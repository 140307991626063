import React, { Fragment, useRef, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Fade,
  TextField,
  Button,
  FormControl,
  Select,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ContactUsSubmitAction } from "./ContactUsAction";
import { texasCities, counties, content } from "./ContactUsContent";
import { validateMailFormat } from "../../util/utilities";
import { getContactPayload } from "./contactUsSelectors";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../constants/colorScheme";
import { formatNumberInput, validateMobileNumberFormat,formatMobileNumberInput } from "../../util/utilities";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: "5px 0",
  },
  box: {
    backgroundColor: "white",
  },
  formContainer: {
    color: "#2e415c",
    backgroundColor: "white",
    padding: "30px",
  },
  title: {
    fontWeight: "bold",
  },
  contactContainer: {
    boxShadow: "2px 2px 11px 1px rgb(90 78 80 / 55%)",
    border: "1px solid PRIMARY_COLOR",
    padding: "10px",
    margin: "20px",
  },
  desc: {
    textAlign: "left",
    fontWeight: "800",
  },
  formInputs: {
    padding: "15px 0",
  },
  fields: {
    minWidth: "calc(100% - 40px)",
  },
  sendBtn: {
    width: "calc(100% - 40px)",
    fontWeight: "bold",
    margin: "10px 0",
    "&:hover": {
      backgroundColor: PRIMARY_COLOR,
      color: SECONDARY_COLOR,
      animation: 0,
    },
  },
}));

const ContactUsForm = () => {
  const errObjInitialState = {
    fnameErr: false,
    lnameErr: false,
    addressl1Err: false,
    mailErr: false,
    validationErr: false,
    phErr: false,
    zipErr: false,
    stateErr: false,
    cityErr: false,
    countyErr: false,
  };

  const classes = useStyles();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  let mailInput = useRef(null);
  const [errs, setErrs] = useState(errObjInitialState);
  //const contactPayloadInitState = useSelector(getContactPayload);
  const contactPayloadInitState = useSelector(getContactPayload) || {
    fname: "",
    lname: "",
  };
  const [contactPayload, setContactPayload] = useState({
    ...contactPayloadInitState,
    state: "Texas",
  });
  const [selectedCity, setSelectedCity] = useState("");

  useEffect(() => {
    const countyForSelectedCity = counties.find(
      (county) => county.city === selectedCity
    );
    if (countyForSelectedCity) {
      setContactPayload((prevPayload) => ({
        ...prevPayload,
        county: countyForSelectedCity.value,
      }));
    }
  }, [selectedCity]);

  useEffect(() => {
    clearValues();
  }, []);
  
  const clearValues = () => {
    setContactPayload({
      firstName: "",
      lastName: "",
      businessName: "",
      addressLineOne: "",
      addressLineTwo: "",
      emailId: "",
      phone: 0,
      zipCode: 0,
      county: "",
      city: "",
      state: "Texas",
    });
    setErrs({
      fnameErr: false,
      lnameErr: false,
      addressl1Err: false,
      mailErr: false,
      validationErr: false,
      phErr: false,
      zipErr: false,
      stateErr: false,
      cityErr: false,
      countyErr: false,
    });
    mailInput.current.value = "";
  };

  const handleTxt = (evt, txtType) => {
    switch (txtType) {
      case "fname":
        setContactPayload({ ...contactPayload, firstName: evt.target.value });
        setErrs((prevState) => ({ ...prevState, fnameErr: false }));
        break;
      case "lname":
        setContactPayload({ ...contactPayload, lastName: evt.target.value });
        setErrs((prevState) => ({ ...prevState, lnameErr: false }));
        break;
      case "bname":
        setContactPayload({
          ...contactPayload,
          businessName: evt.target.value,
        });
        break;
      case "addressl1":
        setContactPayload({
          ...contactPayload,
          addressLineOne: evt.target.value,
        });
        setErrs((prevState) => ({ ...prevState, addressl1Err: false }));
        break;
      case "addressl2":
        setContactPayload({
          ...contactPayload,
          addressLineTwo: evt.target.value,
        });
        break;
      case "email":
        setContactPayload({ ...contactPayload, emailId: evt.target.value });
        if (validateMailFormat(evt.target.value)) {
          setErrs((prevState) => ({
            ...prevState,
            mailErr: false,
            validationErr: false,
          }));
        } else {
          setErrs((prevState) => ({
            ...prevState,
            mailErr: true,
            validationErr: true,
          }));
        }
        break;
      case "phoneNum":
        setContactPayload({ ...contactPayload, phone: evt.target.value });
        setErrs((prevState) => ({ ...prevState, phErr: false }));
        break;
      case "zip":
        setContactPayload({ ...contactPayload, zipCode: evt.target.value });
        setErrs((prevState) => ({ ...prevState, zipErr: false }));
        break;
      case "state":
        setContactPayload({ ...contactPayload, state: evt.target.value });
        setErrs((prevState) => ({ ...prevState, stateErr: false }));
        break;
      case "city":
        setContactPayload({ ...contactPayload, city: evt.target.value });
        setErrs((prevState) => ({ ...prevState, cityErr: false }));
        setSelectedCity(evt.target.value);
        break;
      case `county`:
        setContactPayload({ ...contactPayload, county: evt.target.value });
        setErrs((prevState) => ({ ...prevState, countyErr: false }));
        break;
      default:
    }
  };

  const execScroll = () =>
    formRef.current.scrollIntoView({ behavior: "smooth" });

  const handleSend = (event) => {
    let error = false;
    !contactPayload.firstName
      ? setErrs((prevState) => ({ ...prevState, fnameErr: true }))
      : setErrs((prevState) => ({ ...prevState, fnameErr: false }));
    !contactPayload.lastName
      ? setErrs((prevState) => ({ ...prevState, lnameErr: true }))
      : setErrs((prevState) => ({ ...prevState, lnameErr: false }));
    !contactPayload.addressLineOne
      ? setErrs((prevState) => ({ ...prevState, addressl1Err: true }))
      : setErrs((prevState) => ({ ...prevState, addressl1Err: false }));
    !contactPayload.emailId
      ? setErrs((prevState) => ({ ...prevState, mailErr: true }))
      : setErrs((prevState) => ({ ...prevState, mailErr: false }));
    !contactPayload.phone && contactPayload.phone === 0
      ? setErrs((prevState) => ({ ...prevState, phErr: true }))
      : setErrs((prevState) => ({ ...prevState, phErr: false }));
    !contactPayload.zipCode
      ? setErrs((prevState) => ({ ...prevState, zipErr: true }))
      : setErrs((prevState) => ({ ...prevState, zipErr: false }));
    !contactPayload.state
      ? setErrs((prevState) => ({ ...prevState, stateErr: true }))
      : setErrs((prevState) => ({ ...prevState, stateErr: false }));
    !contactPayload.city
      ? setErrs((prevState) => ({ ...prevState, cityErr: true }))
      : setErrs((prevState) => ({ ...prevState, cityErr: false }));
    !contactPayload.county
      ? setErrs((prevState) => ({ ...prevState, countyErr: true }))
      : setErrs((prevState) => ({ ...prevState, countyErr: false }));

    if (
      !contactPayload.firstName ||
      !contactPayload.lastName ||
      !contactPayload.addressLineOne ||
      !contactPayload.emailId ||
      !contactPayload.phone ||
      !contactPayload.zipCode ||
      !contactPayload.state ||
      !contactPayload.city ||
      !contactPayload.county
    ) {
      error = true;
    }

    if (error) {
      event.stopPropagation();
    }

    if (!error && Object.values(errs).every((item) => item === false)) {
      const numericPhoneNumber = contactPayload.phone.replace(/[^\d]/g, '');

      // Prepare the payload with numeric phone number
      const payloadToSend = {
        ...contactPayload,
        phone: numericPhoneNumber,
      };
      dispatch(ContactUsSubmitAction(payloadToSend));
      clearValues();
    } else {
      execScroll();
    }
  };
  const mobileNumberFormater = (phoneNumber) => {
    phoneNumber = phoneNumber.replace(/[^0-9]/g, "");
    let parsedNumber = phoneNumber;
    if (phoneNumber.length === 10) {
        parsedNumber = phoneNumber.replace(
          /(\d{3})(\d{3})(\d{4})/,
          "($1) $2-$3"
        );
      }
  
    setContactPayload((prevPayload) => ({
      ...prevPayload,
      phone: parsedNumber,
    }));
  };

  return (
    <Box className={classes.box}>
      {/* <Fade in={true} timeout={1500}> */}
      <Grid container className={classes.formContainer} ref={formRef}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.title}>
            {content.title.value}{" "}
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Fade in={true} timeout={1500}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ paddingTop: "20px" }}
              >
                {content.mainContent.map((item) => (
                  <Fragment key={item?.key}>
                    <Grid item xs={12} className={classes.desc}>
                      <Typography variant="h5">{item.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {item.list &&
                        item.list.map((listItem) => (
                          <ListItem
                            component="div"
                            key={listItem?.key}
                            className={classes.listLinks}
                          >
                            <ListItemIcon className={classes.caret}>
                              <ChevronRightIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={listItem?.value} />
                          </ListItem>
                        ))}
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </Fade>
          </Grid>
          <Grid item xs={12} md={6}>
            <Fade in={true} timeout={2500}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.contactContainer}
                ref={formRef}
              >
                <Grid item xs={12}>
                  <Typography variant="h4" className={classes.title}>
                    {" "}
                    Contact Us
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    required
                    id="fname"
                    label="First Name"
                    variant="standard"
                    className={classes.fields}
                    onChange={(e) => handleTxt(e, "fname")}
                    error={errs.fnameErr}
                    helperText={errs.fnameErr && `Enter First Name`}
                    value={contactPayload.firstName}
                    InputLabelProps={{sx: {'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}} }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    required
                    id="lname"
                    label="Last Name"
                    variant="standard"
                    className={classes.fields}
                    onChange={(e) => handleTxt(e, "lname")}
                    error={errs.lnameErr}
                    helperText={errs.lnameErr && `Enter Last Name`}
                    value={contactPayload.lastName}
                    InputLabelProps={{sx: {'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}} }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    id="bname"
                    label="Business Name"
                    variant="standard"
                    className={classes.fields}
                    onChange={(e) => handleTxt(e, "bname")}
                    value={contactPayload.businessName}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    required
                    id="addressl1"
                    label="Address Line 1"
                    variant="standard"
                    error={errs.addressl1Err}
                    className={classes.fields}
                    onChange={(e) => handleTxt(e, "addressl1")}
                    helperText={errs.addressl1Err && `Enter your address`}
                    value={contactPayload.addressLineOne}
                    InputLabelProps={{sx: {'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}} }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    id="addressl2"
                    label="Address Line 2"
                    variant="standard"
                    className={classes.fields}
                    onChange={(e) => handleTxt(e, "addressl2")}
                    value={contactPayload.addressLineTwo}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    required
                    className={classes.fields}
                    id="zip"
                    label="Zip"
                    variant="standard"
                    type="number"
                    InputLabelProps={{ shrink: true,sx: {'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}} }}
                    onKeyDown={formatNumberInput}
                    onChange={(e) => handleTxt(e, "zip")}
                    error={errs.zipErr}
                    helperText={errs.zipErr && `Enter Zip Code`}
                    value={contactPayload.zipCode}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  <FormControl
                    variant="standard"
                    className={classes.fields}
                    required
                    error={errs.stateErr}
                  >
                    <InputLabel htmlFor="native-select-state" sx={{'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}}}>State</InputLabel>
                    <Select
                      native
                      value={contactPayload.state}
                      onChange={(e) => handleTxt(e, "state")}
                      label="State"
                      inputProps={{
                        name: "State",
                        id: "native-select-state",
                      }}
                    >
                      <option value={`Texas`} key={`Texas`}>
                        Texas
                      </option>
                    </Select>
                    <FormHelperText>
                      {errs.stateErr && `Select a state `}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  <FormControl
                    variant="standard"
                    className={classes.fields}
                    required
                    error={errs.cityErr}
                  >
                    <InputLabel htmlFor="native-select-city" sx={{'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}}}>City</InputLabel>
                    <Select
                      native
                      value={contactPayload.city}
                      onChange={(e) => handleTxt(e, "city")}
                      label="City"
                      inputProps={{
                        name: "city",
                        id: "native-select-city",
                      }}
                    >
                      {texasCities.map((item) => (
                        <option value={item.value} key={item.value}>
                          {item.value}
                        </option>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errs.cityErr && `Select a City `}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  {/* <TextField
                    className={classes.fields}
                    required
                    id="phoneNum"
                    label="Phone"
                    variant="standard"
                    error={errs.phErr}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleTxt(e, "phoneNum")}
                    onKeyDown={formatNumberInput}
                    helperText={errs.phErr && `Enter your phone number`}
                    value={
                      contactPayload.phone === 0 ? "" : contactPayload.phone
                    }
                  /> */}
      <TextField
  id="phoneNum"
  label="Phone Number"
  type="text"
  variant="standard"
  onChange={(e) => {
      const formattedValue = e.target.value.replace(/\D/g, '');
    mobileNumberFormater(formattedValue);
  }}
  
  onKeyDown={formatMobileNumberInput}
  onKeyPress={(e) => {
    if (!/[0-9]/.test(e.key) && !["(", ")", " ", "-"].includes(e.key)) {
      e.preventDefault();
    }
  }}
  className={classes.fields}
  InputLabelProps={{ required: true, sx:{'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}} }}
  inputProps={{ maxLength: 14 }}
  error={errs.phErr}
  helperText={errs.phErr && `Enter valid phone number`}
  value={contactPayload.phone}
/>
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    className={classes.fields}
                    required
                    id="email"
                    label="Email"
                    variant="standard"
                    type="email"
                    onChange={(e) => handleTxt(e, "email")}
                    error={errs.validationErr || errs.mailErr}
                    helperText={
                      errs.validationErr
                        ? `Enter valid email address`
                        : errs.mailErr
                        ? `Enter email address`
                        : `Enter registered email address`
                    }
                    inputRef={mailInput}
                    InputLabelProps={{sx:{'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}} }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  <FormControl
                    variant="standard"
                    className={classes.fields}
                    required
                    error={errs.countyErr}
                  >
                    <InputLabel htmlFor="native-select-county" shrink={true} sx={{'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}}}>
                      County
                    </InputLabel>
                    <Select
                      native
                      value={contactPayload.county}
                      onChange={(e) => handleTxt(e, "county")}
                      label="County"
                      inputProps={{
                        name: "county",
                        id: "native-select-county",
                      }}
                    >
                      {counties.map(
                        (item) =>
                          item.city === selectedCity && (
                            <option value={item.value} key={item.city}>
                              {item.value}
                            </option>
                          )
                      )}
                    </Select>
                    <FormHelperText>
                      {errs.countyErr && `Select a County `}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className={classes.sendBtn}
                    color="inherit"
                    variant="contained"
                    onClick={handleSend}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Grid>
      {/* </Fade> */}
    </Box>
  );
};

export default ContactUsForm;
