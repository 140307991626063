import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import AppClientReducer from '../components/appClient/AppClientReducer';
import LoginReducer from '../components/home/login/LoginReducer';
import ContactUsReducer from '../components/contactUs/ContactUsReducer';
import RegisterReducer from '../components/home/register/RegisterReducer';
import UserProfileReducer from '../components/profile/userProfile/UserProfileReducer';
import AgentProfileReducer from '../components/profile/agentProfile/AgentProfileReducer';
import taxInfoReducer from './reducers/taxinfoReducer';
import AssetInfoReducer from './reducers/AssetsInfoReducer';
import LeasedAssetReducer from './reducers/LeasedAssetReducer';
import businessInfoReducer from './reducers/BusinessInfoReducer';
import locationInfoReducer from './reducers/LocationInfoReducer';
import additionalBusinessInfoReducer from './reducers/AdditionalBusinesssInfoReducer';
import SupppliesInfoReducer from './reducers/SuppliesInfoReducer';
import taxFilingPlanInfoReducer from './reducers/TaxFilingPlanInfoReducer';
import representationInfoReducer from './reducers/RepresentationReducer';
import inventoryInfoReducer from './reducers/InventoryInfoReducer';
import RenditionReducer from './reducers/RenditionReducer';
import appServiceInfoReducer from './reducers/AppServiceInfoReducer';
import businessDataInfoReducer from './reducers/BusinessDataFetchReducer';

const history = createBrowserHistory();

const rootReducer = combineReducers({
    router: connectRouter(history),
    appCtx: LoginReducer,
    // forgotPwd: ForgotPwdReducer,
    register: RegisterReducer,
    contactUs: ContactUsReducer,
    appClientCtx: AppClientReducer,
    userProfile: UserProfileReducer,
    agentProfile: AgentProfileReducer,
    taxInfoReducer: taxInfoReducer,
    businessInfo: businessInfoReducer,
    locationInfo: locationInfoReducer,
    assets:AssetInfoReducer,
    leasedAssets: LeasedAssetReducer,
    additionalBusinessInfo: additionalBusinessInfoReducer,
    suppliesInfo : SupppliesInfoReducer,
    taxFilingPlanInfoReducer: taxFilingPlanInfoReducer,
    representation: representationInfoReducer,
    inventoryInfo: inventoryInfoReducer,
    rendition: RenditionReducer,
    appServiceInfoReducer: appServiceInfoReducer,
    businessDataInfoReducer: businessDataInfoReducer,
});

export default rootReducer;