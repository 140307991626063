import { registerUser } from '../../../api/AuthApi';
import NotificationManager from "react-notifications/lib/NotificationManager";

export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const registerAction = (payload) => {
    const registerPayload ={
        'emailId': payload.email,
        'password': payload.pwd,
        'fullName': payload.name,
        'isAgent': payload.agentCheck === 'yes' ? true : false
    }
    return (dispatch) => {
        dispatch({ type: REGISTER, registerPayload});
        registerUser(registerPayload).then( response => {
            (response && response.data && response.status === 200) &&
                dispatch({ type: REGISTER_SUCCESS, payload});
                NotificationManager.success('Registered successfully! An email is sent to your registered email id. Please verify.');
        }).catch( err => {
            if(err.response && err.response.data && err.response.data.errors){
                dispatch({type: REGISTER_FAILURE, payload: err.response.data.errors});
                NotificationManager.error(err.response.data.errors[0].msg);
                setTimeout(() => {
                    NotificationManager.info(err.response.data.errors[0].msg1);
                }, 2000);
            }
        })
    }
}
