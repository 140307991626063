import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import {  Grid,  Box,  FormControlLabel,  Checkbox,  InputLabel,  MenuItem,  Modal,  Backdrop,  Fade,  OutlinedInput,  Select,  Typography,  Divider,  FormHelperText,  LinearProgress,  Chip,  Button,} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {  clearBusinessInfo,  fetchOwnerShipTypes,  getSelectedBusinessInfo,  postBusinessInformation,  verifyBusinessInfoZipCode,} from "../../../redux/actions/BusinessInfoAction";
import { PRIMARY_COLOR } from "../../../constants/colorScheme";
import {  getBusinessOfUser,  getCityValue,  getClientDetails,  getStateValue,} from "../AppClientSelectors";
import {  getBusinessCityValue,  getBusinessInfo,  getBusinessStateValue,  getIsLoadingBusinessStateCityValues,  getownershipTypes,} from "../selectors/BusinessInfoSelector";
import { getTaxInfo } from "../selectors/TaxInfoSelector";
import {  getClientCheckDetails,  getDirectClientInfoId,  getProfilePayload,} from "../../profile/userProfile/UserProfileSelectors";
import { getAgentProfile } from "../../profile/agentProfile/AgentProfileSelectors";
import { defineCurrBusiness, loadBusinesses } from "../AppClientActions";
import DuplicateTaxModal from "./ModalContentScreen";
import { GET_BUSINESS_INFO } from "../../../redux/actions/BusinessInfoAction";
import { getTaxFillingData } from "../selectors/TaxFilingPlanSelector";
import {  dispatchSavedPlanData,  validateMobileNumberFormat,} from "../../../util/utilities";
import AddressSuggestionModal from "../../addressSuggestion/AddressSuggestionModal";
import { getBusinessAddressModalState } from "../selectors/BusinessInfoSelector";
import { setAppServiceInfo } from "../../../redux/actions/AppServiceInfoAction";
import {  formatMobileNumberInput,  formatNumberInput,} from "../../../util/utilities";
//Stepper Imports
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { getBusinessData } from "../selectors/BusinessDataFetchSelector";

const steps = ["Business Info", "OwnerShip"];

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    width: "90vw",
    [theme.breakpoints.down("sm")]: {
      width: "45vw",
      left: "20%",
    },
    "& .MuiInputLabel-root": {
      // color: 'red',
      color: PRIMARY_COLOR,
      fontWeight: "bold",
    },
  },
  paneRoot: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
  },
  modalContainer: {
    top: "100px",
  },
  asterisk: {
    "&.Mui-required .MuiInputLabel-asterisk": { color: "red" },
  },
}));

export default function BusinessInformation({ nextButton, saveButton }) {
  const {    register,    handleSubmit,    setValue,    reset,    formState: { errors },  } = useForm();
  const dispatch = useDispatch();
  const clientDetails = useSelector(getClientDetails);
  const directClientInfoId = useSelector(getDirectClientInfoId);
  const agentProfile = useSelector(getAgentProfile);
  const businessInfoData = useSelector(getBusinessInfo);
  const taxInfo = useSelector(getTaxInfo);
  const OWNERSHIP_TYPES = useSelector(getownershipTypes);
  const stateValue = useSelector(getBusinessStateValue);
  const cityValue = useSelector(getBusinessCityValue);
  const stateValues = useSelector(getStateValue);
  const cityValues = useSelector(getCityValue);
  const isLoadingStateValues = useSelector(getIsLoadingBusinessStateCityValues);
  const addressModalState = useSelector(getBusinessAddressModalState);

  const isClient = useSelector(getClientCheckDetails);
  const clientProfile = useSelector(getProfilePayload);
  const [userBusinesses, setUserBusiness] = useState([]);
  const businessesOfUser = useSelector(getBusinessOfUser);
  const [businessValue, setBusinessValue] = useState("");

  const classes = useStyles();
  const [businessInfo, setBusinessInfo] = useState({ ...businessInfoData});
  const taxFilingPlanData = useSelector(getTaxFillingData);

  const businessData = useSelector(getBusinessData);

  // Stepper Code start
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()? steps.findIndex((step, i) => !(i in completed))        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  // Stepper Code End
  useEffect(() => {
    if (taxFilingPlanData.isPlanSelected) {
      dispatchSavedPlanData(dispatch,"BusinessInfo",taxFilingPlanData.taxFilingPlan);
    }
    dispatch(setAppServiceInfo(0, null, false, false));
  }, [dispatch]);

  const getStateId = (stateName) => {
    for (let value of stateValues) {
      if (value.codeValueKey === stateName) {
        return value.id;
      }
    }
  };
  const getStateCodeValue = (stateId) => {
    if (stateValues) {
      for (let value of stateValues) {
        if (value.id === stateId) {
          return value.codeValueKey;
        }
      }
    }
  };

  const getCityId = (cityName) => {
    for (let value of cityValues) {
      if (value.codeValueKey === cityName) {
        return value.id;
      }
    }
  };

  const getCityCodeValue = (cityId) => {
    if (cityValues) {
      for (let value of cityValues) {
        if (value.id === cityId) {
          return value.codeValueKey;
        }
      }
    }
  };

  const officeNumberFormater = (phoneNumber) => {
    phoneNumber = phoneNumber.replace(/[^0-9]/g, "");
    let parsedNumber = phoneNumber;
    if (validateMobileNumberFormat(phoneNumber)) {
      if (phoneNumber.length === 10) {
        parsedNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      }
      setBusinessInfo((prevInfo) => ({...prevInfo, officePhoneNumber: parsedNumber, }));
    }
  };

  const mobileNumberFormater = (phoneNumber) => {
    phoneNumber = phoneNumber.replace(/[^0-9]/g, "");
    let parsedNumber = phoneNumber;
    if (validateMobileNumberFormat(phoneNumber)) {
      if (phoneNumber.length === 10) {
        parsedNumber = phoneNumber.replace(
          /(\d{3})(\d{3})(\d{4})/,
          "($1) $2-$3"
        );
      }
      setBusinessInfo((prevInfo) => ({
        ...prevInfo,
        mobilePhoneNumber: parsedNumber,
      }));
    }
  };
  const [checked, setChecked] = useState(false);
  const onSubmit = (data, lastVisitedPage) => {
    if (!checked) {
      data.noOfLocations = '';
    }
    let mappedData = {
      ...data,
      id: businessInfo.id ? businessInfo.id : "",
      businessName: businessInfo.businessName        ? businessInfo.businessName        : taxInfo.businessName,
      state: Number.isInteger(businessInfo.state)        ? businessInfo.state        : getStateId(businessInfo.state),
      city: Number.isInteger(businessInfo.city)        ? businessInfo.city        : getCityId(businessInfo.city),
    };
    console.log('MAPPED DATA',mappedData);
    dispatch(
      postBusinessInformation(
        {
          ...mappedData,
          userType: clientDetails.userType,
          directClientInfoId,
          agenProfileId: agentProfile ? agentProfile.id : null,
          taxInfoId: taxInfo.id,
        },
        true,
        lastVisitedPage
      )
    );
  };


  const handleCheckBox = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (!isChecked) {
      // Clear the noOfLocations when unchecked
      setBusinessInfo(prev => ({ ...prev, noOfLocations: '' }));
    }
  };
  
  useEffect(()=>{
if(businessInfo.noOfLocations>1){
  setChecked(true);
}
// else if(checked===true){
//   setBusinessInfo(prev => ({ ...prev, noOfLocations: '' }));
//   setChecked(false)
//     }
  },[businessInfo.noOfLocations])

  const zipCodeLookUpValues = (zipcode) => {
    if (zipcode.length === 5) {
      dispatch(verifyBusinessInfoZipCode(businessInfo, zipcode));
      setValue("city", "");
      setValue("state", "");
    } else {
      setValue("city", "");
      setValue("state", "");
    }
  };

  const handleBusinessChange = (e) => {
    let selectedBusiness = e.target.value;
    setBusinessValue(selectedBusiness);
    dispatch(defineCurrBusiness(selectedBusiness));
    dispatch(getSelectedBusinessInfo(isClient));
  };

  useEffect(() => {
    if (!businessInfo.error && businessInfo.isSubmitting === "yes") {
      dispatch({type: GET_BUSINESS_INFO, payload: { ...businessInfo, isSubmitting: "no" }});
    }
  }, [businessInfo]);

  useEffect(() => {
    dispatch(fetchOwnerShipTypes());
    dispatch(
      loadBusinesses(
        isClient,
        isClient ? clientProfile.id : agentProfile.id,
        taxInfo.taxYear
      )
    );
  }, [dispatch]);

  useEffect(() => {
    setValue("businessName", taxInfo.businessName);
    setValue("city",Number.isInteger(cityValue) ? getCityCodeValue(cityValue) : cityValue);
    setValue("state",Number.isInteger(stateValue) ? getStateCodeValue(stateValue) : stateValue);
  }, [cityValue, stateValue, taxInfo]);

   useEffect(() => {
    setUserBusiness(businessesOfUser);
    reset();
    setBusinessInfo(businessInfoData);
  }, [businessesOfUser, businessInfoData]);

  
   return (
    <Box
      style={{
        position: "relative",
        left: "2%",
        bottom: "15%",
        textAlign: "justify",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Grid container>
            <Modal
              open={addressModalState}
              hideBackdrop
              disableEscapeKeyDown={true}
              aria-labelledby="app-modal"
              aria-describedby="app-modal-client"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              disableScrollLock={true}
              className={classes.modalRoot}
            >
              <Fade in={addressModalState}>
                <Box>
                  <AddressSuggestionModal />
                </Box>
              </Fade>
            </Modal>
          </Grid>
        </Box>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12} sx={{ m: 1 }}>
            <h1 style={{ textAlign: "center" }}>
              Business Information
              <Typography
                className={classes.loginTitle}
                style={{ float: "right" }}
              >
                <span style={{ color: "red", fontSize: 25 }}>*</span> Required
                fields
              </Typography>
              <Divider />
            </h1>
            <h5> Text to be added </h5>
          </Grid>

          {/* Stepper Start*/}
          <Box sx={{ width: "100%" }}>
            <Grid item md={12}>
              <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]} sx={{ ml: 2 }}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
                <Typography
                  sx={{ mt: 0, mb: 0, py: 1, fontWeight: "bold",fontSize:"20px" }}
                  
                >
                  {/* Step {activeStep + 1} <br /> */}
                  Now You are in: {activeStep + 1}-{" "}
                  {activeStep === 0
                    ? "Business Information"
                    : activeStep === 1 && "Owner Information"}
                </Typography>
              </Stepper>
            </Grid>
          </Box>
          {/* Stepper End */}

          {userBusinesses.length !== 0 && (
            <>
              <Grid item>
                <InputLabel id="business-label">Existing Businesses</InputLabel>
                <Select
                  labelId="business-label"
                  id="businessOfUser"
                  defaultValue=""
                  input={
                    <OutlinedInput
                      label="Existing Business"
                      style={{ width: "100%" }}
                    />
                  }
                  {...register("businessOfUser", {
                    onChange: (e) => {
                      // setPlanSelected(true);
                      handleBusinessChange(e);
                    },
                  })}
                  value={
                    businessValue
                      ? businessValue
                      : taxFilingPlanData.taxFilingPlan
                      ? taxFilingPlanData.taxFilingPlan.businessInfoId
                      : ""
                  }
                >
                  {userBusinesses.map((eachBiz) => (
                    <MenuItem key={eachBiz.id} value={eachBiz.id}>
                      {`${eachBiz.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item style={{ marginTop: "5px" }}>
                <Divider>
                  <Chip
                    label="Select the Business Name from the above dropdown if you want to work on Existing Businesses"
                    sx={{ fontSize: "1rem" }}
                  />
                </Divider>
                <Chip
                  label="Reset Form Fields"
                  sx={{ fontSize: "1rem" }}
                  onClick={() => {
                    setBusinessValue("");
                    dispatch(defineCurrBusiness(""));
                    dispatch(clearBusinessInfo());
                  }}
                />
              </Grid>
            </>
          )}

          {activeStep === 0 && (
            <>
              <Grid item xs={12}>
                <TextField
                  id="businessName"
                  label="Business Name"
                  variant="outlined"
                  {...register("businessName", {
                    // required: 'Business name is required',
                    maxLength: 130,
                    onChange: (e) => {
                      setBusinessInfo({
                        ...businessInfo,
                        businessName: e.target.value,
                      });
                    },
                  })}
                  className={classes.fieldContainer}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    sx: {
                      "&.Mui-required .MuiInputLabel-asterisk": {
                        color: "red",
                      },
                    },
                  }}
                  helperText={
                    errors.businessName ? errors.businessName.message : ""
                  }
                  error={errors.businessName}
                  value={
                    businessInfo.businessName === ""
                      ? taxInfo.businessName
                      : businessInfo.businessName
                      ? businessInfo.businessName
                      : ""
                  }
                  // {console.log(value)}
                />
              </Grid>

              <Grid item>
                <TextField
                  id="corporationName"
                  label="Corporation Name (If different from business name)"
                  variant="outlined"
                  {...register("corporationName", {
                    maxLength: 30,
                    onChange: (e) => {
                      setBusinessInfo({
                        ...businessInfo,
                        corporationName: e.target.value,
                      });
                    },
                  })}
                  className={classes.fieldContainer}
                  InputLabelProps={{ shrink: true }}
                  helperText={
                    errors.corporationName
                      ? "Please enter the name within 30 characters"
                      : ""
                  }
                  error={errors.corporationName}
                  value={
                    businessInfo.corporationName
                      ? businessInfo.corporationName
                      : ""
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  id="ownerName"
                  label="Owner Name"
                  variant="outlined"
                  {...register("ownerName", {
                    required: "Owner name is required",
                    maxLength: 100,
                    onChange: (e) => {
                      setBusinessInfo({
                        ...businessInfo,
                        ownerName: e.target.value,
                      });
                    },
                  })}
                  className={classes.fieldContainer}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    sx: {
                      "&.Mui-required .MuiInputLabel-asterisk": {
                        color: "red",
                      },
                    },
                  }}
                  helperText={errors.ownerName ? errors.ownerName.message : ""}
                  error={errors.ownerName}
                  // value={businessInfo.ownerName ? businessInfo.ownerName : ""}
                  value={
                    businessInfo.ownerName
                      ? businessInfo.ownerName
                      : businessData.OWNER
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  id="mailingAddressLine1"
                  label="Company Mailing Address"
                  variant="outlined"
                  {...register("mailingAddressLine1", {
                    required: "Address is required",
                    maxLength: 50,
                    onChange: (e) => {
                      setBusinessInfo({
                        ...businessInfo,
                        mailingAddressLine1: e.target.value,
                      });
                    },
                  })}
                  className={classes.fieldContainer}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    sx: {
                      "&.Mui-required .MuiInputLabel-asterisk": {
                        color: "red",
                      },
                    },
                  }}
                  helperText={
                    errors.mailingAddressLine1
                      ? errors.mailingAddressLine1.message
                      : ""
                  }
                  error={errors.mailingAddressLine1}
                  // value={businessInfo.mailingAddressLine1 ? businessInfo.mailingAddressLine1 : businessData.businessData.ADDR_LINE1 + " " + businessData.ADDR_LINE2 }
                  value={
                    businessInfo.mailingAddressLine1
                      ? businessInfo.mailingAddressLine1
                      : `${businessData.businessData?.ADDR_LINE1 || ""} ${
                          businessData?.ADDR_LINE2 || ""
                        }`.trim()
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  id="mailingAddressLine2"
                  label="Address Line 2"
                  variant="outlined"
                  {...register("mailingAddressLine2", {
                    maxLength: 50,
                    onChange: (e) => {
                      setBusinessInfo({
                        ...businessInfo,
                        mailingAddressLine2: e.target.value,
                      });
                    },
                  })}
                  className={classes.fieldContainer}
                  InputLabelProps={{ shrink: true }}
                  value={
                    businessInfo.mailingAddressLine2
                      ? businessInfo.mailingAddressLine2
                      : `${businessData.ADDR_LINE3?.trim() || ""}` === "NULL"
                      ? ""
                      : `${businessData.ADDR_LINE3?.trim() || ""}`
                  }
                />
              </Grid>

              <Grid item>
                <TextField
                  id="zip"
                  label="Zip"
                  type="number"
                  variant="outlined"
                  {...register("zip", {
                    required: "Zipcode is required",
                    maxLength: {
                      value: 5,
                      message: "Only 5 characters are allowed",
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Only numericals are allowed",
                    },
                    onChange: (e) => {
                      setBusinessInfo({ ...businessInfo, zip: e.target.value });
                       zipCodeLookUpValues(e.target.value);
                    },
                  })}
                  onKeyDown={formatNumberInput}
                  className={classes.fieldContainer}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    sx: {
                      "&.Mui-required .MuiInputLabel-asterisk": {
                        color: "red",
                      },
                    },
                  }}
                  helperText={errors.zip ? errors.zip.message : ""}
                  error={errors.zip}
                  value={businessInfo.zip ? businessInfo.zip: `${businessData.ADDR_ZIP.split("-")[0]}`}
                />
             {!businessInfo.city && !businessInfo.state &&
               <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
               <Button
                 type="button"
                 variant="contained"
                 sx={{
                   background: "#053e85",
                   display: !businessInfo.city && !businessInfo.state ? 'flex' : 'none'
                 }}
                 onClick={() => {
                   zipCodeLookUpValues(businessInfo.zip ? businessInfo.zip : `${businessData.ADDR_ZIP.split("-")[0]}`);
                 }}
               >
                 Click Here!!
               </Button>
               {!businessInfo.city && !businessInfo.state && (
                 <Typography variant="body1" sx={{ marginLeft: 1 }}>
                    👈 Click the button to retrieve City and State Information.
                 </Typography>
               )}
             </Box>
             
                }
              
              </Grid>
              {isLoadingStateValues && (
                <Grid item>
                  <LinearProgress />
                </Grid>
              )}

              <Grid item>
                <TextField
                  id="city"
                  label="City"
                  variant="outlined"
                  {...register("city", { minLength: 1 })}
                  className={classes.fieldContainer}
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={
                    Number.isInteger(cityValue)
                      ? getCityCodeValue(cityValue)
                      : cityValue
                      ? cityValue
                      : ""
                  }
                  // value={businessInfo.city?businessInfo.city: businessData.ADDR_CITY.trim()}
                />
              </Grid>
              
              <Grid item>
                <TextField
                  id="state"
                  label="State"
                  variant="outlined"
                  {...register("state", { minLength: 1 })}
                  className={classes.fieldContainer}
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={
                    Number.isInteger(stateValue)
                      ? getStateCodeValue(stateValue)
                      : stateValue
                      ? stateValue
                      : ""
                  }
                  // value={businessInfo.state? businessInfo.state:`${businessData.ADDR_STATE}`}
                />
              </Grid>
            </>
          )}

          {activeStep === 1 && (
            <>
              <Grid item>
                <InputLabel
                  id="ownership-Code-label"
                  required
                  className={classes.asterisk}
                >
                  Ownership Type
                </InputLabel>
                <Select
                  labelId="ownership-Code-label"
                  id="ownershipType"
                  defaultValue=""
                  input={
                    <OutlinedInput
                      label="OwnerShip Type"
                      style={{ width: "100%" }}
                    />
                  }
                  {...register("ownershipType", {
                    required: "Ownership Type is required",
                    onChange: (e) => {
                      setBusinessInfo({
                        ...businessInfo,
                        ownershipType: e.target.value,
                      });
                    },
                  })}
                  error={errors.ownershipType}
                  value={
                    businessInfo.ownershipType ? businessInfo.ownershipType : ""
                  }
                >
                  {OWNERSHIP_TYPES.map((eachBiz) => (
                    <MenuItem key={eachBiz.codeValueKey} value={eachBiz.id}>
                      {`${eachBiz.codeValueKey}`}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {errors.ownershipType && errors.ownershipType.message}
                </FormHelperText>
              </Grid>
              <Grid item>
                <TextField
                  id="propertyTaxContactPerson"
                  label="Property Tax Contact Person"
                  variant="outlined"
                  {...register("propertyTaxContactPerson", {
                    required: "Property Tax contact person is required",
                    maxLength: 30,
                    onChange: (e) => {
                      setBusinessInfo({
                        ...businessInfo,
                        propertyTaxContactPerson: e.target.value,
                      });
                    },
                  })}
                  className={classes.fieldContainer}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    sx: {
                      "&.Mui-required .MuiInputLabel-asterisk": {
                        color: "red",
                      },
                    },
                  }}
                  helperText={
                    errors.propertyTaxContactPerson
                      ? errors.propertyTaxContactPerson.message
                      : ""
                  }
                  error={errors.propertyTaxContactPerson}
                  value={
                    businessInfo.propertyTaxContactPerson
                      ? businessInfo.propertyTaxContactPerson
                      : ""
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  id="jobTitle"
                  label="Job Title"
                  variant="outlined"
                  {...register("jobTitle", {
                    required: "Job Title is required",
                    maxLength: 30,
                    onChange: (e) => {
                      setBusinessInfo({
                        ...businessInfo,
                        jobTitle: e.target.value,
                      });
                    },
                  })}
                  className={classes.fieldContainer}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    sx: {
                      "&.Mui-required .MuiInputLabel-asterisk": {
                        color: "red",
                      },
                    },
                  }}
                  helperText={errors.jobTitle ? errors.jobTitle.message : ""}
                  error={errors.jobTitle}
                  value={businessInfo.jobTitle ? businessInfo.jobTitle : ""}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="mobilePhoneNumber"
                  label="Mobile Phone Number"
                  type="text" //Changed type number to text
                  variant="outlined"
                  {...register("mobilePhoneNumber", {
                    required: "Phone number is required",
                    maxLength: {
                      value: 14,
                      message: "Only 10 characters are allowed",
                    },
                    pattern: {
                      value: /^\(\d{3}\)\s\d{3}-\d{4}$/,
                      message: "Please enter 10 digits",
                    },
                    onChange: (e) => {
                      const { value } = e.target;
                      setBusinessInfo({
                        ...businessInfo,
                        mobilePhoneNumber: value || " ",
                      });
                      const formattedValue = e.target.value.replace(/\D/g, "");
                      mobileNumberFormater(formattedValue);
                      // setBusinessInfo({
                      //   ...businessInfo,
                      //   mobilePhoneNumber: e.target.value,
                      // });
                      // mobileNumberFormater(value);
                    },
                  })}
                  onKeyDown={formatMobileNumberInput}
                  onKeyPress={(e) => {
                    // This will prevent non-numeric characters from being typed
                    if (
                      !/[0-9]/.test(e.key) &&
                      !["(", ")", " ", "-"].includes(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                  className={classes.fieldContainer}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    sx: {
                      "&.Mui-required .MuiInputLabel-asterisk": {
                        color: "red",
                      },
                    },
                  }}
                  inputProps={{ maxLength: 14 }}
                  helperText={
                    errors.mobilePhoneNumber
                      ? errors.mobilePhoneNumber.message
                      : ""
                  }
                  error={errors.mobilePhoneNumber}
                  value={
                    businessInfo.mobilePhoneNumber
                      ? businessInfo.mobilePhoneNumber
                      : ""
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  id="officePhoneNumber"
                  label="Office Phone Number"
                  type="text" // Changed type number to text
                  inputMode="numeric"
                  variant="outlined"
                  {...register("officePhoneNumber", {
                    required: "Office Phone number is required",
                    maxLength: {
                      value: 14,
                      message: "Only 10 characters are allowed",
                    },
                    minLength: { value: 10, message: "Please enter 10 digits" },
                    pattern: {
                      value: /^\(\d{3}\)\s\d{3}-\d{4}$/,
                      message: "Please Enter 10 digits",
                    },
                    onChange: (e) => {
                      const { value } = e.target;
                      setBusinessInfo({
                        ...businessInfo,
                        officePhoneNumber: value || "",
                      });
                      officeNumberFormater(value);
                    },
                  })}
                  onKeyDown={formatMobileNumberInput}
                  onKeyPress={(e) => {
                    // This will prevent non-numeric characters from being typed
                    if (
                      !/[0-9]/.test(e.key) &&
                      !["(", ")", " ", "-"].includes(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                  className={classes.fieldContainer}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    sx: {
                      "&.Mui-required .MuiInputLabel-asterisk": {
                        color: "red",
                      },
                    },
                  }}
                  inputProps={{ maxLength: 14 }}
                  helperText={
                    errors.officePhoneNumber
                      ? errors.officePhoneNumber.message
                      : ""
                  }
                  error={errors.officePhoneNumber}
                  value={
                    businessInfo.officePhoneNumber
                      ? businessInfo.officePhoneNumber
                      : ""
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  id="emailId"
                  label="Email"
                  variant="outlined"
                  {...register("emailId", {
                    required: "Email is required",
                    maxLength: 63,
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email",
                    },
                    onChange: (e) => {
                      setBusinessInfo({
                        ...businessInfo,
                        emailId: e.target.value,
                      });
                    },
                  })}
                  className={classes.fieldContainer}
                  InputLabelProps={{
                    required: true,
                    sx: {
                      "&.Mui-required .MuiInputLabel-asterisk": {
                        color: "red",
                      },
                    },
                  }}
                  helperText={errors.emailId ? errors.emailId.message : ""}
                  error={errors.emailId}
                  value={businessInfo.emailId ? businessInfo.emailId : ""}
                />
              </Grid>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleCheckBox} />}
                label="Business has multiple locations"
              />
              <Grid item>
                {checked && (
                  <TextField
                    id="noOfLocations"
                    label="Number of Locations"
                    variant="outlined"
                    type="number"
                    className={classes.fieldContainer}
                    {...register("noOfLocations", {
                      required: "Number of locations is required",
                      maxLength: 30,
                      onChange: (e) => {
                        setBusinessInfo({
                          ...businessInfo,
                          noOfLocations: e.target.value,
                        });
                      },
                    })}
                    onKeyDown={formatNumberInput}
                    helperText={
                      errors.noOfLocations ? errors.noOfLocations.message : ""
                    }
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                      sx: {
                        "&.Mui-required .MuiInputLabel-asterisk": {
                          color: "red",
                        },
                      },
                    }}
                    error={errors.noOfLocations}
                    value={businessInfo.noOfLocations? businessInfo.noOfLocations: ""}
                  />
                )}
              </Grid>
            </>
          )}
          <input
            type="submit"
            ref={nextButton}
            style={{ display: "none" }}
            onClick={handleSubmit((data) => {
              onSubmit(data, "LocationInfo");
            })}
          />
          <input
            type="submit"
            ref={saveButton}
            style={{ display: "none" }}
            onClick={handleSubmit((data) => {
              onSubmit(data, "BusinessInfo");
            })}
          />
        </Grid>
        <DuplicateTaxModal />
      </form>
    </Box>
  );
}
