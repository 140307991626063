import {
    FETCH_ADDBIZ_CITY_STATE, FETCH_ADDBIZ_CITY_STATE_SUCCESS,
    FETCH_ADDBIZ_CITY_STATE_FAILURE,ADDITIONAL_BUSINESS_INFO_SUCCESS, SET_ADDITIONAL_BUSINESS_SUCCESS,RESET_ADDITIONALBI
} from '../actions/AdditionalBusinessInfoActions';

const defaultState = {
    additionalBusinessInfo: {
        id:'',
        taxFilingPlanId:'',
        loadingStateCity: false,
        zip: '',
        city: '',
        state: '',
        businessType:'',
        sicCode:'',
    }
}

const additionalBusinessInfoReducer = (state = defaultState, action) => {
    switch (action.type) {
        case FETCH_ADDBIZ_CITY_STATE:
            return { ...state, loadingStateCity: true }
        case FETCH_ADDBIZ_CITY_STATE_SUCCESS:
            {
                console.log(`state value is ${action.payload.State}`);
                return { ...state, additionalBusinessInfo: action.payload, loadingStateCity: false }
            }
        case FETCH_ADDBIZ_CITY_STATE_FAILURE:
            return { ...state, additionalBusinessInfo: { ...action.payload, state: '', city: '' }, loadingStateCity: false }
        case ADDITIONAL_BUSINESS_INFO_SUCCESS:
            return { ...state, additionalBusinessInfo: action.payload }
        case SET_ADDITIONAL_BUSINESS_SUCCESS:
            return { ...state, additionalBusinessInfo: action.payload }
        //ADDED TO RESET ADDITIONAL BUSINESS INFO
        case RESET_ADDITIONALBI:
                return defaultState;    
        default:
            return state;
    }
};
export default additionalBusinessInfoReducer;
