import React, { Fragment, useState , useEffect} from 'react';
import { Grid, Table,TableBody, TableCell, TableContainer, Fade, TablePagination, TableRow,
    Checkbox, Typography, Divider } from '@mui/material';
import { colHeaders } from './AssetsTableData';
import DynamicTableHeader from '../../../layout/DynamicTableHeader';
import { stableSort } from '../../../util/compatibilityUtility';
import { getComparator } from '../../../util/sortUtility';
import DynamicTableToolbar from '../../../layout/DynamicTableToolbar';
import { useDispatch,useSelector } from 'react-redux';
import useStyles  from '../../../styles/mainPagesCSS';
import FilterForm from './FilterForm';
import { fetchAssetTypes, getAssets, deleteAssetInfoById, getAssetsInfoById, getFilteredAssetsInfo, deleteBulkAssetInfo } from '../../../redux/actions/AssetsInfoActions';
import NewAssetForm from './NewAssetForm';
import { BulkAssetsModal } from "../../../layout/BulkAssetsModal";
import { useForm } from "react-hook-form";
import { getLocationInfo } from '../selectors/LocationInfoSelector';
import { getAssetsPayload, getCurrentAsset } from "../selectors/AssetsInfoSelector";
import { getTaxFillingData } from '../selectors/TaxFilingPlanSelector';
import { setAppServiceInfo } from '../../../redux/actions/AppServiceInfoAction';
import { getTaxInfo } from '../selectors/TaxInfoSelector'; //ADDED FOR RETRIVING THE TAX PLAN ID
const AssetsView = ({nextButton, buttonStackRef,saveButton }) => {

    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const dispatch = useDispatch();
    const [newAsset, setNewAsset] = useState(null);
    const [filterSelected, setFilterSelected] = useState(false);
    const [filterCriteria, setFilterCriteria] = useState(null);
    const [assetType, setAssetType] = useState(null);
    const [updateAsset, setUpdateAsset] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const locationInfo = useSelector(getLocationInfo);
    const initPayload = useSelector(getAssetsPayload);
    const currentAsset = useSelector(getCurrentAsset);
    const taxFilingPlanData = useSelector(getTaxFillingData);
    const taxInfoData = useSelector(getTaxInfo); //ADDED FOR RETRIVING THE TAX PLAN ID FROM GETTAXINFO SELECTOR

    let rowDataService = [];
    let tableLength = 0;
    if (initPayload.totalItems) {
        tableLength = initPayload.totalItems;
    }

    const { formState: {  } } = useForm({validations: { // all our validation rules go here
      }, });

    buttonStackRef.current.style.display = '';
    useEffect(() => {
        if (newAsset || updateAsset) {
            buttonStackRef.current.style.display = 'none';
        } else{
            buttonStackRef.current.style.display = '';
        }
      });

      useEffect(()=> {

          let locationInfoId = null,taxFilingPlanId = null;
            if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
                if(taxFilingPlanId===undefined|| taxFilingPlanId===null|| taxFilingPlanId===0){
                    taxFilingPlanId = taxInfoData.id; //Tax Plan id is retriving from taxInfoData.id from getTaxInfo selector from TaxInfo Selector    
                }
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            if(locationInfo !== null) {
            locationInfoId = locationInfo != null ? locationInfo.id :'';
            }
        }
        if(taxFilingPlanId == null || taxFilingPlanId === undefined){
            if(locationInfo !== null) {
                taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
                if(taxFilingPlanId===undefined||taxFilingPlanId===null|| taxFilingPlanId===0){
                    taxFilingPlanId = taxInfoData.id; //Tax Plan id is retriving from taxInfoData.id from getTaxInfo selector from TaxInfo Selector
                }
            }
        }
        dispatch(getAssets(locationInfoId,taxFilingPlanId,0,rowsPerPage));
        dispatch(setAppServiceInfo(0,null,false,false));
    },[dispatch,currentAsset,locationInfo.id,rowsPerPage])
    
   
  

    const handleRequestSort = (property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = rowDataService.map((n) => n.id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };
  
    const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
    };
    const retrieveAssets = () => {
        let locationInfoId = null,taxFilingPlanId = null;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
                //ADDED TO RETRIVE TAX ID
                if(taxFilingPlanId===undefined|| taxFilingPlanId===null||taxFilingPlanId===0){
                    taxFilingPlanId=taxInfoData.id;
                }
            }
        }
        if(locationInfoId == null){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null){
            taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
            //ADDED TO RETRIVE TAX ID
            if(taxFilingPlanId===undefined|| taxFilingPlanId===null||taxFilingPlanId===0){
                taxFilingPlanId=taxInfoData.id;
            }

        }
        dispatch(getAssets(locationInfoId,taxFilingPlanId,0,rowsPerPage));
    }
    const handleChangePage = (event, newPage) => {
        let limit = rowsPerPage;
        let locationInfoId = null,taxFilingPlanId = null;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
                //ADDED TO RETRIVE TAX ID
                if(taxFilingPlanId===undefined|| taxFilingPlanId===null||taxFilingPlanId===0){
                    taxFilingPlanId=taxInfoData.id;
                }
            }
        }
        if(locationInfoId == null){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null){
            taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
            //ADDED TO RETRIVE TAX ID
            if(taxFilingPlanId===undefined|| taxFilingPlanId===null||taxFilingPlanId===0){
                taxFilingPlanId=taxInfoData.id;
            }
        }
        let offset = (newPage*rowsPerPage);
        dispatch(getAssets(locationInfoId,taxFilingPlanId,offset,limit));
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      let locationInfoId = null,taxFilingPlanId = null;
      if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
          if(taxFilingPlanData.taxFilingPlan){
              locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
              taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
                //ADDED TO RETRIVE TAX ID
                if(taxFilingPlanId===undefined|| taxFilingPlanId===null||taxFilingPlanId===0){
                    taxFilingPlanId=taxInfoData.id;
                }
          }
      }
      if(locationInfoId == null){
          locationInfoId = locationInfo != null ? locationInfo.id:'';
      }
      if(taxFilingPlanId == null){
          taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
          //ADDED TO RETRIVE TAX ID
          if(taxFilingPlanId===undefined|| taxFilingPlanId===null||taxFilingPlanId===0){
            taxFilingPlanId=taxInfoData.id;
        }
      }
      dispatch(getAssets(locationInfoId,taxFilingPlanId,0,event.target.value));
      setSelected([]);
    };
  
    const isSelected = (id) => selected.indexOf(id) !== -1;
  
 
    const clickHandler = () => {
        setNewAsset(true)
        dispatch(fetchAssetTypes());
        // dispatch(clearAssetsForm());
    }
    const handleClose = () => {
        setNewAsset(false);
        setUpdateAsset(false);
    }

    const handleFilter = () => {
        !filterSelected ? setFilterSelected(true) : setFilterSelected(false);
    }

    const handleOption = (option1, option2) => {
        setFilterCriteria(option1);
        setAssetType(option2);
        dispatch(getFilteredAssetsInfo(option1, option2,rowsPerPage));
        setPage(0);
    }

    const getFormattedDate = (date) => {
        let dateObj = new Date(date)
        let finalDateString = ('0' + (dateObj.getMonth() + 1)).slice(-2) + '/' + ('0' + dateObj.getDate()).slice(-2) + '/' + dateObj.getFullYear()
        
        return finalDateString
    }
    
        
    const renderAssetTableBody = () => {
        
        if(initPayload.assets){
            if(initPayload.assets.length > 0) {
                rowDataService = [];
            }
            initPayload.assets.map( data => {
                return rowDataService.push(
                    {
                        id: data.id,
                        assetDescription: data.assetDescription,
                        assetNumber: data.assetNumber, 
                        assetType: data.assetType,
                        dateAcquired: getFormattedDate(data.dateAcquired),
                        year:new Date(data.dateAcquired).getFullYear(),
                        originalPurchasePrice: data.originalPurchasePrice, 
                        quantity: data.quantity,
                        costCenter: data.costCenter
                    })
            })
        }
        rowDataService.map( data => console.log('id = '+data.assetDescription));
        return stableSort(rowDataService, getComparator(order, orderBy))
            .map((row, index) => {
            // TO DO - to replace assetDescription with identifier of the asset
            const isItemSelected = isSelected(row.id);
            const labelId = `asset-table-checkbox-${index}`;

            return (
                <TableRow hover
                //TO DO - to replace assetDescription with identifier of the asset
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    // TO DO - replace assetNumber with identifier of asset
                    key={row.id}
                    selected={isItemSelected}
                >
                    <TableCell padding="checkbox">
                        <Checkbox color="primary" checked={isItemSelected}
                        inputProps={{
                            'aria-labelledby': labelId,
                        }}
                        />
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.assetDescription}
                    </TableCell>
                    <TableCell align="center">{row.assetNumber}</TableCell>
                    <TableCell align="center">{row.assetType}</TableCell>
                    <TableCell align="center">{row.dateAcquired}</TableCell>
                    <TableCell align="center">{row.year}</TableCell>
                    <TableCell align="center">{row.originalPurchasePrice}</TableCell>
                    <TableCell align="center">{row.quantity}</TableCell>
                    <TableCell align="center">{row.costCenter}</TableCell>
                </TableRow>
            );
        })
    }
    const deleteHandler = (asset) => {
        if (asset.length > 1) {
            dispatch(deleteBulkAssetInfo(asset, rowsPerPage));
        } else {
            dispatch(deleteAssetInfoById(asset, rowsPerPage));
        }
        setSelected([]);
    }
    const handleEdit = (selected) => {
        dispatch(getAssetsInfoById(selected));
        setUpdateAsset(true);
    }

    useEffect(() => {
       selected.length > 1 ? setEditFlag(true) : setEditFlag(false)
    },[selected])


    return (
        <Fragment>
             {/* <form onSubmit={handleSubmit(onSubmit)} > */}
            <Grid container  className={classes.pageContainer} >
                <Grid item xs={12} >
                    <div> 
                        <Fade in={true} timeout={1500}>
                            <Grid container  className={classes.homeContainer} sx={{paddingTop:'30px'}}>
                                <Typography sx={{ flex: '1 1 100%', textAlign:'center',display:( newAsset || updateAsset) ? 'none':"", marginBottom:"2%",fontSize:'35px',fontWeight:'bold' }} color="inherit" variant="h3"  >
                                           Assets Information
                                            <Divider />
                                            <Typography sx={{ flex: '1 1 100%', textAlign:'initial', paddingTop:"1rem"}} color="inherit"  >
                                               If you used this application last year,review this list and make appropriate changes. Otherwise enter or import your assets using the appropriate buttons.
                                                </Typography>
                                            </Typography>
                                <Grid item xs={12} md={10}>
                                    {( newAsset || updateAsset) ? <NewAssetForm closeAction={handleClose} updateAsset={updateAsset} /> :
                                        <>
                                            
                                            <DynamicTableToolbar 
                                                numSelected={selected.length} 
                                                func={clickHandler}
                                                filter={ handleFilter }
                                                isAssets={true}
                                                delete={() => { deleteHandler(selected) }}
                                                edit={() => { handleEdit(selected) }} 
                                                editBlockFlag={editFlag}
                                            />
                                            {filterSelected ?
                                            <>
                                                <Divider style={{ margin: '5px 0'}}/>
                                                <FilterForm filterOptions={handleOption}/>    
                                            </>
                                            :
                                            <>
                                                    
                                            </>
                                            }
                                            
                                            <BulkAssetsModal retrieveAssets={retrieveAssets} isAssets={true} />
                                            <TableContainer>
                                                <Table
                                                    // sx={{ minWidth: 750 }}
                                                    aria-labelledby="assetsTable"
                                                    size='medium'
                                                >
                                                    <DynamicTableHeader
                                                        numSelected={selected.length}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onSelectAllClick={handleSelectAllClick}
                                                        onRequestSort={handleRequestSort}
                                                        rowCount={tableLength}
                                                        colHeaders={colHeaders}
                                                    />
                                                    <TableBody>
                                                        {renderAssetTableBody()}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[1, 5, 10, 25]}
                                                component="div"
                                                count={tableLength}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                          
                                        </>
                                        }
                                </Grid>
                            </Grid>
                                        </Fade>
                    </div>
                </Grid>
            </Grid>
            <input type="submit" ref={nextButton} style={{ display: 'none' }} />
            <input type="submit" ref={saveButton} style={{ display: 'none' }} />
            {/* </form> */}
        </Fragment>
    )
}

export default AssetsView;
