import { 
    GET_BUSINESS_INFO, SET_BUSINESS_INFO_SUCCESS, SET_BUSINESS_INFO_ERROR,
    FETCH_OWNERSHIP_TYPES, FETCH_OWNERSHIP_TYPES_SUCCESS,FETCH_OWNERSHIP_TYPES_FAILURE,
    FETCH_BUSINESS_TYPES, FETCH_BUSINESS_TYPES_SUCCESS, FETCH_BUSINESS_TYPES_FAILURE,
    FETCH_SIC_CODES, FETCH_SIC_CODES_SUCCESS, FETCH_SIC_CODES_FAILURE,
    FETCH_BUSINESS_CITY_STATE, FETCH_BUSINESS_CITY_STATE_SUCCESS, FETCH_BUSINESS_CITY_STATE_FAILURE, SET_UPDATE_BUSINESS_INFO, SET_UPDATE_BUSINESS_INFO_FAILURE, SET_UPDATE_BUSINESS_INFO_SUCCESS,  
    SET_BUSINESS_ADDRESS_INFO, SET_PARTIAL_BUSINESS_INFO_SUCCESS, BUSINESS_RESET, GET_BUSINESS_INFO_SUCCESS, SET_BUSINESS_ADDRESS_MODAL_STATE
} from '../actions/BusinessInfoAction';
const defaultState = {
    businessInfo: {
        businessName: '',
        corporationName: '',
        ownerName: '',
        mailingAddressLine1: '',
        mailingAddressLine2: '',
        ownershipType: '',
        businessType: '',
        sicCode: '',
        taxContactPerson: '',
        jobTitle: '',
        mobilePhone: 0,
        officePhone: 0,
        zip: '',
        state: "",
        city: '',
        email: '',
        noOfLocations: '',
        loadingStateCity: false,
        id:0,
        error: null,
        isSubmitting: "no"
    },
    isBusinessInfoSubmitting: false,
    businesstypes: [],
    ownershiptypes: [],
    siccodes: [],
    isPartialBusiness: false,
    addressSelected: false,
    modalState: false
}

const businessInfoReducer = (state = defaultState, action) => {
    switch (action.type) {
        case GET_BUSINESS_INFO:
            return { ...state, businessInfo: action.payload }
            case GET_BUSINESS_INFO_SUCCESS:
                return { ...state, businessInfo: action.payload }
            case SET_BUSINESS_INFO_SUCCESS:
                {return {...state, businessInfo: {...action.payload, isSubmitting: "yes",error:false} }}
            case SET_BUSINESS_INFO_ERROR: 
                return { ...state, businessInfo: {...action.payload, error:true,isSubmitting: "yes",errorResponse: action.payload.error} }
            case FETCH_OWNERSHIP_TYPES:
                return {...state}
            case FETCH_OWNERSHIP_TYPES_SUCCESS:
                return { ...state, ownershiptypes: action.payload }
            case FETCH_OWNERSHIP_TYPES_FAILURE:
                return { ...state, ownershiptypes:[] }
            case FETCH_BUSINESS_TYPES:
                return { ...state}
            case FETCH_BUSINESS_TYPES_SUCCESS:
                return { ...state, businesstypes: action.payload }
            case FETCH_BUSINESS_TYPES_FAILURE:
                return { ...state, businesstypes: [] }
            case FETCH_SIC_CODES:
                return { ...state }
            case FETCH_SIC_CODES_SUCCESS:
                return {...state, siccodes: action.payload}
            case FETCH_SIC_CODES_FAILURE:
                return {...state, siccodes:[]}
            case FETCH_BUSINESS_CITY_STATE: 
                return { ...state, loadingStateCity: true}
            case FETCH_BUSINESS_CITY_STATE_SUCCESS: 
               {
                console.log(`state value is ${action.payload.State}`);
                return { ...state, businessInfo: action.payload, loadingStateCity: false}
               }
            case FETCH_BUSINESS_CITY_STATE_FAILURE: 
                return { ...state, businessInfo: { ...action.payload, state: '', city: ''}, loadingStateCity: false}
            case SET_UPDATE_BUSINESS_INFO:
            case SET_UPDATE_BUSINESS_INFO_FAILURE:
                return { ...state, businessInfo: defaultState.businessInfo }
            case SET_UPDATE_BUSINESS_INFO_SUCCESS:
                { return { ...state, businessInfo: action.payload } }
            case SET_PARTIAL_BUSINESS_INFO_SUCCESS:
                {return {...state, businessInfo: action.payload, isPartialBusiness: true }}
            case SET_BUSINESS_ADDRESS_MODAL_STATE:
                return {...state, modalState: action.payload};
            case SET_BUSINESS_ADDRESS_INFO:
                {
                    return {
                        ...state, 
                        businessInfo: {
                            ...state.businessInfo,
                            mailingAddressLine1: action.payload.mailingAddressLine1,
                            mailingAddressLine2: action.payload.mailingAddressLine2
                        },
                        isPartialBusiness: false,
                        addressSelected: true
                    }
                }
            case BUSINESS_RESET:
                return {...state, businessInfo: defaultState.businessInfo, isPartialBusiness: false, addressSelected: false};
        default:
            return state;
    }
};
export default businessInfoReducer;