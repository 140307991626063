import React, { useEffect, useState } from 'react';
import {
    Grid, Table, TableBody, TableCell, TableContainer,
    Fade, TablePagination, TableRow,
    Checkbox, Typography, Divider } from '@mui/material';
import { colHeaders } from './LeasedAssetsData';
import DynamicTableHeader from '../../../layout/DynamicTableHeader';
import { stableSort } from '../../../util/compatibilityUtility';
import { getComparator } from '../../../util/sortUtility';
import DynamicTableToolbar from '../../../layout/DynamicTableToolbar';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../styles/mainPagesCSS';
import LeasedAssetFilterForm from './LeasedAssetFilterForm';
import { deleteLeasedAssetInfo, getLeasedAssetInfoById, getLeasedAssetInfoByLoc, clearLeasedAssetsForm, deleteBulkLeasedAssetsInfo, getFilteredLeasedAssetsInfo } from '../../../redux/actions/LeasedAsestAction';
import { getLeasedAssets } from '../selectors/LeasedAssetSelectors';
import { getStateValue, getCityValue } from '../selectors/AppClientSelectors';
import { BulkAssetsModal } from "../../../layout/BulkAssetsModal";
import NewLeasedAssetForm from './NewLeasedAssetForm';
import { useForm } from "react-hook-form";
import { setAppServiceInfo } from '../../../redux/actions/AppServiceInfoAction';

const LeasedAssetsView = ({ nextButton, buttonStackRef,saveButton  }) => {

    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const dispatch = useDispatch();
    const [newAsset, setNewAsset] = useState(null);
    const [updateAsset, setUpdateAsset] = useState(false);
    const [filterSelected, setFilterSelected] = useState(false);
    const [filterCriteria, setFilterCriteria] = useState(null);
    const leasedAssetData = useSelector(getLeasedAssets);
    const stateValues = useSelector(getStateValue);
    const cityValues = useSelector(getCityValue);
    const [editFlag, setEditFlag] = useState(false);

    let rowData = [];
    let tableLength = 0;
    if (leasedAssetData) {
        rowData = leasedAssetData.leasedAssets;
    }
    if(leasedAssetData && leasedAssetData.totalItems) {
        tableLength = leasedAssetData.totalItems;
    }

    const { formState: {  } } = useForm({validations: { // all our validation rules go here
    }, });

    buttonStackRef.current.style.display = '';
    useEffect(() => {
        if (newAsset || updateAsset) {
            buttonStackRef.current.style.display = 'none';
        } else{
            buttonStackRef.current.style.display = '';
        }
      });
      
      useEffect( () => {
        dispatch(setAppServiceInfo(0,null,false,false));
      },[dispatch]);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
  
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rowData.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    useEffect(() => {
        selected.length > 1 ? setEditFlag(true) : setEditFlag(false)
     },[selected])
  
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        
  
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
  
        setSelected(newSelected);
    };
  
    const handleChangePage = (event, newPage) => {
        let limit = rowsPerPage;
        dispatch(getLeasedAssetInfoByLoc((newPage * rowsPerPage), limit));
        setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        dispatch(getLeasedAssetInfoByLoc(0, event.target.value))
        setPage(0);
        
    };
  
    const isSelected = (id) => selected.indexOf(id) !== -1;
     
  
    const clickHandler = () => {
        setNewAsset(true);
        dispatch(clearLeasedAssetsForm());

    }

    const handleClose = () => {
        setNewAsset(false);
        setUpdateAsset(false);
    }
    const handleFilter = () => {
        !filterSelected ? setFilterSelected(true) : setFilterSelected(false);
    }

    const handleOption = (option) => {
        dispatch(getFilteredLeasedAssetsInfo(option,(page * rowsPerPage),rowsPerPage));
        // setPage(0);
        setFilterCriteria(option);
    }

    const deleteHandler = (leasedAssets) => {
        if (leasedAssets.length > 1) {
            dispatch(deleteBulkLeasedAssetsInfo(leasedAssets, rowsPerPage));
        } else {
            dispatch(deleteLeasedAssetInfo(leasedAssets, rowsPerPage))
        }
        setSelected([]);
    }

    const getStateDisplayValue = (loadedState) => {
        let value;
        stateValues.forEach((ele) => {
            if (ele.id === loadedState) {
                value = ele.codeValueKey
            }
        })
        return value;
    }
    

    const getCityDisplayValue = (loadedCity) => {
        let value;
        cityValues.forEach((ele) => {
            if (ele.id === loadedCity) {
                value = ele.codeValueKey
            }
        })
        return value;
    }

    const getFormattedDate = (date) => {
        let dateObj = new Date(date)
        let finalDateString = ('0' + (dateObj.getMonth() + 1)).slice(-2) + '/' + ('0' + dateObj.getDate()).slice(-2) + '/' + dateObj.getFullYear()
        
        return finalDateString
    }

    const handleEdit = (selected) => {
        dispatch(getLeasedAssetInfoById(selected));
        setUpdateAsset(true);
    }

    useEffect(() => {
        dispatch(getLeasedAssetInfoByLoc(0, rowsPerPage));
    },[dispatch, rowsPerPage]);

    const retrieveLeasedAssets = () => {
        dispatch(getLeasedAssetInfoByLoc(0, rowsPerPage));
    }
        const renderAssetTableBody = () => {
         if (leasedAssetData) {
            if (leasedAssetData.leasedAssets.length > 0) {
                rowData = [];
            }
            leasedAssetData.leasedAssets.map(data => {
                return rowData.push({
                    id: data.id, 
                    address: data.address, 
                    leaseEndDate: getFormattedDate(data.leaseEndDate),
                    leaseStartDate: getFormattedDate(data.leaseStartDate),
                    leasedAssetDescription: data.leasedAssetDescription,
                    leasingCompanyName: data.leasingCompanyName, 
                    leasedNumber: data.leasedNumber
                })
            })
        }
        
        return stableSort(rowData, getComparator(order, orderBy))
        .map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `asset-table-checkbox-${index}`;
            let addressLine = row.address.line1;
            if(row.address.line2 && row.address.line2 !== '') {
                addressLine = `${row.address.line1}, ${row.address.line2}`;
            }

            return (
                <TableRow hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                >
                    <TableCell padding="checkbox">
                        <Checkbox color="primary" checked={isItemSelected}
                        inputProps={{
                            'aria-labelledby': labelId,
                        }}
                        />
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none" align="center">
                        {row.leasedAssetDescription}
                    </TableCell>
                    <TableCell align="center">{row.leasedNumber}</TableCell>
                    <TableCell align="center">{row.leasingCompanyName}</TableCell>
                    <TableCell align="center">{getFormattedDate(row.leaseStartDate)}</TableCell>
                    <TableCell align="center">{getFormattedDate(row.leaseEndDate)}</TableCell>
                    <TableCell align="center">{addressLine}</TableCell>
                    <TableCell align="center">{row.address.zipcode}</TableCell>
                    <TableCell align="center">{getCityDisplayValue(row.address.city)}</TableCell>
                    <TableCell align="center">{getStateDisplayValue(row.address.state)}</TableCell>
                </TableRow>
            );
        })
    }

    return (
        <>
        {/* <form onSubmit={handleSubmit(onSubmit)} > */}
                <Grid container  className={classes.pageContainer}>
                    <Grid item xs={12}>
                        <div >
                            <Fade in={true} timeout={1500}>
                            <Grid container  className={classes.homeContainer} sx={{paddingTop:'30px'}}>
                                    <Typography sx={{ flex: '1 1 100%',textAlign:'center', display:( newAsset || updateAsset) ? 'none':"", marginBottom:"2%",fontSize:'35px',fontWeight:'bold' }} color="inherit" variant="h3"  >
                                        Leased Assets Information
                                        <Divider />
                                        <Typography sx={{ flex: '1 1 100%', textAlign:'initial',paddingTop:"1rem"}} color="inherit"  >
                                                If you used this application last year,review this list and make appropriate changes. Otherwise enter or import your assets using the appropriate buttons.
                                                </Typography>
                                    </Typography>
                                <Grid item xs={12} md={10}>
                                {(newAsset || updateAsset) ? <NewLeasedAssetForm closeAction={handleClose} updateAsset={updateAsset} /> : 
                                <>
                                    <DynamicTableToolbar
                                        numSelected={selected.length}
                                        delete={() => { deleteHandler(selected) }}
                                        func={clickHandler}
                                        filter={handleFilter}
                                        edit={()=>{handleEdit(selected)}}
                                        isAssets={true}
                                        editBlockFlag={editFlag}
                                    />
                                    {
                                        filterSelected ?
                                        <>
                                            <Divider style={{ margin: '5px 0' }} />
                                            <LeasedAssetFilterForm filterOptions={handleOption}/>
                                        </>
                                        :
                                        <></>
                                    }
                                    <BulkAssetsModal retrieveAssets={retrieveLeasedAssets} isLeaseAssets={true} />
                                  
                            <TableContainer>
                                <Table
                                    aria-labelledby="assetsTable"
                                    size='medium'
                                >
                                    <DynamicTableHeader
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={tableLength}
                                        colHeaders={colHeaders}
                                    />
                                    <TableBody>
                                    {renderAssetTableBody()}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[1, 5, 10, 25]}
                                component="div"
                                count={tableLength}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                                </>
                            }
                        </Grid>
                    </Grid>
                </Fade>
            </div>
        </Grid>
    </Grid>
    <input type="submit" ref={nextButton} style={{ display: 'none' }}/>
    <input type="submit" ref={saveButton} style={{ display: 'none' }} />
    {/* </form> */}
        </>
    )
}

export default LeasedAssetsView;
