import React, { Fragment, useRef, useState, useEffect } from "react";
import {
  Grid,
  Fade,
  TextField,
  Button,
  Typography,
  LinearProgress,
  Box,
  Modal,
  Backdrop,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setAgentAddressSelectedFlag,
  submitAgentProfileDetails,
  submitAgentProfileDetailsForUpdate,
  updateAgentProfileInfo,
  verifyAgentProfileZipCode,
} from "./AgentProfileActions";
import {
  getAgentProfilePayload,
  getLoadedCity,
  getIsLoadingStateCity,
  getLoadedState,
} from "./AgentProfileSelectors";
import { getIsProfileComplete } from "../../appClient/AppClientSelectors";
import ScrollUpButton from "react-scroll-up-button";
import useStyles, { theme } from "../../../styles/mainPagesCSS";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  validateMailFormat,
  validateMobileNumberFormat,
  validateTextFormat,
  validateNumericFormat,
  validateAddressFormat,
} from "../../../util/utilities";
import {
  getClientDetails,
  getStateValue,
  getCityValue,
} from "../../appClient/AppClientSelectors";
import { getAddressModalState } from "../userProfile/UserProfileSelectors";
import AddressSuggestionModal from "../../addressSuggestion/AddressSuggestionModal";
import { useHistory } from "react-router";
import { ThemeProvider } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

const AgentProfileView = () => {
  const errObjInitialState = {
    nameErr: false,
    phoneNumErr: false,
    mailErr: false,
    validationErr: false,
    addressLine1Err: false,
    zipErr: false,
    agentCompNameErr: false,
  };

  const classes = useStyles();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const history = useHistory();
  const clientDetails = useSelector(getClientDetails);
  let mailInput = useRef(null);
  const [errs, setErrs] = useState(errObjInitialState);
  const cityValues = useSelector(getCityValue);
  const stateValues = useSelector(getStateValue);
  const agentProfileInitPayload = useSelector(getAgentProfilePayload);
  const agentProfileData = agentProfileInitPayload.agentprofile;
  const [agentProfile, setAgentProfile] = useState({ ...agentProfileData });
  const loadedCity = useSelector(getLoadedCity);
  const loadedState = useSelector(getLoadedState);
  const isLoadingStateCity = useSelector(getIsLoadingStateCity);
  const isProfileComplete = useSelector(getIsProfileComplete);
  const addressModalState = useSelector(getAddressModalState);

  const getStateMappingValue = (stateId) => {
    if (stateValues) {
      for (let value of stateValues) {
        if (value.id === stateId) {
          return value.codeValueKey;
        }
      }
    }
  };

  const getMappedCityValue = (cityId) => {
    if (cityValues) {
      for (let value of cityValues) {
        if (value.id === cityId) {
          return value.codeValueKey;
        }
      }
    }
  };

  let agentCountryCode = "";
  if (
    agentProfile &&
    agentProfile.agentCountyCodes &&
    agentProfile.agentCountyCodes.length > 0
  ) {
    agentCountryCode = agentProfile.agentCountyCodes[0].agentCountyCode;
  }
  // const [input, setInput] = useState(agentCountryCode);
  const [input, setInput] = useState("");
  let initialCodes = [];
  if (agentProfile.codes) {
    initialCodes = agentProfile.codes;
  }
  const [
    codes, setCodes] = useState(initialCodes);
  let initialCity = "";
  if (agentProfile && agentProfile.address) {
    initialCity = agentProfile.address.city;
  }
  const [fetchedCity, setFetchedCity] = useState(
    getMappedCityValue(initialCity)
  );
  let initialState = "";
  if (agentProfile && agentProfile.address) {
    initialState = agentProfile.address.state;
  }
  const [fetchedState, setFetchedState] = useState(
    getStateMappingValue(initialState)
  );

  // setAgentProfile({
  //     name:clientDetails.clientName, email: clientDetails.emailId, phoneNum: clientDetails.phoneNumber,
  //     addressLine1: clientDetails.address.line1, addressLine2: '', zip: clientDetails.address.zipcode,
  //      state: clientDetails.address.state, city: clientDetails.address.city, codes:[]
  // });

  const clearValues = () => {
    setAgentProfile({
      name: "",
      email: "",
      phoneNum: 0,
      address: {},
      state: "",
      city: "",
      codes: [],
    });
    setErrs({
      nameErr: false,
      phoneNumErr: false,
      mailErr: false,
      validationErr: false,
      addressLine1Err: false,
      zipErr: false,
      agentCompNameErr: false,
    });
  };

  const getStateId = (stateName) => {
    for (let value of stateValues) {
      if (value.codeValueKey === stateName) {
        return value.id;
      }
    }
  };
  const getCityId = (cityName) => {
    for (let value of cityValues) {
      if (value.codeValueKey === cityName) {
        return value.id;
      }
    }
  };

  const validateZipAndFetchStateCityValues = (val) => {
    if (validateNumericFormat(val)) {
      setAgentProfile({
        ...agentProfile,
        address: { ...agentProfile.address, zipcode: val },
      });
      if (val.length !== 5) {
        setFetchedCity("");
        setFetchedState("");
        setErrs({ ...errs, validZipErr: true, zipErr: false });
      } else {
        setErrs({ ...errs, validZipErr: false, zipErr: false });
        dispatch(verifyAgentProfileZipCode(val));
      }
    } else {
      setErrs({ ...errs, validZipErr: true, zipErr: false });
    }
  };

  const handleTxt = (evt, txtType) => {
    let inpFieldValue = evt.target.value;
    switch (txtType) {
      case "name":
        setAgentProfile({ ...agentProfile, name: inpFieldValue });
        if (validateTextFormat(inpFieldValue)) {
          setErrs((prevState) => ({ ...prevState, nameErr: false }));
        } else {
          setErrs((prevState) => ({ ...prevState, nameErr: true }));
        }
        break;
      case "phoneNum":
        let phoneNumber = evt.target.value.replace(/[^0-9]/g, "");
        setAgentProfile({ ...agentProfile, phoneNum: phoneNumber });
        if (validateMobileNumberFormat(phoneNumber)) {
          let parsedNumber = phoneNumber;
          if (phoneNumber.length === 10) {
            parsedNumber = phoneNumber.replace(
              /(\d{3})(\d{3})(\d{4})/,
              "($1) $2-$3"
            );
          }
          setAgentProfile({ ...agentProfile, phoneNum: parsedNumber });
          setErrs((prevState) => ({ ...prevState, phoneNumErr: false }));
        } else {
          setErrs((prevState) => ({ ...prevState, phoneNumErr: true }));
        }
        break;
      case "email":
        setAgentProfile({ ...agentProfile, email: inpFieldValue });
        if (validateMailFormat(inpFieldValue)) {
          setErrs((prevState) => ({
            ...prevState,
            mailErr: false,
            validationErr: false,
          }));
        } else {
          setErrs((prevState) => ({
            ...prevState,
            mailErr: true,
            validationErr: true,
          }));
        }
        break;
      case "agentCompName":
        setAgentProfile({ ...agentProfile, agentCompName: inpFieldValue });
        if (validateTextFormat(inpFieldValue)) {
          setErrs((prevState) => ({ ...prevState, agentCompNameErr: false }));
        } else {
          setErrs((prevState) => ({ ...prevState, agentCompNameErr: true }));
        }
        break;
      case "addressLine1":
        setAgentProfile({
          ...agentProfile,
          address: { ...agentProfile.address, line1: inpFieldValue },
        });
        dispatch(setAgentAddressSelectedFlag(false));
        if (validateAddressFormat(inpFieldValue)) {
          setErrs((prevState) => ({ ...prevState, addressLine1Err: false }));
        } else {
          setErrs((prevState) => ({ ...prevState, addressLine1Err: true }));
        }
        break;
      case "addressLine2":
        setAgentProfile({
          ...agentProfile,
          address: { ...agentProfile.address, line2: inpFieldValue },
        });
        break;
      case "city":
        setAgentProfile({ ...agentProfile, city: inpFieldValue });
        break;
      case "state":
        setAgentProfile({ ...agentProfile, state: inpFieldValue });
        break;
      case "zip":
        validateZipAndFetchStateCityValues(evt.target.value);
        break;

      default:
    }
  };

  const execScroll = () =>
    formRef.current.scrollIntoView({ behavior: "smooth" });

  const handleSubmit = (event) => {
    let error = false;
    !agentProfile.name
      ? setErrs((prevState) => ({ ...prevState, nameErr: true }))
      : setErrs((prevState) => ({ ...prevState, nameErr: false }));
    !agentProfile.phoneNum
      ? setErrs((prevState) => ({ ...prevState, phoneNumErr: true }))
      : setErrs((prevState) => ({ ...prevState, phoneNumErr: false }));
    !agentProfile.email
      ? setErrs((prevState) => ({ ...prevState, mailErr: true }))
      : setErrs((prevState) => ({ ...prevState, mailErr: false }));
    !agentProfile.agentCompName
      ? setErrs((prevState) => ({ ...prevState, agentCompNameErr: true }))
      : setErrs((prevState) => ({ ...prevState, agentCompNameErr: false }));
    agentProfile.address && !agentProfile.address.line1
      ? setErrs((prevState) => ({ ...prevState, addressLine1Err: true }))
      : setErrs((prevState) => ({ ...prevState, addressLine1Err: false }));
    agentProfile.address && !agentProfile.address.zipcode
      ? setErrs((prevState) => ({ ...prevState, zipErr: true }))
      : setErrs((prevState) => ({ ...prevState, zipErr: false }));
    fetchedCity === "" || fetchedState === ""
      ? setErrs((prevState) => ({ ...prevState, validZipErr: true }))
      : setErrs((prevState) => ({ ...prevState, validZipErr: false }));
    if (
      !agentProfile.name ||
      !agentProfile.phoneNum ||
      !agentProfile.email ||
      !agentProfile.agentCompName ||
      !agentProfile.address.line1 ||
      !agentProfile.address.zipcode
    ) {
      error = true;
    }
    if (error) {
      event.stopPropagation();
    }

    if (!error && Object.values(errs).every((item) => item === false)) {
      let userId = clientDetails.userId;
      let stateValue = getStateId(fetchedState);
      let cityValue = getCityId(fetchedCity);
      isProfileComplete
        ? dispatch(
            submitAgentProfileDetailsForUpdate({
              ...agentProfile,
              codes,
              stateValue,
              cityValue,
              userId,
            })
          )
        : dispatch(
            submitAgentProfileDetails({
              ...agentProfile,
              stateValue,
              cityValue,
              userId,
            })
          );
      // clearValues();
    } else {
      execScroll();
    }
  };
  useEffect(() => {
    // Code that depends on the updated `codes` state
  }, [codes]);
  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
    setAgentProfile({ ...agentProfile, codes: [...codes, value] });
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const inputTrim = input.trim();
    if (key === "Enter" && inputTrim.length && !codes.includes(inputTrim)) {
      e.preventDefault();
      setCodes((prevState) => [...prevState, inputTrim]);
      setInput("");
    }
  };

  // const deleteCode = (value) => {
  //   setCodes((prevState) => prevState.filter((code, i) => code !== value));
  // };
  const deleteCode = (value) => {
    setCodes((prevState) => {
      const updatedCodes = prevState.filter((code) => code !== value);
      console.log('Updated Codes:', updatedCodes);
      return updatedCodes;  // Return the updated state
    });
  };

  const handleCancelClick = () => {
    history.push("/appClientHome");
  };

  useEffect(() => {
    setFetchedCity(loadedCity);
    setFetchedState(loadedState);
  }, [loadedCity, loadedState]);

  return (
    <Fragment>
      {/* {
                addressModalState ?
                <> */}
      <Box>
        <Grid container>
          <Modal
            open={addressModalState}
            hideBackdrop
            disableEscapeKeyDown={true}
            aria-labelledby="app-modal"
            aria-describedby="app-modal-client"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            disableScrollLock={true}
            className={classes.modalRoot}
          >
            <Fade in={addressModalState}>
              <Box>
                <AddressSuggestionModal />
              </Box>
            </Fade>
          </Modal>
        </Grid>
      </Box>

      {/* </> : */}
      <Grid
        container
        className={
          addressModalState === true ? classes.paneRootBlur : classes.paneRoot
        }
      >
        <Grid item xs={12}>
          <div className={classes.paneBox}>
            <Fade in={true} timeout={1500}>
              <Grid container ref={formRef}>
                <Grid item xs={12} className={classes.formInputs}>
                  <Typography variant="h4" className={classes.loginTitle}>
                    Agent Profile
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  {clientDetails && (
                    <TextField
                      required
                      id="name"
                      label="Agent Name"
                      variant="standard"
                      className={classes.fields}
                      onChange={(e) => handleTxt(e, "name")}
                      error={errs.nameErr}
                      helperText={errs.nameErr && `Enter a Valid Name`}
                      value={agentProfile.name}
                    />
                  )}
                </Grid>

                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    required
                    id="phoneNum"
                    label=" Phone Number"
                    variant="standard"
                    type="text"
                    className={classes.fields}
                    onChange={(e) => handleTxt(e, "phoneNum")}
                    error={errs.phoneNumErr}
                    inputProps={{ maxLength: 10 }}
                    helperText={
                      errs.phoneNumErr && `Enter a Valid Mobile Number`
                    }
                    value={agentProfile.phoneNum || ""}
                  />
                </Grid>

                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    className={classes.fields}
                    required
                    id="email"
                    label="Email"
                    variant="standard"
                    type="email"
                    onChange={(e) => handleTxt(e, "email")}
                    error={errs.validationErr || errs.mailErr}
                    helperText={
                      errs.validationErr
                        ? `Enter valid email address`
                        : errs.mailErr
                        ? `Enter registered email address`
                        : null
                    }
                    inputRef={mailInput}
                    value={agentProfile.email || ""}
                  />
                </Grid>

                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    className={classes.fields}
                    required
                    id="agentCompName"
                    label="Agent Company Name"
                    variant="standard"
                    type="email"
                    onChange={(e) => handleTxt(e, "agentCompName")}
                    error={errs.agentCompNameErr}
                    helperText={errs.agentCompNameErr && `Enter a valid name`}
                    value={agentProfile.agentCompName || ""}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    required
                    id="addressLine1"
                    label="Agent Company Mailing Address"
                    variant="standard"
                    className={classes.fields}
                    onChange={(e) => handleTxt(e, "addressLine1")}
                    error={errs.addressLine1Err}
                    helperText={errs.addressLine1Err && `Enter a valid address`}
                    value={
                      (agentProfile.address && agentProfile.address.line1) || ""
                    }
                  />
                </Grid>

                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    id="addressLine2"
                    label="Address Line 2"
                    variant="standard"
                    className={classes.fields}
                    onChange={(e) => handleTxt(e, "addressLine2")}
                    value={
                      (agentProfile.address && agentProfile.address.line2) || ""
                    }
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    lg={4}
                    className={classes.formInputs}
                  >
                    <TextField
                      required
                      className={classes.fields}
                      id="zip"
                      label="Zip"
                      variant="standard"
                      type="text"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => handleTxt(e, "zip")}
                      error={errs.zipErr || errs.validZipErr}
                      helperText={
                        errs.zipErr
                          ? `Enter Zip Code`
                          : errs.validZipErr
                          ? `Enter valid Zip code`
                          : null
                      }
                      inputProps={{ maxLength: 5 }}
                      value={
                        (agentProfile.address &&
                          agentProfile.address.zipcode) ||
                        ""
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    lg={4}
                    className={classes.formInputs}
                  >
                    <TextField
                      required
                      id="city"
                      label="City"
                      variant="standard"
                      disabled
                      value={fetchedCity}
                      InputLabelProps={{ shrink: true }}
                      className={classes.fields}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    lg={4}
                    className={classes.formInputs}
                  >
                    <TextField
                      required
                      id="state"
                      label="State"
                      variant="standard"
                      disabled
                      value={fetchedState}
                      InputLabelProps={{ shrink: true }}
                      className={classes.fields}
                    />
                  </Grid>
                </Grid>
                {isLoadingStateCity && (
                  <Grid item xs={12} className={classes.formInputs}>
                    <LinearProgress />
                  </Grid>
                )}
                <Grid item xs={12} className={classes.formInputs}>
                  {/* {codes.map((code) => <>{code}</>)} */}
                  <TextField
                    value={input || ""}
                    id="agentCodes"
                    label="Enter your agent code for each county you operate in"
                    variant="standard"
                    className={classes.fields}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                  />
                  {codes.map((code, index) => (
                    <>  
                    <div key={index}>
                      {code}
                      <Button
                        onClick={() => deleteCode(code)}
                        startIcon={<DeleteIcon />}
                      />
                      <br />
                      </div>
                    </>
                  ))}
                </Grid>
                <Grid container spacing={2} className={classes.buttonContainer}>
                  <ThemeProvider theme={theme}>
                    <Stack
                      spacing={2}
                      style={{ width: "100%" }}
                      direction="row"
                      justifyContent="right"
                    >
                      <Button
                        color="secondary"
                        variant="contained"
                        size="medium"
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        onClick={handleSubmit}
                      >
                        {" "}
                        {isProfileComplete ? `Update` : `Submit`}{" "}
                      </Button>
                    </Stack>
                  </ThemeProvider>
                  {/* <Grid item xs={12} md={8} lg={4} className={classes.formInputs}>
                                        <Button 
                                            className={classes.cancelBtn} size="medium" color="inherit" onClick={handleCancelClick} >
                                            Cancel 
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={4} className={classes.formInputs}>
                                        <Button 
                                            className={classes.saveBtn} size="medium" color="inherit" onClick={handleSubmit} >
                                            {isProfileComplete ? `Update`: `Submit`}
                                        </Button>
                                    </Grid> */}
                </Grid>
              </Grid>
            </Fade>
          </div>
        </Grid>
      </Grid>
      {/* } */}
      <ScrollUpButton
        style={{ backgroundColor: "rgb(34 71 126 / 72%)", bottom: "100px" }}
      />
    </Fragment>
  );
};

export default AgentProfileView;
