import { Box, Fade, Modal, Backdrop, Grid, Divider, Button, TextField, Typography, FormHelperText, LinearProgress} from '@mui/material';
import React, { Fragment, useEffect, useState} from 'react';
import Moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import useStyles, { standardButtons, theme }  from '../styles/mainPagesCSS';
import { useSelector, useDispatch } from 'react-redux';
import { getBulkAssetsModalState } from '../components/appClient/AppClientSelectors';
import { setBulkModalState } from '../components/appClient/AppClientActions';
import * as FileSaver from 'file-saver';
import  xlsxReader from 'xlsx';
import assetXlsFile from './bulk-assets-import-template.xlsx';
import leaseAssetXlsFile from './bulk-lease-assets-import-template.xlsx';
import * as BulkAssetsImportUtils from './BulkAssetsImportUtil';
import axios from 'axios';
import config from '../config/context-root-configs';
import { getAssetTypes } from "../components/appClient/selectors/AssetsInfoSelector";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from '@mui/styles';
import Stack from '@mui/material/Stack';
import { getStateValue, getCityValue } from '../components/appClient/AppClientSelectors';
import { getLocationInfo } from '../components/appClient/selectors/LocationInfoSelector';
import { getTaxFillingData } from '../components/appClient/selectors/TaxFilingPlanSelector';
import { getBulkAssets,GET_BULKASSET_INFO_SUCCESS } from '../redux/actions/AssetsInfoActions';

import { getTaxInfo } from '../components/appClient/selectors/TaxInfoSelector' //ADDED FOR RETRIVING THE TAX PLAN ID


export const BulkAssetsModal = ({retrieveAssets,isAssets,isLeaseAssets}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
    
  const bulkAssetsModalstate = useSelector(getBulkAssetsModalState);
  const [formattedBulkAssetInfo, setFormattedBulkAssetInfo] = useState('');
  const [uploadSuccessMessage, setUploadSuccessMessage] = useState('');
  const taxFilingPlanData = useSelector(getTaxFillingData);
  const [addressValidationStatus, setAddressValidationStatus] = useState(null);
  const [addressValidationMessage, setAddressValidationMessage] = useState('');
  const [assetsImportStatusInfo, setAssetsImportStatusInfo] = useState({
    bulkImportStatus: '',
    bulkImportAssetsCount: 0,
    bulkAssetsErrorDetails: '',
    loadingValidationResults: true,
    loadingMessage: 'Please wait while we retrieve previous assets importing details if any.',
    isGetFailed: false
  });
  const assetTypes = useSelector(getAssetTypes);
  const stateValues = useSelector(getStateValue);
  const cityValues = useSelector(getCityValue);
  const locationInfo = useSelector(getLocationInfo);

  const taxInfoData = useSelector(getTaxInfo); //ADDED FOR RETRIVING THE TAX PLAN ID FROM GETTAXINFO SELECTOR

const useSpinnerStyles = makeStyles({
  eventDialogDelete: {
    position: "relative",
    "& .MuiCircularProgress-root": {
      position: "absolute",
      // Moving this a little off the edge prevents horizontal scrollbar from flickering in and out
      top: 3,
      right: 3
    },
    "& .MuiFab-sizeSmall": {
      position: "absolute",
      top: 9,
      right: 9
    }
  }
});

const spinnerClass = useSpinnerStyles();

const downloadTemplateFile = async () => {
  let xlsFile;
  if(isAssets){
    xlsFile = assetXlsFile;
  } else if(isLeaseAssets) {
    xlsFile = leaseAssetXlsFile;
  }
  try {
    fetch(xlsFile, {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        var updatedBlob = new Blob([blob], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });
        const currentDateTime = Moment().format("YYYYMMDDHHmmssSSS");
        console.log('currentDateTime:', currentDateTime);
        if(isAssets){
          FileSaver.saveAs(updatedBlob, `bulk-assets-import-template_${currentDateTime}.xlsx`);
        } else if(isLeaseAssets){
          FileSaver.saveAs(updatedBlob, `bulk-lease-assets-import-template_${currentDateTime}.xlsx`);
        }
      });
  } catch (error) {
    console.log('err:', error);
  }
};

useEffect(() => {
  if (!addressValidationStatus) {
    setAddressValidationMessage('');  
  } else {
    setAddressValidationMessage('Please wait for few seconds for the address validation to complete before uploading.');
  }
},[addressValidationStatus])



useEffect(() => {
  const preLoadData = async () => {
    // Call service to get previous bulk import status and other details
    let bulkImportAssetsCount = 0,
      bulkImportStatus = '',
      isGetFailed = false;

      let locationInfoId = null;
      if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
          if(taxFilingPlanData.taxFilingPlan){
              locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
          }
         
      }
      if(locationInfoId == null || locationInfoId === undefined){
        locationInfoId = locationInfo != null ? locationInfo.id:'';
    }
        
    let assetType = isAssets?"ASSET":"LEASEDASSET";
   dispatch(getBulkAssets(locationInfoId,assetType));
    // await axios.get(config.bulkAssets, { params: { locationInfoId: locationInfoId } }).then(
    //   (response) => {
    //     if (response.status === 200 && response.data && response.data.assetsCount) {
    //       bulkImportAssetsCount = response.data.assetsCount;
    //       bulkImportStatus = response.data.status;
    //       isGetFailed = false;
    //       //TODO if status is ERROR then populate errorDetails
    //       if ('ERROR' === bulkImportStatus) {
    //         isGetFailed = true;
    //         // TODO need to map error details in such a way that the error table can render
    //       }
    //     }
    //   },
    //   (error) => {
    //     console.log('error:::', error);
    //   },
    // );
    setAssetsImportStatusInfo((prevStateInfo) => {
      return {
        bulkImportStatus,
        isGetFailed,
        bulkImportAssetsCount,
        bulkAssetsErrorDetails: '',
        loadingValidationResults: false,
        loadingMessage: prevStateInfo.loadingMessage,
      };
    });
  };
  preLoadData();
}, [locationInfo]);

const readInputFile = async (event) => { 

  const inputFile = event.target.files[0];
  console.log('inputFile', inputFile);
  const fileReader = new FileReader();
  const rABS = !!fileReader.readAsBinaryString;  
  let locationInfoId = null;
  let taxFilingPlanId = null;
  if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
      if(taxFilingPlanData.taxFilingPlan){
          locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
          taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
          if(taxFilingPlanId===null || taxFilingPlanId===undefined|| taxFilingPlanId===0 || taxFilingPlanId===""){
            taxFilingPlanId = taxInfoData.id;
          }
      }
      
  }
  if(locationInfoId == null || locationInfoId === undefined){
    locationInfoId = locationInfo != null ? locationInfo.id:'';
}
if(taxFilingPlanId == null || taxFilingPlanId === undefined){
    taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
    if(taxFilingPlanId===null || taxFilingPlanId===undefined|| taxFilingPlanId===0 || taxFilingPlanId===""){
      taxFilingPlanId = taxInfoData.id;
    }

}

  
  fileReader.onload = function(event) {
    let input_assets_json;
    try {
      var data = event.target.result;
      const workbook = xlsxReader.read(data, { type: rABS ? 'binary' : 'array' });
      const sheetName = workbook.SheetNames[0];
      input_assets_json = xlsxReader.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
        blankrows: false,
      });
      
    } catch (error) {
      // dont need to do anything. below condition will takes care of it.
    }
    //Validate whether the uploaded template is the right one.
    if (BulkAssetsImportUtils.isValidBulkImportDocument(input_assets_json,isAssets)) {
      // validate the input assets & convert input json to a valid assetsInfo JSON for services to understand
      // If no errors then only convert input data to valid assetInfo
      const assetsInfo_inputErrors = BulkAssetsImportUtils.validateAssetInfo(input_assets_json, assetTypes,isAssets,stateValues, cityValues,taxFilingPlanId);
      if (!isAssets) {
        const check = assetsInfo_inputErrors.formatted_assets_info;
        const promises = []
        setAddressValidationStatus(true);
        check.forEach((item) => {
          promises.push(
            new Promise((resolve) => {
              item.address.result.then((res) => {
                resolve({ city: res.city, state: res.state })
              })
            })
          )
        })
        Promise.all(promises).then((result) => {
          setAddressValidationStatus(false);
        });
      }
      
      //if no errors then convert input data to valid assetInfo
      console.log('assetsInfo_inputErrors', assetsInfo_inputErrors);
      let bulkImportStatus = '';
      setUploadSuccessMessage('');
      if (assetsInfo_inputErrors && assetsInfo_inputErrors.assets_info_with_errors.length === 0) {
        let assetType = 'ASSET';
        if(isAssets){
          assetType = 'ASSET';
        } else {
          assetType = 'LEASED ASSET';
        }
      
        // call service to save the data/ this can be fire and forgot based on assets length
        const bulkAssetsInfo = {
          assetsInfo: assetsInfo_inputErrors.formatted_assets_info,
          locationInfoId: locationInfoId,
          assetType, 
          taxFilingPlanId: taxFilingPlanId
        };
        setFormattedBulkAssetInfo(bulkAssetsInfo);
        
      } else {
        bulkImportStatus = 'ERROR';
        setUploadSuccessMessage('File contains invalid data');
      }
      setAssetsImportStatusInfo((prevStateInfo) => {
        return {
          bulkImportStatus,
          bulkImportAssetsCount: input_assets_json.length - 1,
          bulkAssetsErrorDetails: assetsInfo_inputErrors.assets_info_with_errors,
          loadingValidationResults: true,
          loadingMessage: '',
        };
      });
    } else {
      setAssetsImportStatusInfo((prevStateInfo) => {
        return {
          bulkImportStatus: 'ERROR',
          bulkImportAssetsCount: 0,
          bulkAssetsErrorDetails: '',
          loadingValidationResults: false,
          loadingMessage: '',
        };
      });
      setUploadSuccessMessage(
        'Invalid Input File',
      );
    }
  };
  if (rABS) {
    fileReader.readAsBinaryString(inputFile);
  } else {
    fileReader.readAsArrayBuffer(inputFile);
  }
};
    
    const handleOnClose =  () => {
      setAddressValidationStatus(false);
      setAddressValidationMessage('');
      dispatch(setBulkModalState(false));
    };
    
    const handleSubmit = () => {
      let bulkImportStatus = '';
      let assetType = 'ASSET';
      if(isAssets){
        assetType = 'ASSET';
      } else {
        assetType = 'LEASEDASSET';
      }
      let locationInfoId = null;
    
      if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
          if(taxFilingPlanData.taxFilingPlan){
              locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
          }
  
      }
      if(locationInfoId == null || locationInfoId === undefined){
        locationInfoId = locationInfo != null ? locationInfo.id:'';
    }
  
      let queryParam=`?locationInfoId=${locationInfoId}&assetType=${assetType}`
      try {
        axios
          .post(`${config.bulkAssets}/${queryParam}`, formattedBulkAssetInfo)
          .catch((error) => {
            // logger.error('Error: Unable to send bulk assets to server. ' + error);
            console.log('error 1:', error);
            let response = error.response;
            // dispatch(getBulkAssets(locationInfoId,assetType));
            bulkImportStatus = 'ERROR';
              setUploadSuccessMessage(response.data.message);
              setAssetsImportStatusInfo((prevStateInfo) => {
                return {
                  bulkImportStatus: 'ERROR',
                  bulkImportAssetsCount: 0,
                  bulkAssetsErrorDetails: '',
                  loadingValidationResults: false,
                  loadingMessage: '',
                };
              });
          })
          .then(
            (response) => {
              if (response) {
                if(response.status === 200) {
                    bulkImportStatus = response.data.status;
                  if ('DONE' === bulkImportStatus) {
                    setUploadSuccessMessage(
                      'Assets upload has been completed successfully. Please close this window to go back to assets page.',
                    );
                    retrieveAssets();
                    handleOnClose();
                    // dispatch(setBulkModalState(false));
                  } else if ('INPROGRESS' === bulkImportStatus) {
                    setUploadSuccessMessage(
                      'Your last bulk assets upload is in progress, please come back after 4-6 hours',
                    );
                    setAssetsImportStatusInfo((prevStateInfo) => {
                      return {
                        bulkImportStatus: 'INPROGRESS',
                        bulkImportAssetsCount: 0,
                        bulkAssetsErrorDetails: '',
                        loadingValidationResults: false,
                        loadingMessage: '',
                      };
                    });
                  } else if ('ERROR' === bulkImportStatus) {
                    setUploadSuccessMessage('System Error Occurred!');
                  }
                } else {
                  if(response.status > 500) {
                    bulkImportStatus = 'ERROR';
                    setUploadSuccessMessage('System error Occurred!');
                  } else if(response.status > 400 ){
                    bulkImportStatus = 'ERROR';
                    setUploadSuccessMessage('Please upload valid assets!');
                  }
                }
                // dispatch(getBulkAssets(locationInfoId,assetType));
                dispatch({type: GET_BULKASSET_INFO_SUCCESS, payload: response});
              } 
            },
            (error) => {
              // logger.error('Error: Failed to save assets information, Please try again. ');
              console.log('error2: ', error);
            },
          );
      } catch (error) {
        // logger.error('Error: Not able to reach server, Please try again.' + error);
        console.log('error3: ', error);
      }
    };
 

    return (
        <React.Fragment>
        <Modal
            open={bulkAssetsModalstate}
            hideBackdrop={false}
            disableEscapeKeyDown={true}
            aria-labelledby="bulk-modal"
            aria-describedby="bulk-modal-client"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            disableScrollLock={true}
            className={classes.bulkContainer}
          >
            <div className={spinnerClass.eventDialogDelete}>
            <Fade in={bulkAssetsModalstate}>
              <Box>
                <Fragment>
                  <Grid container  className={classes.homeContainer}>
                    <Grid item xs={12} md={10}>
                      <div className={classes.uploadPaneBox}>
                        <ThemeProvider theme={theme}>
                            <Button style={{ float: 'right' }} color="error" variant="contained" size="medium" onClick={handleOnClose} endIcon={<CloseIcon/>}>Close</Button>
                        </ThemeProvider>
                        
                        <Grid item xs={12}  className={classes.formInputs}>
                          <Typography variant="h4"  className={classes.loginTitle} >
                            {isLeaseAssets? `Upload Bulk Lease Asset Information` : `Upload Bulk Asset Information`}  </Typography>
                          
                            <Grid>
                        {assetsImportStatusInfo.bulkImportStatus === 'ERROR' && <FormHelperText error className={classes.customErrorMessage}>{uploadSuccessMessage}</FormHelperText>}
                        {assetsImportStatusInfo.bulkImportStatus !== 'ERROR' &&  <div className={classes.greenMessage}>{uploadSuccessMessage}</div>}
                        {addressValidationStatus &&  <div  style={{position: 'relative'}} className={classes.greenMessage}>{addressValidationMessage} <LinearProgress color='primary' /></div> }
                        </Grid>
                          <Divider style={{ margin: '5px 0 15px 0', color:'#12395b'}}/>
                        </Grid>
                        {assetsImportStatusInfo.bulkImportStatus !== 'INPROGRESS' &&
                        <React.Fragment>
                        <div>
                          Please download the assets template from{' '}
                          <a href='#' onClick={downloadTemplateFile} disabled={assetsImportStatusInfo.isGetFailed}>
                              {' '}
                              here
                            </a>{' '}
                                and utilize import functionality.
                        </div>
                        <form noValidate onSubmit={handleSubmit}>
                          <Grid container>
                            <Grid item xs={12} className={classes.formInputs}>
                              <TextField 
                                  required 
                                  id="bulkAssetsFile" 
                                  variant="standard" 
                                  className={classes.fields} 
                                  type="file"
                                  helperText={`.xlsx, application/msexcel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`} 
                                  onChange={(e) => {
                                    readInputFile(e);
                                  }}
                              />
                              
                            </Grid>
                          </Grid>   
                          <ThemeProvider theme={standardButtons}>
                              <Stack spacing={2} style={{ width:'100%' }} >
                                  <Button color="primary"  variant="contained" size="medium" onClick={() => { handleSubmit(); }} disabled={!assetsImportStatusInfo.loadingValidationResults || addressValidationStatus}> Upload Button</Button>
                              </Stack>
                                {/* <Button style={{marginTop: '30px'}} size="medium" color="primary" disabled={!assetsImportStatusInfo.loadingValidationResults} 
                                            onClick={(e)=> {handleSubmit();}} >
                                  Upload button
                                </Button> */}
                              </ThemeProvider>
                        </form>
                        </React.Fragment>}
                      </div>
                    </Grid>
                  </Grid>
                </Fragment>
              </Box>
            </Fade>
          </div>
        </Modal>
      </React.Fragment>
    );
}

