import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Box,
  Typography,
  Divider,
  CircularProgress,
  Icon,
  SvgIcon,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { PRIMARY_COLOR } from "../../../constants/colorScheme";
import { getSuppliesInfoRelatedToYearAndLocation } from "../../../redux/actions/SuppliesInfoActions";
import {
  getRenditionData,
  postRenditionAction,
} from "../../../redux/actions/RenditionAction";
import { setAppServiceInfo } from "../../../redux/actions/AppServiceInfoAction";

import { appServiceData as getAppServiceData } from "../selectors/AppServiceInfoSelector";
import WarningIcon from '@mui/icons-material/Warning';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    width: "90vw",
    [theme.breakpoints.down("sm")]: {
      width: "45vw",
      left: "20%",
    },
    "& .MuiInputLabel-root": {
      color: PRIMARY_COLOR,
      fontWeight: "bold",
    },
  },
}));

export default function Rendition({ nextButton }) {
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [generateRendition, setGenerateRendition] = useState(false);

  const appServiceData = useSelector(getAppServiceData);

  const onSubmit = (data) => {
    dispatch(postRenditionAction(true));
    setGenerateRendition(true);
  };

  useEffect(() => {
    dispatch(getRenditionData());
    dispatch(setAppServiceInfo(0, null, false, false));
  }, [dispatch]);

  //TODO - remove the additional availability call to service and remove it dependents

  useEffect(() => {
    dispatch(getSuppliesInfoRelatedToYearAndLocation());
  }, [dispatch]);

  return (
    <Box
      style={{
        position: "relative",
        left: "2%",
        bottom: "15%",
        textAlign: "justify",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12} sx={{ m: 1 }}>
            <h1 style={{ textAlign: "center" }}>              Rendition Information              <Divider />            </h1>
          </Grid>
          <Grid item xs={12}>
            {!generateRendition ? (
              <Typography className={classes.fieldContainer} style={{ paddingTop: "5%", paddingBottom: "5%", textAlign: "center", fontSize:'1.3rem'}}>
                Continue to generate the rendition document!!!
              </Typography>
            ) : appServiceData.isError === false ? (
              <>
                <Typography
                  className={classes.fieldContainer}
                  style={{
                    paddingTop: "5%",
                    paddingBottom: "5%",
                    textAlign: "center",
                  }}
                >
                  <CircularProgress />
                </Typography>
                <Typography style={{ textAlign: "center",fontSize:'1.3rem' }}>
                  Your document generation is in progress!!!
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  className={classes.fieldContainer}
                  style={{
                    paddingTop: "5%",
                    paddingBottom: "5%",
                    textAlign: "center",
                  }}
                >
                    <Typography><SvgIcon component={WarningIcon} color="warning" fontSize="large" style={{fontSize:100}}/>   </Typography>
                    <Typography style={{fontSize:"1.3rem"}}>         Plaese Review all the Section data once and Try Again!! </Typography></Typography>
              </>
            )}
          </Grid>

          <input type="submit" ref={nextButton} style={{ display: "none" }} />
        </Grid>
      </form>
    </Box>
  );
}
