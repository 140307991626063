import {
    FETCH_LEASED_CITY_STATE, FETCH_LEASED_CITY_STATE_FAILURE, FETCH_LEASED_CITY_STATE_SUCCESS,
    FETCH_LEASED_ASSETS_VALUES,
    GET_UPDATE_LEASED_ASSETINFO_SUCCESS,GET_UPDATE_LEASED_ASSETINFO_FAILURE,GET_UPDATE_LEASED_ASSETINFO,
    SET_LEASED_ASSETS, SET_LEASED_ASSETS_FAILURE, SET_LEASED_ASSETS_SUCCESS, RESET, SET_PARTIAL_LEASEDASSET_INFO_SUCCESS, SET_LEASED_ASSET_ADDRESSINFO, SET_LEASED_ASSETS_MODAL_STATE
} from "../actions/LeasedAsestAction";


const defaultState = {
    leasedAssetsData:{
        leasedAssets:[],
        totalItems:0
    },
    leasedAssetsInfo: {
        leasedAssetDescription:'',
        leasedNum:'',
        leasingCompName:'',
        leaseStartDate:'',
        leaseEndDate:'',
        leaseCompMailAddr:'',
        zip:'',
        city:'', 
        state: '',
        loadingStateCity: false,
        id:0
    },
    isSubmitting: false,
    isUpdating: false, 
    isPartialLeasedAsset: false,
    addressSelected: false,
    modalState: false
}

const LeasedAssetReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_LEASED_ASSETS:
            return {...state, isSubmitting: true }
        case SET_LEASED_ASSETS_SUCCESS:
            return {...state, leasedAssetsInfo: action.payload, isSubmitting: false, addressSelected: false }
        case SET_LEASED_ASSETS_FAILURE: 
            return {state: defaultState}
        case FETCH_LEASED_CITY_STATE: 
            return { ...state, loadingStateCity: true}
        case FETCH_LEASED_CITY_STATE_SUCCESS: 
            return { ...state, leasedAssetsInfo: { ...state.leasedAssetsInfo,state: action.payload.State, city: action.payload.City}, loadingStateCity: false, addressSelected: false, isPartialLeasedAsset: false}
        case FETCH_LEASED_CITY_STATE_FAILURE: 
            return { ...state, loadingStateCity: false }
        case FETCH_LEASED_ASSETS_VALUES: 
            return {...state, leasedAssetsData: action.payload}
        case GET_UPDATE_LEASED_ASSETINFO:
            return { ...state, isUpdating: true }
        case GET_UPDATE_LEASED_ASSETINFO_SUCCESS:
            return { ...state, leasedAssetsInfo: action.payload, id: action.payload.id }
        case GET_UPDATE_LEASED_ASSETINFO_FAILURE:
        case RESET: 
            return {...state, leasedAssetsInfo: defaultState.leasedAssetsInfo,leasedAssetsData:defaultState.leasedAssetsData, id: '', isUpdating: false, isPartialLeasedAsset: false, addressSelected: false, modalState: false}
        case SET_PARTIAL_LEASEDASSET_INFO_SUCCESS:
            return { ...state, leasedAssetsInfo: action.payload, isPartialLeasedAsset: true };
        case SET_LEASED_ASSETS_MODAL_STATE:
            return {...state, modalState: action.payload}
        case SET_LEASED_ASSET_ADDRESSINFO:
            {
                return {
                    ...state, 
                    leasedAssetsInfo: {
                        ...state.leasedAssetsInfo,
                        leaseCompMailAddr: action.payload.leaseCompMailAddr,
                        city:action.payload.city,
                        state:action.payload.state
                    },
                    isPartialLeasedAsset: false,
                    addressSelected: true
                }
            }
        default:
            return state;
    }
}

export default LeasedAssetReducer;