import { saveTaxInformation , updateTaxInformation, getTaxInformationPlan, deleteTaxInformationPlan} from '../services/taxService';
import { getTaxFilingPlans,setNewPlanFalse } from './taxFilingPlanAction';
import { setAppServiceInfo } from './AppServiceInfoAction';
import { NotificationManager } from 'react-notifications';

export const GET_TAX_INFO = "GET_TAX_INFO";
export const POST_TAX_INFO_ERROR = "POST_TAX_INFO_ERROR";
export const PUT_TAX_INFO_ERROR = "PUT_TAX_INFO_ERROR";
export const PUT_TAX_INFO_SUCCESS = "PUT_TAX_INFO_SUCCESS";



export const postTaxInformation = (data, directClientInfoId,agentProfileId,userType,isPageSubmitted,lastVisitedPage) => {
    let userRequestBody = null;
    let agentRequestBody = null;
    if(userType === 'CLIENT' || userType === 'BUSINESS-OWNER'){
        userRequestBody = {
            taxYear: data.taxYear,
            county:data.county,
            propertyId:data.propertyId,
            businessName: data.businessName,
            lastVisitedPage: lastVisitedPage,
            directClientInfoId: directClientInfoId,
        }
        // alert(data.county);
        // alert(userRequestBody);
    } else {
        agentRequestBody = {
            taxYear: data.taxYear,
            county:data.county,
            propertyId:data.propertyId,
            businessName: data.businessName,
            lastVisitedPage: lastVisitedPage,
            agentId: agentProfileId,
        }
    }
    return dispatch => {
       const requestBody = (userType === 'CLIENT' || userType === 'BUSINESS-OWNER')?userRequestBody:agentRequestBody;
        saveTaxInformation(requestBody).then((response) => {
            if (response && response.status === 200) {
                let data = response.data;
               
                dispatch({type: GET_TAX_INFO, payload: data});
                dispatch(setAppServiceInfo(response.status,"Tax Info successfully submitted",false,isPageSubmitted));

            }
            else {
                dispatch({type: POST_TAX_INFO_ERROR, payload: data});
                dispatch({type: GET_TAX_INFO, payload: data});
                dispatch(setAppServiceInfo(response.status,"Tax Info error",true,isPageSubmitted));
            }
            
            
        }).catch((err) => {
            NotificationManager.error(err.message,"Tax Info Error",5000);
            dispatch({type: POST_TAX_INFO_ERROR, payload: data});
            dispatch({type: GET_TAX_INFO, payload: data})
            dispatch(setAppServiceInfo(err.response.status,"Tax Info error",true,isPageSubmitted));
        })
    }
}
export const putTaxInfo = (id,businessInfoId,locationInfoId,lastVisitedPage,isPageSubmitted,) => {

    // alert("Taxid from putTaxInfo: " +id);

    // alert("conty:"+county+"propertyid:"+propertyId);
    return dispatch => {
        updateTaxInformation(id,{businessInfoId,locationInfoId,lastVisitedPage}).then((response) => {
            let data = response && response.data;
             if (response && response.status === 200) {
                
                dispatch({type: PUT_TAX_INFO_SUCCESS, payload: {id,lastVisitedPage,} });
                if(isPageSubmitted) {
                    dispatch(setAppServiceInfo(response.status,"Tax Info successfully submitted",false,true));
                }
             }
             else {
                 dispatch({type: PUT_TAX_INFO_ERROR, payload: data});
                 if(isPageSubmitted) {
                     dispatch(setAppServiceInfo(response.status,"Tax Info error",true,true));
                 }
             }
             
         }).catch((err) => {
            NotificationManager.error(err.message,"Tax Info Error",5000);
             dispatch({type: PUT_TAX_INFO_ERROR, payload: err});
             dispatch(setAppServiceInfo(err.response.status,"Tax Info error",true,isPageSubmitted));
         })
     }
}

export const getTaxInfo = (id) => {

    return dispatch => {
        getTaxInformationPlan(id).then((response) => {
            let data = response && response.data;
             if (response && response.status === 200) {
                
                dispatch({type: GET_TAX_INFO, payload: data})
                // alert("gettaxdata:",data)
             }
             else {
                 dispatch({type: GET_TAX_INFO, payload: data});
             }
             
         }).catch((err) => {
            //  dispatch({type: GET, payload: err});
            //  dispatch({type: GET_TAX_INFO, payload: data})
         })
     }
}

export const deleteTaxInfo = (id,directClientInfoId,agentProfile) => {

    return dispatch => {
        deleteTaxInformationPlan(id).then((response) => {
            let data = response && response.data;
             if (response && response.status === 200) {
               
                if(directClientInfoId) {
                    dispatch(getTaxFilingPlans(directClientInfoId, null,'history'));
                  } else if(agentProfile && agentProfile.id){
                    dispatch(getTaxFilingPlans(null,agentProfile.id,'history'));
                  }
                    dispatch(setNewPlanFalse());
                
                dispatch({type: GET_TAX_INFO, payload: data})
             }
             else {
                 dispatch({type: GET_TAX_INFO, payload: data});
             }
             
         }).catch((err) => {
            NotificationManager.error(err.message,"Tax Plan Deleted Successfully",5000);
            //  dispatch({type: GET, payload: err});
            //  dispatch({type: GET_TAX_INFO, payload: data})
         })
     }
}
