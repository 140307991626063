import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import HeaderMenu from './layout/HeaderMenu';
import Footer from './layout/Footer';
import HomeView from './components/home/HomeView';
import ContactUsForm from './components/contactUs/ContactForm';
import AppClientHome from './components/appClient/AppClientHome';
import RegisterView from './components/home/register/RegisterView';
import ForgotPwdView from './components/home/forgotPwd/ForgotPwdView';
import VerifyView from './components/home/VerifyView';
import { getIsAuthorized } from './components/home/login/LoginSelectors';
import ProfileSelection from './components/profile/ProfileSelection';
import FaqApp from './components/support/faqView';
import DeadlineView from './components/support/deadlineView';
import MainScreen from './components/appClient/MainScreen';
import './App.css';
import 'react-notifications/lib/notifications.css';
import HistoryScreen from './components/appClient/HistoryScreen';

const App = () => {
  const appTheme = createTheme({
    typography: {
        fontFamily: ["Abel", "sans-serif"].join(','),
      },
    });

  appTheme.typography.h4 = {
      fontFamily: ["Abel", "sans-serif"].join(','),
      fontSize: '2rem',
      fontWeight: '400',
      [appTheme.breakpoints.between('sm', 'lg')]: {
        fontSize: '1.875rem',
      },
      [appTheme.breakpoints.between('xs', 'md')]: {
        fontSize: '1.5rem',
      },
  };

  appTheme.typography.h5 = {
    fontFamily: ["Abel", "sans-serif"].join(','),
    fontSize: '1.5rem',
    fontWeight: '400',
    [appTheme.breakpoints.between('sm', 'lg')]: {
      fontSize: '1.5rem',
    },
    [appTheme.breakpoints.between('xs', 'md')]: {
      fontSize: '1.5rem',
    },
  };

  appTheme.typography.h6 = {
    fontFamily: ["Abel", "sans-serif"].join(','),
    fontSize: '1.1rem',
    fontWeight: '500',
    [appTheme.breakpoints.down('md')]: {
      fontSize: '1rem',
      fontWeight: '500',
    }
  };

  appTheme.typography.subtitle1 = {
    fontFamily: ["Abel", "sans-serif"].join(','),
    fontSize: '1rem',
    fontWeight: '400',
    [appTheme.breakpoints.down('lg')]: {
      fontSize: '0.7rem',
      fontWeight: '400',
    }
  };

  const isAuth = useSelector(getIsAuthorized);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
          <HeaderMenu/>
          <div>
            <Switch>
              <Route path="/" name="Home" exact component={HomeView}/>
              <Route path="/verifyCode" name="Verify" exact component={VerifyView}/>
              <Route path="/forgotPwd" name="ForgotPassword" exact component={ForgotPwdView}/>
              <Route path="/register" name="Register" exact component={RegisterView}/> 
              <Route path="/contactUs" name="ContactUs" exact component={ContactUsForm}/>
              <Route path="/faq" name="Faq" exact component={FaqApp} />
              <Route path='/deadlines' name='Deadline' exact component={DeadlineView}/>
              {isAuth &&
                <AuthRoutes />
              } 
            </Switch>
          </div>
          <Footer />
          <NotificationContainer/>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const AuthRoutes = () => {
  return (
    <div>
      <Switch>
        <Route path="/appClientHome" name="ClientHome" exact component={AppClientHome}/>
        <Route path="/profile" name="Profile" exact component={ProfileSelection}/>
        <Route path='/mainscreen' name='MainScreen' exact component={MainScreen}/>
        <Route path='/historyScreen' name='HistoryScreen' exact component={HistoryScreen}/>
      </Switch>
    </div>
  )
}
export default App;
