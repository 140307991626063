import { NotificationManager } from "react-notifications";
import { getBusinessInfoFromBusinessId } from "../../api/BusinessInfoApi";
import { getLocationInfoFromLocId } from "../../api/LocationInfoApi";
import { getAllAssets, postRendition, getAllLeasedAssets, getAllInventory, getAllSupplies } from "../../api/RenditionApi";
import { setModalState } from "../../components/appClient/AppClientActions";
import { putTaxInfo } from './taxInfoAction';
import { setAppServiceInfo } from './AppServiceInfoAction';
import { getAdditionalBusinessInfo } from "./AdditionalBusinessInfoActions";
import { getRepresentation } from "./RepresentationAction";

const {logger} = require('../../logger')

export const SET_RENDITION_STATUS = 'SET_RENDITION_STATUS';
export const SET_RENDITION_STATUS_SUCCESS = 'SET_RENDITION_STATUS_SUCCESS';
export const SET_RENDITION_STATUS_FAILURE = 'SET_RENDITION_STATUS_FAILURE';

export const GET_RENDITION_LEASEDASSETS_DATA = "GET_RENDITION_LEASEDASSETS_DATA";
export const GET_RENDITION_LEASEDASSETS_DATA_SUCCESS = "GET_RENDITION_LEASEDASSETS_DATA_SUCCESS";
export const GET_RENDITION_LEASEDASSETS_DATA_FAILURE = "GET_RENDITION_LEASEDASSETS_DATA_FAILURE";

export const GET_RENDITION_ASSETS_DATA = 'GET_RENDITION_ASSETS_DATA';
export const GET_RENDITION_ASSETS_DATA_SUCCESS = 'GET_RENDITION_ASSETS_DATA_SUCCESS';
export const GET_RENDITION_ASSETS_DATA_FAILURE = 'GET_RENDITION_ASSETS_DATA_FAILURE';

export const GET_RENDITION_SUPPLIES_DATA = 'GET_RENDITION_SUPPLIES_DATA';
export const GET_RENDITION_SUPPLIES_DATA_SUCCESS = 'GET_RENDITION_SUPPLIES_DATA_SUCCESS';
export const GET_RENDITION_SUPPLIES_DATA_FAILURE = 'GET_RENDITION_SUPPLIES_DATA_FAILURE';

export const GET_RENDITION_INVENTORY_DATA = 'GET_RENDITION_INVENTORY_DATA';
export const GET_RENDITION_INVENTORY_DATA_SUCCESS = 'GET_RENDITION_INVENTORY_DATA_SUCCESS';
export const GET_RENDITION_INVENTORY_DATA_FAILURE = 'GET_RENDITION_INVENTORY_DATA_FAILURE';

export const GET_RENDITION_LOC_DATA_SUCCESS = 'GET_RENDITION_LOC_DATA_SUCCESS';
export const GET_RENDITION_BIZ_DATA_SUCCESS = 'GET_RENDITION_BIZ_DATA_SUCCESS';

export const GET_RENDITION_ADDT_BUSINESS_DATA  = 'GET_RENDITION_ADDT_BUSINESS_DATA';
export const GET_RENDITION_ADDT_BUSINESS_DATA_SUCCESS = 'GET_RENDITION_ADDT_BUSINESS_DATA_SUCCESS';
export const GET_RENDITION_ADDT_BUSINESS_DATA_FAILURE = 'GET_RENDITION_ADDT_BUSINESS_DATA_FAILURE';
export const RESET_REDITION_FORM='RESET_REDITION_FORM'; //ADDED TO CALL RESET REDITION FORM
export const postRenditionAction = (isPageSubmitted) => {
    return (dispatch, getState) => { 
        let assets = getState().rendition.assets;
        let leasedAssets = getState().rendition.leasedAssets;
        let supplies = getState().rendition.supplies;
        let inventory = getState().rendition.inventory;
        let business = getState().rendition.business;
        let location = getState().rendition.location;
        let isClientAuthenticated = getState().appCtx.isClientAuthenticated;
        let isAuthorized = getState().appCtx.isAuthorized;
        let additionalBusiness = getState().rendition.additionalBusiness;
        let representativeInfo = '';
        let userProfileData = '';
        let taxFilingPlanId = getState().taxInfoReducer.taxInfo.id;
        // let businessInfoId = getState().businessInfo.businessInfo.id;
        // let locationInfoId = getState().locationInfo.locationInfo.id;
        let locationInfoId,businessInfoId;
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                businessInfoId = getState().taxFilingPlanInfoReducer.taxFilingPlan.businessInfoId;
                
                //ADDED TO RETRIVE THE BUSINESINFOID
                if(businessInfoId===undefined||businessInfoId===null){
                    businessInfoId = getState().businessInfo.businessInfo.id; 
                }
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(businessInfoId === null || businessInfoId === undefined) {
            businessInfoId = getState().businessInfo.businessInfo.id;
        }

        if (isAuthorized && isClientAuthenticated) {
            userProfileData = getState().userProfile.profile;
            representativeInfo = {
                address: {
                    "line1": userProfileData.addressLine1,
                    "line2": userProfileData.addressLine2,
                    "email": userProfileData.email,
                    "city": userProfileData.city,
                    "state": userProfileData.state,
                    "zipcode": userProfileData.zip,
                },
                "id": userProfileData.id,
                "clientName": userProfileData.name,
                "phoneNumber":userProfileData.phone,
                "emailId":userProfileData.email,
                "userId": userProfileData.userId
            }
        } else if (isAuthorized) {
            let additionalrep = getState().representation.representation;
            representativeInfo = {...getState().agentProfile.agentprofile, securedPartyConsentInd: additionalrep.securedPartyConsentInd };
        }
        // let countyValue = getState().appClientCtx.countyValues.filter((item) => item.id === location.county)[0];
        // let stateValue = getState().appClientCtx.stateValues.filter((item) => item.id === location.address.state)[0];
        
        // ADDED THIS CODE FOR RETRIVING THE TAXYEAR
        let taxYear=getState().taxFilingPlanInfoReducer.taxFilingPlan.taxYear;
        if(taxYear===undefined|| taxYear===null || taxYear===0){
            taxYear= getState().taxInfoReducer.taxInfo.taxYear //ADDED
        }
        // ADDED THIS CODE FOR RETRIVING THE TAXYEAR END

        let postPayload = {
            values: { assets, leasedAssets, supplies, inventory, location, business, representativeInfo, additionalBusiness },
            stateCode: location.address.state,
            countyCode: location.county,
            // taxYear: getState().taxFilingPlanInfoReducer.taxFilingPlan.taxYear,
            taxYear: taxYear,
            taxFilingPlanId
        }
        postRendition(postPayload).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully posted Rendition`);
                NotificationManager.success("Rendition Document Generated Successfully");
                // dispatch(putTaxInfo(taxFilingPlanId,businessInfoId,locationInfoId,"RenditionInformation"));
                dispatch(putTaxInfo(taxFilingPlanId,businessInfoId,locationInfoId,"Draft Rendition",true));
                let file = new Blob([response.data], {
                    type: 'application/pdf'
                });
                let fileURL = window.URL.createObjectURL(file);
                window.open(fileURL);
                dispatch(setModalState(false));
                dispatch(setAppServiceInfo(response.status,"Rendition Info successfully submitted",false,isPageSubmitted));
            } else {
                NotificationManager.error("Couldn't generate rendition");
                dispatch(setModalState(false));
                dispatch(setAppServiceInfo(response.status,"Rendition Info error",true,isPageSubmitted));
            }
        }).catch((err) => {
            logger.error(`Failed to post Rendition,error:${err}`);
            NotificationManager.error("Couldn't generate rendition");
            dispatch(setModalState(false));
            dispatch(setAppServiceInfo(err.response.status,"Rendition Info error",true,isPageSubmitted));
        });
    }
}

export const getRenditionData = () => {
    return (dispatch, getState) => {
        dispatch(loadAssetData());
        dispatch(loadLeasedAssetsData());
        dispatch(loadInventoryData());
        dispatch(loadSuppliesData());
        dispatch(loadBusinessData());
        dispatch(loadLocationData());
        dispatch(loadAdditionalBusinessData());
        dispatch(loadRepresentationData());
    }
}

export const loadRepresentationData = () => {
    return (dispatch, getState) => { 
        let locationInfoId,taxFilingPlanId;
        let taxinfo=getState().taxInfoReducer; //ADDED TO GET THE TAXID
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
                
                //ADDED TO RETRIVE THE TAXFILINGPLANID
                if(taxFilingPlanId===undefined || taxFilingPlanId===null || taxFilingPlanId===0){
                    taxFilingPlanId = taxinfo.taxInfo.id; 
                }
                //ADDED TO RETRIVE THE TAXFILINGPLANID END
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null || taxFilingPlanId === undefined){
            taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
            //ADDED TO RETRIVE THE TAXFILINGPLANID
                if(taxFilingPlanId===undefined || taxFilingPlanId===null || taxFilingPlanId===0){
                    taxFilingPlanId = taxinfo.taxInfo.id; //ADDED TAXINFO.TAXINFO.ID FROM TAXINFOREDUCER
                }
                //ADDED TO RETRIVE THE TAXFILINGPLANID END
        }

        dispatch(getRepresentation(taxFilingPlanId));
    }
}

export const loadAssetData = () => { 
    return (dispatch, getState) => {
        let locationInfoId,taxFilingPlanId;
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        let taxinfo=getState().taxInfoReducer; //ADDED TO GET THE TAXID
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
                  //ADDED TO RETRIVE THE TAXFILINGPLANID
                  if(taxFilingPlanId===undefined || taxFilingPlanId===null || taxFilingPlanId===0){
                    taxFilingPlanId = taxinfo.taxInfo.id; //ADDED TAXINFO.TAXINFO.ID FROM TAXINFOREDUCER
                }
                //ADDED TO RETRIVE THE TAXFILINGPLANID END
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null || taxFilingPlanId === undefined){
            taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
             //ADDED TO RETRIVE THE TAXFILINGPLANID
             if(taxFilingPlanId===undefined || taxFilingPlanId===null || taxFilingPlanId===0){
                taxFilingPlanId = taxinfo.taxInfo.id; //ADDED TAXINFO.TAXINFO.ID FROM TAXINFOREDUCER
            }
            //ADDED TO RETRIVE THE TAXFILINGPLANID END
        }
        
        dispatch({ type: GET_RENDITION_ASSETS_DATA })
        getAllAssets(locationInfoId, taxFilingPlanId).then((response) => {
            if (response.status === 200) {
                logger.info(`successfully loaded AssetData in RenditionAction.js`);
                NotificationManager.success("Successfully loaded Assets Data for Rendition!", "", 5000);
                dispatch({type: GET_RENDITION_ASSETS_DATA_SUCCESS, payload: response.data})
            }
        }).catch((err) => {
            logger.error(`Failed to load AssetData,error:${err} in RenditionAction.js`);
            NotificationManager.error("Failed to load Assets Data for Rendition, Please Check once!", "", 0);
            dispatch({type: GET_RENDITION_ASSETS_DATA_FAILURE, payload: err})
        })
    }
}
export const loadLeasedAssetsData = () => {
    return (dispatch, getState) => {

        let locationInfoId,taxFilingPlanId;
        
        let taxinfo=getState().taxInfoReducer; //ADDED TO GET THE TAXID
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
                 //ADDED TO RETRIVE THE TAXFILINGPLANID
                 if(taxFilingPlanId===undefined || taxFilingPlanId===null || taxFilingPlanId===0){
                    taxFilingPlanId = taxinfo.taxInfo.id; //ADDED TAXINFO.TAXINFO.ID FROM TAXINFOREDUCER
                }
                //ADDED TO RETRIVE THE TAXFILINGPLANID END
        }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null || taxFilingPlanId === undefined){
            taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
             //ADDED TO RETRIVE THE TAXFILINGPLANID
             if(taxFilingPlanId===undefined || taxFilingPlanId===null || taxFilingPlanId===0){
                taxFilingPlanId = taxinfo.taxInfo.id; //ADDED TAXINFO.TAXINFO.ID FROM TAXINFOREDUCER
            }
            //ADDED TO RETRIVE THE TAXFILINGPLANID END
        }
        
        dispatch({ type: GET_RENDITION_LEASEDASSETS_DATA })
        getAllLeasedAssets(locationInfoId, taxFilingPlanId).then((response) => {
            if (response.status === 200) {
                NotificationManager.success("Successfully loaded leased assets data for Rendition!",'',5000);
                logger.info(`Successfully retrieved leased Asset data in RenditionAction.js`);
                dispatch({type: GET_RENDITION_LEASEDASSETS_DATA_SUCCESS, payload: response.data})
            }
        }).catch((err) => {
            logger.error(`Failed to load LeasedAssets data,error:${err} in RenditionAction.js`);
            NotificationManager.error("Failed to load leased assets data for Rendition, Please Check once!",'',0);
            dispatch({type: GET_RENDITION_LEASEDASSETS_DATA_FAILURE, payload: err})
        })
    }
}

export const loadSuppliesData = () => {
    return (dispatch, getState) => { 
        let locationInfoId,taxFilingPlanId;
        let taxinfo=getState().taxInfoReducer; //ADDED TO GET THE TAXID
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
                 //ADDED TO RETRIVE THE TAXFILINGPLANID
                 if(taxFilingPlanId===undefined || taxFilingPlanId===null || taxFilingPlanId===0){
                    taxFilingPlanId = taxinfo.taxInfo.id; //ADDED TAXINFO.TAXINFO.ID FROM TAXINFOREDUCER
                }
                //ADDED TO RETRIVE THE TAXFILINGPLANID END
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null || taxFilingPlanId === undefined){
            taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
              //ADDED TO RETRIVE THE TAXFILINGPLANID
              if(taxFilingPlanId===undefined || taxFilingPlanId===null || taxFilingPlanId===0){
                taxFilingPlanId = taxinfo.taxInfo.id; //ADDED TAXINFO.TAXINFO.ID FROM TAXINFOREDUCER
            }
            //ADDED TO RETRIVE THE TAXFILINGPLANID END
        }
        //ADDED TO GET TAX YEAR 
        let taxYear = taxFilingPlanData.taxFilingPlan.taxYear;
        if(taxYear===null || taxYear===undefined||taxYear===0){
            taxYear = taxinfo.taxInfo.taxYear; 
        }
        //ADDED TO GET TAX YEAR END

        dispatch({type: GET_RENDITION_SUPPLIES_DATA})
        getAllSupplies(locationInfoId, taxFilingPlanId, taxYear).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully retrieved Supplies data in RenditionAction.js`);
                NotificationManager.success("Successfully loaded Supplies data for Rendition!",'',5000);
                dispatch({type: GET_RENDITION_SUPPLIES_DATA_SUCCESS, payload: response.data})
            }
        }).catch((err) => {
            logger.error(`Failed to load Supplies data,error:${err} in RenditionAction.js`);
            NotificationManager.error("Failed to load Supplies data for Rendition, Please Check once!",'',0);
            dispatch({type: GET_RENDITION_SUPPLIES_DATA_FAILURE, payload: err})
        })
    }
}

export const loadInventoryData = () => {
    return (dispatch, getState) => {
        let locationInfoId,taxFilingPlanId;
        let taxinfo=getState().taxInfoReducer; //ADDED TO GET THE TAXID
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
                 //ADDED TO RETRIVE THE TAXFILINGPLANID
              if(taxFilingPlanId===undefined || taxFilingPlanId===null || taxFilingPlanId===0){
                taxFilingPlanId = taxinfo.taxInfo.id; //ADDED TAXINFO.TAXINFO.ID FROM TAXINFOREDUCER
            }
            //ADDED TO RETRIVE THE TAXFILINGPLANID END
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null || taxFilingPlanId === undefined){
            taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
           //ADDED TO RETRIVE THE TAXFILINGPLANID
           if(taxFilingPlanId===undefined || taxFilingPlanId===null || taxFilingPlanId===0){
            taxFilingPlanId = taxinfo.taxInfo.id; //ADDED TAXINFO.TAXINFO.ID FROM TAXINFOREDUCER
        }
        //ADDED TO RETRIVE THE TAXFILINGPLANID END
        }

        //ADDED TO GET TAX YEAR 
        let taxYear = taxFilingPlanData.taxFilingPlan.taxYear;
        if(taxYear===null || taxYear===undefined||taxYear===0){
            taxYear = taxinfo.taxInfo.taxYear; 
        }
        //ADDED TO GET TAX YEAR END
        dispatch({type: GET_RENDITION_INVENTORY_DATA})
        getAllInventory(locationInfoId, taxFilingPlanId, taxYear).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully retrieved Inventory Data for the locationInfoId:${locationInfoId}`);
                NotificationManager.success("Suceesfully Loaded Inventory Data for Rendition!",'',5000);
                console.log(response.data);
                dispatch({type: GET_RENDITION_INVENTORY_DATA_SUCCESS, payload: response.data})
            }
        }).catch((err) => {
            logger.error(`Failed to load Inventory data,error:${err} in RenditionAction.js`);
            NotificationManager.error("Failed to load Inventory Data for Rendition, Please Check once!",'',0);
            dispatch({type: GET_RENDITION_INVENTORY_DATA_FAILURE, payload: err})
        });
    }
}

export const loadBusinessData = () => {
    return (dispatch, getState) => {
        let isClient = getState().appCtx.isClientAuthenticated;
         let currBusiness = getState().taxFilingPlanInfoReducer.taxFilingPlan.businessInfoId;
        // ADDED TO GET THE BUSINESS ID
        if(currBusiness===null || currBusiness===0 || currBusiness===undefined){
            currBusiness = getState().businessInfo.businessInfo.id;
        }
        // ADDED TO GET THE BUSINESS ID END

        // alert("currBusiness: "+currBusiness);
        getBusinessInfoFromBusinessId(isClient, currBusiness).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully retrieved BusinessInfo using current business:${currBusiness}`);
                NotificationManager.success("Successfully Loaded BusinessInfo Data For Rendition",'',5000);
                dispatch({type: GET_RENDITION_BIZ_DATA_SUCCESS, payload: response.data})
            }
        }).catch((err) => {
            logger.error(`Failed to load Business data using current business:${currBusiness},error:${err} in RenditionAction.js`);
            NotificationManager.error("Failed to load BusinessInfo Data for Rendition, Please Check Once!",'',0);
        });
    };
}

export const loadLocationData = () => {
    return (dispatch, getState) => {
        let currLocation,taxFilingPlanId;
        let taxinfo=getState().taxInfoReducer; //ADDED TO GET THE TAXID
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                currLocation = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
                if(taxFilingPlanId===undefined || taxFilingPlanId===null || taxFilingPlanId===0){
                    taxFilingPlanId = taxinfo.taxInfo.id; //ADDED TAXINFO.TAXINFO.ID FROM TAXINFOREDUCER
                }
            }
        }
        if(currLocation == null || currLocation === undefined){
            currLocation = locationInfo != null ? locationInfo.id:'';
        }
        
        getLocationInfoFromLocId(currLocation).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully retrieved location data for the current location:${currLocation}`);
                NotificationManager.success("Successfully loaded Location Data for Rendition!",'',5000);
                dispatch({type: GET_RENDITION_LOC_DATA_SUCCESS, payload: response.data});
            }
        }).catch((err) => {
            logger.error(`Failed to load Location data for the current location:${currLocation},error:${err} in RenditionAction.js`);
            NotificationManager.error("Failed to load Location Data for Renditioin, Please Check once!",'',0);
        });
    };
}

export const loadAdditionalBusinessData = () => {
    return (dispatch, getState) => {
        dispatch({ type: GET_RENDITION_ADDT_BUSINESS_DATA });
        let taxFilingPlanId = getState().taxFilingPlanInfoReducer.taxFilingPlan.id;
        //ADDED TO RETRIVE TAXFILINGPLAN ID
        if(taxFilingPlanId===undefined || taxFilingPlanId===null || taxFilingPlanId===0){
            taxFilingPlanId = getState().taxInfoReducer.taxInfo.id; //ADDED
           }
           
        // alert("ADDITIONALBUSINESSDATA: "+taxFilingPlanId);
           //ADDED TO RETRIVE TAXFILINGPLAN ID END
        getAdditionalBusinessInfo(taxFilingPlanId).then((response) => {
            let payload = response.data;
            if(payload) {
            let getParsedPayload = {
                businessType: payload.businessType,
                sicCode: payload.sicCode,
                businessDescription: payload.businessDescription,
                squareFootOccupied: payload.occupiedArea,
                businessSoldDate: payload.businessSoldDate,
                businessStartDateAtLoc: payload.businessStartDate,
                businessMovedDate: payload.businessMovedDate,
                businessClosedDate: payload.businessClosedDate,
                newOwner: payload.newOwnerName,
                salesTaxPermitNumber: payload.salesTaxPermitNr,
                newAddressLine1: payload.newAddress? payload.newAddress.line1: '',
                newAddressLine2: payload.newAddress? payload.newAddress.line2:'', 
                state: payload.newAddress?payload.newAddress.state:'', 
                city: payload.newAddress?payload.newAddress.city:'',
                zip: payload.newAddress?payload.newAddress.zipcode:'',
                assetsConfirmationInd: payload.assetsConfirmationInd,  
                id: payload.id
            }
            NotificationManager.success("Successfully loaded Additional Business info for Rendition",'',5000);
            dispatch({ type: GET_RENDITION_ADDT_BUSINESS_DATA_SUCCESS, payload: getParsedPayload });
        } else{
            NotificationManager.error("Failed to load  Additional Business info for Rendition.",'',0);
            dispatch({ type: GET_RENDITION_ADDT_BUSINESS_DATA_FAILURE, payload: {} });

        }
        })
    }
}

//ADDED TO RESET ADDITIONAL BUSINESS INFO
export const resetReditionForm=()=>{
    return dispatch => {
        dispatch({ type: RESET_REDITION_FORM });
    }
}
