import { postInventoryInfo, getInventoryInfo, updateInventory} from '../../api/InventoryApi';
import { setModalState } from '../../components/appClient/AppClientActions';
import { putTaxInfo } from './taxInfoAction';
import { setAppServiceInfo } from './AppServiceInfoAction';
import { NotificationManager } from 'react-notifications';

const { logger } = require('../../logger');

export const SET_INVENTORY_INFO = "SET_INVENTORY_INFO";
export const SET_INVENTORY_INFO_SUCCESS = "SET_INVENTORY_INFO_SUCCESS";
export const SET_INVENTORY_INFO_ERROR = "SET_INVENTORY_INFO_ERROR";

export const FETCH_INVENTORY_INFO = "FETCH_INVENTORY_INFO";
export const FETCH_INVENTORY_INFO_SUCCESS = "FETCH_INVENTORY_INFO_SUCCESS";
export const FETCH_INVENTORY_INFO_ERROR = "FETCH_INVENTORY_INFO_ERROR";

export const UPDATE_INVENTORY_INFO = "UPDATE_INVENTORY_INFO";
export const UPDATE_INVENTORY_INFO_SUCCESS = "UPDATE_INVENTORY_INFO_SUCCESS";
export const UPDATE_INVENTORY_INFO_ERROR = "UPDATE_INVENTORY_INFO_ERROR";

export const UPDATE_SECOND_FORM_SECTION_STATUS = "UPDATE_SECOND_FORM_SECTION_STATUS";
export const UPDATE_THIRD_FORM_SECTION_STATUS = "UPDATE_THIRD_FORM_SECTION_STATUS";
export const UPDATE_INV_RESALE_FLAG = "UPDATE_INV_RESALE_FLAG";

export const RESET_INVENTORY="RESET_INVENTORY";

export const submitInventoryInfo = (payload,isPageSubmitted,lastVisitedPage) => {
    return (dispatch, getState) => {
        dispatch({ type: SET_INVENTORY_INFO });
    
        let locationInfoId, taxFilingPlanId, taxYear,businessInfoId;
        if(getState().taxFilingPlanInfoReducer.isPlanSelected) {
            if(getState().taxFilingPlanInfoReducer.taxFilingPlan) {
                locationInfoId = getState().taxFilingPlanInfoReducer.taxFilingPlan.locationInfoId;
              taxFilingPlanId = getState().taxFilingPlanInfoReducer.taxFilingPlan.id;
              taxYear = getState().taxFilingPlanInfoReducer.taxFilingPlan.taxYear;
              businessInfoId = getState().taxFilingPlanInfoReducer.taxFilingPlan.businessInfoId;
            }
        }
        if(locationInfoId === null || locationInfoId === undefined) {
            locationInfoId = getState().locationInfo.locationInfo.id;
        }
        if(taxFilingPlanId === null || taxFilingPlanId === undefined) {
            taxFilingPlanId = getState().taxInfoReducer.taxInfo.id;
        }
        if(taxYear === null || taxYear === undefined) {
            taxYear =  getState().taxInfoReducer.taxInfo.taxYear;
        }
        if(businessInfoId === null || businessInfoId === undefined) {
            businessInfoId = getState().businessInfo.businessInfo.id;
        }
        
        const postPayload = {
            ...payload, 
            locationId: locationInfoId, 
            taxYear: taxYear,
            taxFilingPlanId:getState().taxInfoReducer.taxInfo.id
        }
        postInventoryInfo(postPayload).then((response) => {
            if (response.status === 201) {
                logger.info(`Successfully posted Inventory information`);
                dispatch(putTaxInfo(taxFilingPlanId,businessInfoId,locationInfoId,lastVisitedPage,true));
                dispatch(setModalState(false));
                dispatch({type: SET_INVENTORY_INFO_SUCCESS, payload});
                dispatch(getInventoryInfoByLocIdTaxYearAndTaxFillingId());
                // dispatch(setAppServiceInfo(response.status,"Inventory Info successfully submitted",false,isPageSubmitted));

            }
        }).catch((err) => {
            logger.error(`Failed to submit Inventory Information from InventoryInfoActions, error : -> ${err}`);
            NotificationManager.error(err.message,'Failed to Submit Inventory Information',5000);
            
                // Error Capturing
                const errors = err.response?.data?.errors;
                if (errors && Array.isArray(errors)) {
                    const uniqueErrors = {};
                    errors.forEach(error => {
                        if (!uniqueErrors[error.param]) {
                            uniqueErrors[error.param] = error.msg;
                        }
                    });
                    const errorMessage = Object.entries(uniqueErrors).map(([param, msg]) => `- ${msg}`); 
                    NotificationManager.error('Please Complete the OwnerShip Step',"", 5000);
                } else {
                    NotificationManager.error(err.message,'An unexpected error occurred. Please try again.', 5000);
                }
            dispatch(setAppServiceInfo(err.response.status,"Inventory Info error",true,isPageSubmitted));
        })


    }
}

export const getInventoryInfoByLocIdTaxYearAndTaxFillingId = () => {
    return (dispatch, getState) => {
        dispatch({type: FETCH_INVENTORY_INFO});
        let locationId, taxFilingPlanId, taxYear;
        if(getState().taxFilingPlanInfoReducer.isPlanSelected) {
            if(getState().taxFilingPlanInfoReducer.taxFilingPlan) {
              locationId = getState().taxFilingPlanInfoReducer.taxFilingPlan.locationInfoId;
              taxFilingPlanId = getState().taxFilingPlanInfoReducer.taxFilingPlan.id;
              taxYear = getState().taxFilingPlanInfoReducer.taxFilingPlan.taxYear;
            }
            // alert("loactionid: "+ locationId+ "TaxFilingplanid: "+ taxFilingPlanId+ "taxyear: "+taxYear);
        } 
        if(locationId === null || locationId === undefined) {
            locationId = getState().locationInfo.locationInfo.id;
        }
        if(taxFilingPlanId === null || taxFilingPlanId === undefined) {
            taxFilingPlanId = getState().taxInfoReducer.taxInfo.id;
        }
        // alert("taxFilingPlanID: "+ taxFilingPlanId);
        if(taxYear === null || taxYear === undefined) {
            taxYear =  getState().taxInfoReducer.taxInfo.taxYear;
        }
        // alert("taxYear: "+ taxYear);
       
        getInventoryInfo(locationId, taxYear, taxFilingPlanId).then((response) => {
            if (response.status === 200) {
                if (response.data._id) {
                    logger.info(`Successfully retrieved Inventory Information by locationId : ${locationId}`);
                    dispatch({type: FETCH_INVENTORY_INFO_SUCCESS, payload: response.data}); 
                    // dispatch(setInventoryInfoStatus(true))  
                } else {
                    logger.error(`Failed to retrieve Inventory Information from InventoryInfoActions`);
                    dispatch({ type: FETCH_INVENTORY_INFO_ERROR });
                    // dispatch(setInventoryInfoStatus(false));
                }
            } 
        }).catch(err => {
            logger.error(`Failed to retrieve Inventory Information from InventoryInfoActions, error : -> ${err}`);
            dispatch({type: FETCH_INVENTORY_INFO_ERROR});
            // dispatch(setInventoryInfoStatus(false));
        })
    }
}

export const updateInventoryInfo = (payload,isPageSubmitted,lastVisitedPage) => {
    return (dispatch, getState) => {
        let inventoryId = getState().inventoryInfo.inventoryInfo._id;
       
        let locationInfoId, taxFilingPlanId,businessInfoId;
        if(getState().taxFilingPlanInfoReducer.isPlanSelected) {
            if(getState().taxFilingPlanInfoReducer.taxFilingPlan) {
                locationInfoId = getState().taxFilingPlanInfoReducer.taxFilingPlan.locationInfoId;
              taxFilingPlanId = getState().taxFilingPlanInfoReducer.taxFilingPlan.id;
              businessInfoId = getState().taxFilingPlanInfoReducer.taxFilingPlan.businessInfoId;
            }
            // alert("locationInfoId: "+ locationInfoId+ "TaxFilingplanid: "+ taxFilingPlanId+ "businessid: "+businessInfoId);
        } else {
            locationInfoId = getState().locationInfo.locationInfo.id;
            taxFilingPlanId = getState().taxInfoReducer.taxInfo.id;
            businessInfoId = getState().businessInfo.businessInfo.id;
        }

        if(locationInfoId === null || locationInfoId === undefined) {
            locationInfoId = getState().locationInfo.locationInfo.id;
        }
        if(taxFilingPlanId === null || taxFilingPlanId === undefined) {
            taxFilingPlanId = getState().taxInfoReducer.taxInfo.id;
        }
        // alert("taxFilingPlanID: "+ taxFilingPlanId);
        if(businessInfoId === null || businessInfoId === undefined) {
            businessInfoId = getState().businessInfo.businessInfo.id;
        }
        // alert("businessid: "+ businessInfoId);

        updateInventory(inventoryId, payload).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully updated Inventory Information for the InventoryId: ${inventoryId}`);
                NotificationManager.success("","Update Successful");
                dispatch(getInventoryInfoByLocIdTaxYearAndTaxFillingId());
                dispatch(putTaxInfo(taxFilingPlanId,businessInfoId,locationInfoId,lastVisitedPage,true));
                dispatch(setModalState(false));
                // dispatch(setAppServiceInfo(response.status,"Inventory Info successfully submitted",false,isPageSubmitted));
            }
        }).catch((err) => {
            logger.error(`Failed to update Inventory Information from InventoryInfoActions, error : -> ${err}`);
            NotificationManager.error(err.message,"Failed to Update Inventory",5000);
            dispatch({type: UPDATE_INVENTORY_INFO_ERROR, payload: err.message});
            dispatch(setAppServiceInfo(err.response.status,"Inventory Info error",true,isPageSubmitted));
        })

        
    }
}

export const updateSecondFormSectionStatus = (payload) => {
    return ((dispatch) => {
        dispatch({ type: UPDATE_SECOND_FORM_SECTION_STATUS, payload: payload });
    })
}

export const updateThirdFormSectionStatus = (payload) => {
    return ((dispatch) => {
        dispatch({ type: UPDATE_THIRD_FORM_SECTION_STATUS, payload: payload });
    })
}

export const updateInvForResaleFlag = (payload) => {
    return ((dispatch) => {
        dispatch({ type: UPDATE_INV_RESALE_FLAG, payload: payload });
    })
}

export const resetInventory=(payload)=>{
    return((dispatch)=>{
        dispatch({type:RESET_INVENTORY});
    })
}
