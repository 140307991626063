import { NotificationManager } from "react-notifications";
import businessDataFetch from "./BusinessDataFetch";

// Import your action type
export const BUSINESS_DATA_FETCH = "BUSINESS_DATA_FETCH";
export const RESET_BUSINESS_DATA_FETCH = "RESET_BUSINESS_DATA_FETCH";
export const FETCH_BUSINESS_DATA_FAILURE = "FETCH_BUSINESS_DATA_FAILURE";
// This action creator now returns a function that performs the async operation

// export const fetchBusinessData =  (countyInput, propertyIdInput) => async (dispatch) => {
//     try {
//       console.log(
//         "Fetch Business Data called " + countyInput + " " + propertyIdInput
//       );
//       const data = await businessDataFetch(countyInput, propertyIdInput);
//       dispatch({ type: BUSINESS_DATA_FETCH, payload: data });
//       return data;
//     } catch (error) {
//       dispatch({
//         type: FETCH_BUSINESS_DATA_FAILURE,
//         payload: error,
//       });
//       return Promise.reject(error);
//     }
//   };

export const fetchBusinessData = (county, propertyId) => async (dispatch) => {
  return businessDataFetch(county, propertyId)
    .then((data) => {
      // Assuming your API returns null or a similar indicator for no data found
      if (data === null || data === undefined) {
        dispatch({
          type: FETCH_BUSINESS_DATA_FAILURE,
          payload: "Please Enter Valid Property ID",
        });
      } else {
        dispatch({ type: BUSINESS_DATA_FETCH, payload: data });
      }
    })
    .catch((error) => {
      console.error("Failed to fetch business data", error);
      NotificationManager.error(error.message,"Failed to fetch Business data",5000);
      dispatch({
        type: FETCH_BUSINESS_DATA_FAILURE,
        payload: error.toString(),
      });
    });
};

export const resetBusinessData = () => {
  return (dispatch) => {
    dispatch({ type: RESET_BUSINESS_DATA_FETCH });
  };
};
