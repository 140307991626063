import NotificationManager from "react-notifications/lib/NotificationManager";
import { uspsvalidator } from "../../util/uspsConfig";
import { OPERATING_STATES } from "../../constants/operatingStates";
import {
    getLeasedAssetsInfoByLocation, postLeasedAssets, deleteLeasedAssetInfoByID,
    getLeasedAssetInfoByAssetId, updateLeasedAssetInfoByAssetId, deleteBulkLeasedAssets
} from "../../api/LeasedAssetsApi";

const { logger } = require('../../logger');

export const FETCH_LEASED_CITY_STATE = 'FETCH_LEASED_CITY_STATE';
export const FETCH_LEASED_CITY_STATE_SUCCESS = 'FETCH_LEASED_CITY_STATE_SUCCESS';
export const FETCH_LEASED_CITY_STATE_FAILURE = 'FETCH_LEASED_CITY_STATE_FAILURE';

export const SET_LEASED_ASSETS = 'SET_LEASED_ASSETS';
export const SET_LEASED_ASSETS_SUCCESS = 'SET_LEASED_ASSETS_SUCCESS';
export const SET_LEASED_ASSETS_FAILURE = 'SET_LEASED_ASSETS_FAILURE';

export const FETCH_LEASED_ASSETS_VALUES = 'FETCH_LEASED_ASSETS_VALUES';

export const FETCH_FILTERED_LEASED_ASSETS_INFO = 'FETCH_FILTERED_LEASED_ASSETS_INFO';
export const FETCH_FILTERED_LEASED_ASSETS_INFO_SUCCESS = 'FETCH_FILTERED_LEASED_ASSETS_INFO-SUCCESS';
export const FETCH_FILTERED_LEASED_ASSETS_INFO_FAILURE = 'FETCH_FILTERED_LEASED_ASSETS_INFO_FAILURE';

export const GET_UPDATE_LEASED_ASSETINFO = 'GET_UPDATE_LEASED_ASSETINFO';
export const GET_UPDATE_LEASED_ASSETINFO_SUCCESS = 'GET_UPDATE_LEASED_ASSETINFO_SUCCESS';
export const GET_UPDATE_LEASED_ASSETINFO_FAILURE = 'GET_UPDATE_LEASED_ASSETINFO_FAILURE';

export const DELETE_LEASED_ASSETS_SUCCESS = 'DELETE_LEASED_ASSETS_SUCCESS';
export const SET_LEASED_ASSETS_STATUS = 'SET_LEASED_ASSETS_STATUS';

export const SET_PARTIAL_LEASEDASSET_INFO_SUCCESS = 'SET_PARTIAL_LEASEDASSET_INFO_SUCCESS';
export const SET_LEASED_ASSET_ADDRESSINFO = 'SET_LEASED_ASSET_ADDRESSINFO';
export const SET_LEASED_ASSETS_MODAL_STATE = 'SET_LEASED_ASSETS_MODAL_STATE';
export const RESET = 'RESET';

export const submitLeasedAssetsDetails = (payload,isPageSubmitted,closeAction) => {
    // alert("submitted Leased Assets: "+ payload.taxFilingPlanId);
    return (dispatch, getState) => {

        dispatch({ type: SET_LEASED_ASSETS, payload });
        if (getState().leasedAssets.addressSelected) {
            submitLeaseDetails(payload,isPageSubmitted,dispatch,closeAction);
        }
        else {
            dispatch(verifyLeasedAssetsAddress(payload,isPageSubmitted,closeAction));
        }
    }
}

const submitLeaseDetails = (payload, isPageSubmitted,dispatch,closeAction) => {
    let leaseStartDate = new Date(payload.leaseStartDate);
    let leaseEndDate = new Date(payload.leaseEndDate);
    let postPayload = {
        'leasedAssetDescription': payload.leasedAssetDescription,
        'address': {
            'line1': payload.leaseCompMailAddr,
            'zipcode': payload.zip,
            'city': payload.city, 
            'state': payload.state,
        },
        'leasedNumber': payload.leasedNumber,
        'leasingCompanyName': payload.leasingCompanyName,
        'leaseStartDate': new Date( leaseStartDate.getTime() + Math.abs(leaseStartDate.getTimezoneOffset()*60000) ).getTime() ,
        'leaseEndDate': new Date( leaseEndDate.getTime() + Math.abs(leaseEndDate.getTimezoneOffset()*60000) ).getTime() ,
        'locationInfoId':payload.locationInfoId,
        'taxFilingPlanId':payload.taxFilingPlanId
    }
    
    postLeasedAssets({ ...postPayload }).then(response => {
        if (response.status === 200) {
            logger.info(`Successfully posted LeasedAssetsInformation`);
            NotificationManager.success("","Added Successfully!!");
            dispatch({ type: SET_LEASED_ASSETS_SUCCESS, payload: {...payload,id:response.data.leasedAssetInfoId } });
            dispatch(getLeasedAssetInfoByLoc(0,5));
            dispatch(setLeasedAssetsAddressModalState(false));
            closeAction();
            // dispatch(setAppServiceInfo(response.status,"LeasedAsset Info successfully submitted",false,isPageSubmitted));
        }
    }).catch((err) => {
        logger.error(`Failed to Post LeasedAssets from LeasedAssetActions, error : -> ${err}`);
        NotificationManager.error(err.message, 'Failed to Post LeasedAssets',5000);
        closeAction();
        // dispatch(setAppServiceInfo(err.response.status,"LeasedAsset Info error",true,isPageSubmitted));
    });
}

export const verifyLeasedAssetsZipCode = (zip) => {
    return (dispatch) => {
        dispatch({ type: FETCH_LEASED_CITY_STATE, payload: zip});
        setTimeout( () => {
            // call to fetch the city and state values from zipcode
            uspsvalidator.cityStateLookup(zip).then((res) => {
                if(res.State && res.City){
                    if (OPERATING_STATES.includes(res.State)) {
                        logger.info(`Successfully fetched State,City details for the Zipcode:${zip}`);
                        dispatch({ type: FETCH_LEASED_CITY_STATE_SUCCESS, payload: res});
                    }
                    else {
                        logger.info(`We are not serving this area at this particular moment where Zipcode:${zip}.`);
                        dispatch({ type: FETCH_LEASED_CITY_STATE_FAILURE, payload: res });
                        NotificationManager.error("We are not serving this area at this particular moment.")
                    }
                }
                else{
                    logger.error(`Failed to fetch State,City using Zipcode:${zip}, from file: LeasedAssetActions`);
                    dispatch({ type: FETCH_LEASED_CITY_STATE_FAILURE, payload: res});
                    NotificationManager.error(res.message);
                }
            }).catch((err) => {
                logger.error(`Failed to fetch State,City using Zipcode:${zip}, from file: LeasedAssetActions, error : -> ${err}`);
                NotificationManager.error(err.message,'Failed to fetch State,City using Zipcode', 5000);
                dispatch({ type: FETCH_LEASED_CITY_STATE_FAILURE, payload: err});
            });
        }, 2000 )
    }
}

export const getLeasedAssetInfoByLoc = (offset, limit) => {
    return (dispatch, getState) => {
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        // let taxFilingPlanData = getState().taxInfoReducer;  //getting taxfilling id from taxInfoReducer
        let locationInfo = getState().locationInfo.locationInfo;

        let locationInfoId = null,taxInfoId = null;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxInfoId = taxFilingPlanData.taxFilingPlan.id;
                if(taxInfoId===undefined||taxInfoId===null||taxInfoId===""||taxInfoId===0){
                    taxInfoId = getState().taxInfoReducer.taxInfo.id; //updating the taxinfoid
                }
            }
        
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxInfoId == null || taxInfoId === undefined){
            taxInfoId = locationInfo != null ? locationInfo.taxInfoId:'';
            if(taxInfoId===undefined||taxInfoId===null||taxInfoId===""||taxInfoId===0){
                taxInfoId = getState().taxInfoReducer.taxInfo.id; //updating the taxinfoid
            }
        }
        getLeasedAssetsInfoByLocation(locationInfoId,taxInfoId, offset, limit, '').then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully retrieved LeasedAssetInfo using LocationId:${locationInfoId}`);
                let data = response.data;
                if (data.length !== 0) {
                    dispatch({ type: FETCH_LEASED_ASSETS_VALUES, payload: data })
                    // if (data.totalItems !== 0) {
                    //     dispatch(setLeasedAssetStatus(true));
                    // } else {
                    //     dispatch(setLeasedAssetStatus(false))
                    // }
                   
                } else {
                    logger.info(`We got empty payload(LeasedAssetInfo) for the locationId:${locationInfoId}`);
                    dispatch({type: FETCH_LEASED_ASSETS_VALUES, payload: []})
                }
                
            }
        }).catch((err) => {
            logger.error(`Failed to fetch LeasedAssetInfo using LocationId:${locationInfoId}, from file: LeasedAssetActions, error : -> ${err}`);
        });
    }
}

export const deleteLeasedAssetInfo = (leasedAssetId, rowsPerPage) => {
    return (dispatch) => {
            deleteLeasedAssetInfoByID(leasedAssetId).then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("","Deleted Successfully!!");
                    dispatch({ type: DELETE_LEASED_ASSETS_SUCCESS })
                    dispatch(getLeasedAssetInfoByLoc(0, rowsPerPage))
                }
            }).catch((err) => {
                logger.error(`Failed to fetch LeasedAssetInfo using LeasedAssetId:${leasedAssetId}, from file: LeasedAssetActions, error : -> ${err}`);
                NotificationManager.error(err.message,"Failed to Delete. Please Try again!!");
            });
        
    }
}

export const deleteBulkLeasedAssetsInfo = (leasedAssetIds, rowsPerPage) => {
    return (dispatch) => {
            deleteBulkLeasedAssets(leasedAssetIds).then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("","Deleted Successfully!!");
                    dispatch({ type: DELETE_LEASED_ASSETS_SUCCESS })
                    dispatch(getLeasedAssetInfoByLoc(0, rowsPerPage))
                }
            }).catch((err) => {
                logger.error(`Failed to delete LeasedAssetInfo,FileName: LeasedAssetInfoActions, error : -> ${err}`);
                NotificationManager.error(err.message,"Failed to Delete. Please Try again!!");
            });
        
    }
}

const getFormattedDate = (date) => {
    let dateObj = new Date(date)
    let finalDateString = dateObj.getFullYear() + '-' + ('0' + (dateObj.getMonth()+1)).slice(-2) + '-' + ('0' + dateObj.getDate()).slice(-2) 
    
    return finalDateString
}

export const getLeasedAssetInfoById = (leasedAssetId) => {
    return (dispatch) => {
        dispatch({ type: GET_UPDATE_LEASED_ASSETINFO})
        getLeasedAssetInfoByAssetId(leasedAssetId).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully retrieved LeasedAssetInfo for the leasedAssetId:${leasedAssetId}`);
                let data = response.data;
                let getPayload = {
                    leasedAssetDescription: data.leasedAssetDescription,
                    leasedNumber: data.leasedNumber,
                    leasingCompanyName: data.leasingCompanyName,
                    leaseStartDate: getFormattedDate(data.leaseStartDate),
                    leaseEndDate: getFormattedDate(data.leaseEndDate),
                    leaseCompMailAddr: data.address.line1,
                    zip: data.address.zipcode,
                    city: data.address.city, 
                    state: data.address.state,
                    id : data.id
                }
                dispatch({ type: GET_UPDATE_LEASED_ASSETINFO_SUCCESS, payload: getPayload})
            }
        }).catch((err) => {
            logger.error(`Failed to retrieve LeasedAssetInfo for the leasedAssetId:${leasedAssetId}, from file: LeasedAssetActions, error : -> ${err} `)
            dispatch({ type: GET_UPDATE_LEASED_ASSETINFO_FAILURE});
        });
    }
}

export const updateLeasedAssetsDetails = (payload,isPageSubmitted) => {
    return (dispatch, getState) => {
        let leasedAssetId = payload.leasedAssetId;
        let updatePayload = {
            'leasedAssetDescription': payload.leasedAssetDescription,
            'locationInfoId': getState().appClientCtx.currLocation,
            'address': {
                'line1': payload.leaseCompMailAddr,
                'zipcode': payload.zip,
                'city': 1488, 
                'state': 51,
            },
            'leasingCompanyName': payload.leasingCompanyName,
            'leaseStartDate': new Date(payload.leaseStartDate).getTime(),
            'leaseEndDate': new Date(payload.leaseEndDate).getTime(), 
            'leasedNumber': payload.leasedNumber
        }
        updateLeasedAssetInfoByAssetId(leasedAssetId, updatePayload).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully updated LeasedAssetInfo for the leasedAssetId:${leasedAssetId}`)
                NotificationManager.success("","Updated Successfully!!");
                dispatch({ type: SET_LEASED_ASSETS_SUCCESS, payload: response.data }) 
                dispatch(getLeasedAssetInfoByLoc(0,5));
                dispatch(setLeasedAssetsAddressModalState(false));
                // dispatch(setAppServiceInfo(response.status,"LeasedAsset Info successfully submitted",false,isPageSubmitted));
            }
        }).catch((err) => {
            logger.error(`Failed to update LeasedAssetInfo for the leasedAssetId:${leasedAssetId}, from file: LeasedAssetActions, error : -> ${err} `);
            NotificationManager.error(err.message,'Failed to Update Leased Assets Info' ,5000);
            dispatch({ type: GET_UPDATE_LEASED_ASSETINFO_FAILURE});
            // dispatch(setAppServiceInfo(err.response.status,"LeasedAsset Info error",true,isPageSubmitted));
        });
    }
}

export const clearLeasedAssetsForm = () => {
    return (dispatch) => {
        dispatch({type: RESET})
    }
}

export const getFilteredLeasedAssetsInfo = (description,offset,limit) => {
    return (dispatch, getState) => {
        let locationInfoId,taxInfoId;
        let taxFilingPlanData = getState().taxFilingPlanInfoReducer;
        let locationInfo = getState().locationInfo.locationInfo;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                // taxInfoId = taxFilingPlanData.taxFilingPlan.id;
                taxInfoId = getState().taxInfoReducer.taxInfo.id; //updating the taxinfoid
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxInfoId == null || taxInfoId === undefined){
            // taxInfoId = locationInfo != null ? locationInfo.taxInfoId:'';
            taxInfoId = getState().taxInfoReducer.taxInfo.id; //updating the taxinfoid
        }
        // let offset = 0;
        // let limit = 5;
        getLeasedAssetsInfoByLocation(locationInfoId, taxInfoId, offset, limit, description).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully retrieved LeasedAssetsInfo for the locationInfo:${locationInfo}`);
                let data = response.data;
                if(data.totalItems<0 || data.totalItems===0){
                    NotificationManager.error("","No Leased Assets found on the given criteria!!")
                }
                dispatch({type: FETCH_LEASED_ASSETS_VALUES, payload: data});
            }
        }).catch((err) => {
            logger.error(`Failed to get Filtered LeasedAssetInfo for the locationInfo:${locationInfo}, from file: LeasedAssetActions, error : -> ${err} `);
        });
    }
}

// export const verifyLeasedAssetsAddress = (payload,isPageSubmitted,closeAction) => {
//     const addressObj = {
//         Address1: payload.leaseCompMailAddr,
//         City: payload.city,
//         State: payload.state,
//         Zip5: payload.zip   
//     }

//     return (dispatch) => {
//         uspsvalidator.verify(addressObj).then((response) => {
//             console.log('USPS Verification Response:', response); 
//             let line1 = false;
//             if (response.Address1!==undefined) {
//                 if (response.Address1 === addressObj.Address1) {
//                     line1 = true;
//                 }
//             }
           
//             if (line1) {
//                 submitLeaseDetails(payload, isPageSubmitted,dispatch,closeAction);
//             }
//             else {
//                 let addressPayload = {
//                     ...payload,
//                     uspsAddress: response
//                 }
//                 dispatch({ type: SET_PARTIAL_LEASEDASSET_INFO_SUCCESS, payload: addressPayload });
//                 // dispatch(setCurrentPane(paneSteps.eight));
//                 dispatch(setLeasedAssetsAddressModalState(true));
//             }
//         })
//     }
// }

export const verifyLeasedAssetsAddress = (payload, isPageSubmitted, closeAction) => {
    return (dispatch) => {
        const addressObj = {
            Address1: payload.leaseCompMailAddr,
            City: payload.city,
            State: payload.state,
            Zip5: payload.zip   
        };

        uspsvalidator.verify(addressObj).then((response) => {
            let line1 = false;
            if (response.Address1 !== undefined) {
                if (response.Address1 === addressObj.Address1) {
                    line1 = true;
                }
            }

            if (line1) {
                submitLeaseDetails(payload, isPageSubmitted, dispatch, closeAction);
            } else {
                let addressPayload = {
                    ...payload,
                    uspsAddress: response
                }
                dispatch({ type: 'SET_PARTIAL_LEASEDASSET_INFO_SUCCESS', payload: addressPayload });
                // dispatch(setCurrentPane(paneSteps.eight));
                dispatch(setLeasedAssetsAddressModalState(true));
            }
        }).catch((error) => {
            console.error('USPS Address Verification Error:', error);
            NotificationManager.error(error.message, 'Address Verification Failed', 5000);
            dispatch({ type: 'SET_LEASEDASSET_VERIFICATION_ERROR', error: error });
        });
    }
}

export const setAddressForLeasedAssets = (payload, addressType) => {
    return (dispatch, getState) => {
        let addressPayload = '';
        if (addressType === 'userAddress') {
            addressPayload = {
                leaseCompMailAddr: payload.leaseCompMailAddr,
                city:payload.city,
                state:payload.state
            }
        } else {
            addressPayload = {
                leaseCompMailAddr: payload.Address1, 
                city:payload.City,
                state:payload.State
            }
            
        }
        dispatch({ type: SET_LEASED_ASSET_ADDRESSINFO, payload: addressPayload });
        // dispatch(submitLeasedAssetsDetails(getState().leasedAssets.leasedAssetsInfo));
        dispatch(setLeasedAssetsAddressModalState(false));
        // dispatch(setCurrentPane(paneSteps.four));
    }
}
export const setLeasedAssetsAddressModalState = (flag) => {
    return (dispatch) => {
        dispatch({ type: SET_LEASED_ASSETS_MODAL_STATE, payload: flag})
    }
}