import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { Grid , Box, Typography,Divider, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from "../../../constants/colorScheme";
import { getSuppliesInfoRelatedToYearAndLocation, submitSuppliesInfo, updateSuppliesInfo } from '../../../redux/actions/SuppliesInfoActions';
import { getIsSuppliesUpdating, getSuppliesInformation } from "../selectors/SuppliesInfoSelector";
import { getTaxFillingData } from '../selectors/TaxFilingPlanSelector';
import { getBusinessInfo } from '../selectors/BusinessInfoSelector';
import { setAppServiceInfo } from '../../../redux/actions/AppServiceInfoAction';
import { formatNumberInput } from '../../../util/utilities';

const useStyles = makeStyles((theme) => ({
    fieldContainer: {
      width:'90vw',
      [theme.breakpoints.down("sm")]: {
        width: '45vw',
        left: '20%'
        },
        "& .MuiInputLabel-root": {
            color: PRIMARY_COLOR,
            fontWeight: 'bold'
        },
    },
  }));

export default function Supplies({nextButton,saveButton}) {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const classes = useStyles();
    const suppliesInfoData = useSelector(getSuppliesInformation);
    const [suppliesInfo, setSuppliesInfo] = useState({ ...suppliesInfoData });
    const updateFlag = useSelector(getIsSuppliesUpdating);
    const businessInfo = useSelector(getBusinessInfo);
    const taxFilingPlanData = useSelector(getTaxFillingData);

    let businessInfoId; 
    if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
        if(taxFilingPlanData.taxFilingPlan){
            businessInfoId = taxFilingPlanData.taxFilingPlan.businessInfoId;
        }
    } else{
        businessInfoId = businessInfo.id;
    }

    const onSubmit = (data,lastVisitedPage) => {
        updateFlag ? dispatch(updateSuppliesInfo(data,businessInfoId,true,lastVisitedPage)) : dispatch(submitSuppliesInfo(data,businessInfoId,true,lastVisitedPage))
    }

    useEffect(() => {
        if(taxFilingPlanData.isPlanSelected){
         dispatch(getSuppliesInfoRelatedToYearAndLocation());
        }
        dispatch(setAppServiceInfo(0,null,false,false));
    },[dispatch])
    
    useEffect(() => {
        setSuppliesInfo(suppliesInfoData);
        reset();
    },[suppliesInfoData])

    return (
    <Box style={{position:'relative',left:'2%',bottom:'15%',textAlign:'justify'}}>
    <form onSubmit={handleSubmit(onSubmit)} >
        <Grid container direction="column" spacing={2}>
            <Grid item xs={12} sx={{ m: 1 }}>
                <h1 style={{textAlign:'center'}}>Supplies Information
                    <Typography className={classes.loginTitle} style={{ float: 'right' }}>
                            <span style={{color: 'red'}}>*</span>  Required fields 
                    </Typography><Divider />
                </h1> 
                <h5>
                    Supplies are assets with a short life such as office materials, cleaning, spare parts which are consumed 
                    in the course of operating business. 
                </h5>
            </Grid>
            <Grid item xs={12}>
                <TextField 
                    id="totalExpensesYearly" label="Total Supplies Expenses for this year" variant="outlined" 
                    {...register('totalExpensesYearly', 
                        {
                            required: 'Total expenses yearly field is required', maxLength: 130,
                            onChange: (e) => { setSuppliesInfo({ ...suppliesInfo, totalExpensesYearly: e.target.value }) }
                        })
                    }
                    type="number"
                    InputLabelProps={{shrink: true, required: true, sx: {'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}}}} 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        onKeyDown={ formatNumberInput }
                    className={classes.fieldContainer}
                    error={Boolean(errors.totalExpensesYearly)}
                    helperText={errors.totalExpensesYearly ? errors.totalExpensesYearly.message : ""}
                    value = {suppliesInfo.totalExpensesYearly?suppliesInfo.totalExpensesYearly:''}
                />
            </Grid>
            <Grid item>
                <TextField 
                    id="reOrderTimes" label="Average number of times you reorder supplies" variant="outlined" 
                    {...register('reOrderTimes', 
                        {
                            required: "Average reorder times field is required", maxLength: 30,
                            onChange: (e) => { setSuppliesInfo({ ...suppliesInfo, reOrderTimes: e.target.value }) }
                        })
                    }
                    type="number"
                    onKeyDown={formatNumberInput}
                    InputLabelProps={{shrink: true, required: true, sx: {'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}}}} 
                    className={classes.fieldContainer}
                    helperText={errors.reOrderTimes ? errors.reOrderTimes.message : ""}
                    error={Boolean(errors.reOrderTimes)}
                    value = {suppliesInfo.reOrderTimes?suppliesInfo.reOrderTimes:''}
                />
            </Grid>
        <input type="submit" ref={nextButton} style={{ display: 'none' }} onClick={handleSubmit((data) => {onSubmit(data,'InventoryInfo')})}/>
        <input type="submit" ref={saveButton} style={{ display: 'none' }} onClick={handleSubmit((data) => {onSubmit(data,'SuppliesInfo')})}/>
        </Grid>
        
    </form>
    </Box>
  );
}
