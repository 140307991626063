import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import {
  Grid,
  Box,
  Typography,
  Divider,
  LinearProgress,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
  Modal,
  Fade,
  Backdrop,
  Button,
  Hidden,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  clearLocationInfo,
  getSelectedLocationInfo,
  submitLocationInfo,
  verifylocationInfoZipCode,
  GET_LOCATION_INFO,
} from "../../../redux/actions/LocationInfoAction";
import { PRIMARY_COLOR } from "../../../constants/colorScheme";
import { getBusinessInfo } from "../selectors/BusinessInfoSelector";
import { getTaxInfo } from "../selectors/TaxInfoSelector";
import {
  getIsLoadingLocationStateCityValues,
  getLocationCityValue,
  getLocationCountyValue,
  getLocationInfo,
  getLocationStateValue,
} from "../selectors/LocationInfoSelector";
import {
  getCityValue,
  getCountyValue,
  getLocsOfBusiness,
  getStateValue,
} from "../AppClientSelectors";
import { defineCurrLocation, loadLocsOfBusiness } from "../AppClientActions";
import DuplicateTaxModal from "./ModalContentScreen";
import { getTaxFillingData } from "../selectors/TaxFilingPlanSelector";
import { dispatchSavedPlanData } from "../../../util/utilities";
import AddressSuggestionModal from "../../addressSuggestion/AddressSuggestionModal";
import { getLocationAddressModalState } from "../selectors/LocationInfoSelector";
import { setAppServiceInfo } from "../../../redux/actions/AppServiceInfoAction";
import { formatNumberInput } from "../../../util/utilities";
import { getBusinessData } from "../selectors/BusinessDataFetchSelector";

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    width: "90vw",
    [theme.breakpoints.down("sm")]: {
      width: "45vw",
      left: "20%",
    },
    "& .MuiInputLabel-root": {
      color: PRIMARY_COLOR,
      fontWeight: "bold",
    },
  },
}));

export default function LocationInformation({ nextButton, saveButton }) {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const classes = useStyles();
  const businessInfo = useSelector(getBusinessInfo);
  const taxInfo = useSelector(getTaxInfo);
  const locationInfoData = useSelector(getLocationInfo);
  const stateValue = useSelector(getLocationStateValue);
  const cityValue = useSelector(getLocationCityValue);
  const countyValue = useSelector(getLocationCountyValue);
  const isLoadingStateValues = useSelector(getIsLoadingLocationStateCityValues);
  const [locationInfo, setLocationInfo] = useState({ ...locationInfoData });
  const stateValues = useSelector(getStateValue);
  const cityValues = useSelector(getCityValue);
  const countyValues = useSelector(getCountyValue);
  const locationsRelatedToBusiness = useSelector(getLocsOfBusiness);
  const [businessLocations, setBusinessLocations] = useState([]);
  const [locationValue, setLocationValue] = useState("");
  const taxFilingPlanData = useSelector(getTaxFillingData);
  const addressModalState = useSelector(getLocationAddressModalState);
  const businessData = useSelector(getBusinessData);

  useEffect(() => {
    if (taxFilingPlanData.isPlanSelected) {
      dispatchSavedPlanData(
        dispatch,
        "LocationInfo",
        taxFilingPlanData.taxFilingPlan
      );
    }
    dispatch(setAppServiceInfo(0, null, false, false));
  }, [dispatch]);

  const zipCodeLookUpValues = (zipcode) => {
    if (zipcode.length === 5) {
      dispatch(verifylocationInfoZipCode(locationInfo, zipcode));
    } else {
      setValue("city", "");
      setValue("state", "");
      setValue("county", "");
    }
  };

  const getIdOfValue = (valueType, value) => {
    switch (valueType) {
      case "state":
        const stateId = stateValues
          .filter((item) => item.codeValueKey === value)
          .map((item) => item.id);
        return stateId[0];
      case "city":
        const cityId = cityValues
          .filter((item) => item.codeValueKey === value)
          .map((item) => item.id);
        return cityId[0];
      case "county":
        const countyId = countyValues
          .filter((item) => item.codeValueKey === value)
          .map((item) => item.id);
        return countyId[0];
      default:
        return "";
    }
  };

  const getValueOfId = (valueType, value) => {
    switch (valueType) {
      case "state":
        const stateCodeValue = stateValues
          .filter((item) => item.id === value)
          .map((item) => item.codeValueKey);
        return stateCodeValue[0];
      case "city":
        const cityCodeValue = cityValues
          .filter((item) => item.id === value)
          .map((item) => item.codeValueKey);
        return cityCodeValue[0];
      case "county":
        const countyCodeValue = countyValues
          .filter((item) => item.id === value)
          .map((item) => item.codeValueKey);
        return countyCodeValue[0];
      default:
        return "";
    }
  };

  const handleLocationChange = (e) => {
    let selectedLocation = e.target.value;
    setLocationValue(selectedLocation);
    dispatch(defineCurrLocation(selectedLocation));
    dispatch(getSelectedLocationInfo(selectedLocation));
  };

  useEffect(() => {
    setValue(
      "city",
      Number.isInteger(cityValue)
        ? getValueOfId("city", cityValue)
        : cityValue
        ? cityValue
        : ""
    );
    setValue(
      "state",
      Number.isInteger(stateValue)
        ? getValueOfId("state", stateValue)
        : stateValue
        ? stateValue
        : ""
    );
    setValue(
      "county",
      Number.isInteger(countyValue)
        ? getValueOfId("county", countyValue)
        : countyValue
        ? countyValue
        : ""
    );
  }, [cityValue, stateValue, countyValue]);

  useEffect(() => {
    let businessInfoId;
    if (taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
      if (taxFilingPlanData.taxFilingPlan) {
        businessInfoId = taxFilingPlanData.taxFilingPlan.businessInfoId;
      }
    } else {
      businessInfoId = businessInfo.id;
    }
    dispatch(loadLocsOfBusiness(businessInfoId));
  }, [dispatch, taxFilingPlanData]);

  useEffect(() => {
    setBusinessLocations(locationsRelatedToBusiness);
    reset();
    setLocationInfo(locationInfoData);
  }, [locationsRelatedToBusiness, locationInfoData]);

  const onSubmit = (data, lastVisitedPage) => {
    let businessInfoId;
    if (taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
      if (taxFilingPlanData.taxFilingPlan) {
        businessInfoId = taxFilingPlanData.taxFilingPlan.businessInfoId;
      }
    }

    if (businessInfoId === null || businessInfoId === undefined) {
      if (businessInfo !== null) {
        businessInfoId = businessInfo.id;
      }
    }

    let mappedData = {
      ...data,
      state: Number.isInteger(locationInfo.state)
        ? locationInfo.state
        : getIdOfValue("state", locationInfo.state),
      city: Number.isInteger(locationInfo.city)
        ? locationInfo.city
        : getIdOfValue("city", locationInfo.city),
      county: Number.isInteger(locationInfo.county)
        ? locationInfo.county
        : getIdOfValue("county", locationInfo.county),
      id: locationInfo.id,
    };
    dispatch(
      submitLocationInfo(
        {
          ...mappedData,
          businessInfoId: businessInfoId,
          taxInfoId: taxInfo.id,
        },
        true,
        lastVisitedPage
      )
    );
  };

  useEffect(() => {
    if (
      !locationInfo.error &&
      !taxInfo.error &&
      locationInfo.isSubmitting === "yes"
    ) {
      dispatch({
        type: GET_LOCATION_INFO,
        payload: { ...locationInfo, isSubmitting: "no" },
      });
    }
  }, [locationInfo]);

  useEffect(() => {
    if (
      !businessInfo.noOfLocations ||
      businessInfo.noOfLocations === 0 ||
      businessInfo.noOfLocations === 1
    ) {
      setValue("zipCode", "");
    }
  }, [businessInfo.noOfLocations]);

  return (
    <Box
      style={{
        position: "relative",
        left: "2%",
        bottom: "15%",
        textAlign: "justify",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Grid container>
            <Modal
              open={addressModalState}
              hideBackdrop
              disableEscapeKeyDown={true}
              aria-labelledby="app-modal"
              aria-describedby="app-modal-client"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              disableScrollLock={true}
              className={classes.modalRoot}
            >
              <Fade in={addressModalState}>
                <Box>
                  <AddressSuggestionModal />
                </Box>
              </Fade>
            </Modal>
          </Grid>
        </Box>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12} sx={{ m: 1 }}>
            <h1 style={{ textAlign: "center" }}>
              Location Information
              <Typography
                className={classes.loginTitle}
                style={{ float: "right" }}
              >
                <span style={{ color: "red" }}>*</span> Required fields
              </Typography>
              <Divider />
            </h1>
            <h5> Text to be added </h5>
          </Grid>
          {businessLocations.length !== 0 && (
            <>
              <Grid item>
                <InputLabel id="business-locations-label">
                  Existing Locations
                </InputLabel>
                <Select
                  labelId="business-locations-label"
                  id="buinessLocations"
                  input={
                    <OutlinedInput
                      label="Existing Locations"
                      style={{ width: "100%" }}
                    />
                  }
                  {...register("businessLocations", {
                    onChange: (e) => handleLocationChange(e),
                  })}
                  value={
                    locationValue
                      ? locationValue
                      : taxFilingPlanData.taxFilingPlan
                      ? taxFilingPlanData.taxFilingPlan.locationInfoId
                        ? taxFilingPlanData.taxFilingPlan.locationInfoId
                        : ""
                      : ""
                  }
                >
                  {businessLocations.map((eachBizLoc) => (
                    <MenuItem key={eachBizLoc.id} value={eachBizLoc.id}>
                      {`${eachBizLoc.description}`}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item style={{ marginTop: "5px" }}>
                <Divider>
                  <Chip
                    label="Select from the above dropdown if you want to work on existing locations"
                    sx={{ fontSize: "1rem" }}
                  />
                </Divider>
                <Chip
                  label="Reset Form Fields"
                  onClick={() => {
                    setLocationValue("");
                    dispatch(defineCurrLocation(""));
                    dispatch(clearLocationInfo());
                  }}
                  sx={{ fontSize: "1rem" }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <TextField
              id="propertyDescription"
              label="Property Description"
              variant="outlined"
              {...register("propertyDescription", {
                required: "Property Description is required",
                maxLength: 130,
                onChange: (e) => {
                  setLocationInfo({
                    ...locationInfo,
                    propertyDescription: e.target.value,
                  });
                },
              })}
              className={classes.fieldContainer}
              InputLabelProps={{
                required: true,
                sx: {
                  "&.Mui-required .MuiInputLabel-asterisk": { color: "red" },
                },
              }}
              helperText={
                errors.propertyDescription
                  ? errors.propertyDescription.message
                  : ""
              }
              error={errors.propertyDescription}
              value={
                locationInfo.propertyDescription
                  ? locationInfo.propertyDescription
                  : `${businessData.LEGAL_DESC}`
              }
            />
          </Grid>
          <Grid item>
            <TextField
              id="line1"
              label="Company Mailing Address"
              variant="outlined"
              {...register("line1", {
                required: "Address is required",
                maxLength: 100,
                onChange: (e) => {
                  setLocationInfo({ ...locationInfo, line1: e.target.value });
                },
              })}
              className={classes.fieldContainer}
              helperText={errors.line1 ? errors.line1.message : ""}
              error={errors.line1}
              InputLabelProps={{
                required: true,
                sx: {
                  "&.Mui-required .MuiInputLabel-asterisk": { color: "red" },
                },
              }}
              value={
                locationInfo.line1
                  ? locationInfo.line1
                  : `${businessData.ADDR_LINE2} ${businessData.ADDR_LINE1}`
              }
            />
          </Grid>
          <Grid item>
            <TextField
              id="line2"
              label="Address Line 2"
              variant="outlined"
              {...register("line2", {
                maxLength: 50,
                onChange: (e) => {
                  setLocationInfo({ ...locationInfo, line2: e.target.value });
                },
              })}
              className={classes.fieldContainer}
              value={locationInfo.line2 ? locationInfo.line2 : `${businessData.ADDR_LINE3}`==='NULL'?"":`${businessData.ADDR_LINE3}`}
            />
          </Grid>
          <Grid item>
            <TextField
              id="zipcode"
              label="Zip"
              type="number"
              variant="outlined"
              {...register("zipcode", {
                required: "Zipcode is required",
                maxLength: {
                  value: 5,
                  message: "Only 5 characters are allowed",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Only numericals are allowed",
                },
                onChange: (e) => {
                  setLocationInfo({ ...locationInfo, zipcode: e.target.value });
                  zipCodeLookUpValues(e.target.value);
                },
              })}
              onKeyDown={formatNumberInput}
              className={classes.fieldContainer}
              InputLabelProps={{
                required: true,
                sx: {
                  "&.Mui-required .MuiInputLabel-asterisk": { color: "red" },
                },
              }}
              helperText={errors.zipcode ? errors.zipcode.message : ""}
              error={errors.zipcode}
              value={
                locationInfo.zipcode
                  ? locationInfo.zipcode
                  : `${businessData.ADDR_ZIP.split("-")[0]}`
              }
            />
            {!locationInfo.city && !locationInfo.state && !locationInfo.county && (
                <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
                  <Button type="button" variant="contained" sx={{ background: "#053e85", display:!locationInfo.city && !locationInfo.state && !locationInfo.county ? "inline-flex" : "none"}}
                    onClick={() => { zipCodeLookUpValues( locationInfo.zipcode ? locationInfo.zipcode : `${businessData.ADDR_ZIP.split("-")[0]}`);
                    }}
                  >
                    Click Here!!
                  </Button>
                  {!locationInfo.city &&
                    !locationInfo.state &&
                    !locationInfo.county && (
                      <Typography variant="body1" sx={{ marginLeft: 1 }}>👈 Click the button to retrieve City, State and County Information. </Typography>
                    )}
                </Box>
              )}
          </Grid>
          {isLoadingStateValues && (
            <Grid item>
              <LinearProgress />
            </Grid>
          )}
          <Grid item>
            <TextField
              id="city"
              label="City"
              variant="outlined"
              {...register("city")}
              className={classes.fieldContainer}
              InputLabelProps={{ shrink: true }}
              disabled
              value={
                Number.isInteger(cityValue)
                  ? getValueOfId("city", cityValue)
                  : cityValue
                  ? cityValue
                  : ""
              }
              // value={locationInfo.city?locationInfo.city:`${businessData.ADDR_CITY}`}
            />
          </Grid>
          <Grid item>
            <TextField
              id="state"
              label="State"
              variant="outlined"
              {...register("state")}
              className={classes.fieldContainer}
              InputLabelProps={{ shrink: true }}
              disabled
              value={
                Number.isInteger(stateValue)
                  ? getValueOfId("state", stateValue)
                  : stateValue
                  ? stateValue
                  : ""
              }
              // value={locationInfo.state? locationInfo.state:`${businessData.ADDR_STATE}`}
            />
          </Grid>
          <Grid item>
            <TextField
              id="county"
              label="County"
              variant="outlined"
              {...register("county")}
              className={classes.fieldContainer}
              InputLabelProps={{ shrink: true }}
              disabled
              value={
                Number.isInteger(countyValue)
                  ? getValueOfId("county", countyValue)
                  : countyValue
                  ? countyValue
                  : ""
              }
              // value={locationInfo.county?locationInfo.county:`${businessData.County}`}
            />
          </Grid>
          <Grid item>
            <TextField
              id="countyPropertyId"
              label="Location Code"
              variant="outlined"
              {...register("countyPropertyId", {
                required: "Location code is required",
                maxLength: 30,
                onChange: (e) => {
                  setLocationInfo({
                    ...locationInfo,
                    countyPropertyId: e.target.value,
                  });
                },
              })}
              className={classes.fieldContainer}
              helperText={
                errors.countyPropertyId ? errors.countyPropertyId.message : ""
              }
              error={errors.countyPropertyId}
              InputLabelProps={{
                required: true,
                sx: {
                  "&.Mui-required .MuiInputLabel-asterisk": { color: "red" },
                },
              }}
              value={
                locationInfo.countyPropertyId
                  ? locationInfo.countyPropertyId
                  : `${businessData.ADDR_STATE}`
              }
            />
          </Grid>
          {!businessInfo.noOfLocations ||
          businessInfo.noOfLocations === 0 ||
          businessInfo.noOfLocations === 1 ? null : (
            <Hidden>
              <Grid item>
                <TextField
                  disabled={
                    !businessInfo.noOfLocations ||
                    businessInfo.noOfLocations === 0 ||
                    businessInfo.noOfLocations === 1
                  }
                  id="totalSqft"
                  label="Total Square Footage"
                  type="number"
                  variant="outlined"
                  {...register("totalSqft", {
                    // required: 'Total square footage field is required', maxLength: 30,
                    onChange: (e) => {
                      setLocationInfo({
                        ...locationInfo,
                        totalSqft: e.target.value,
                      });
                    },
                  })}
                  onKeyDown={formatNumberInput}
                  className={classes.fieldContainer}
                  helperText={errors.totalSqft ? errors.totalSqft.message : ""}
                  error={errors.totalSqft}
                  // InputLabelProps={{ required: true, sx: {'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}} }}
                  value={locationInfo.totalSqft ? locationInfo.totalSqft : ""}
                />
                {/* <input type="hidden" {...register('totalSqft')} value={locationInfo.totalSqft?locationInfo.totalSqft:""}/> */}
              </Grid>
            </Hidden>
          )}
          <input
            type="submit"
            ref={nextButton}
            style={{ display: "none" }}
            onClick={handleSubmit((data) => {
              onSubmit(data, "AdditionalBusinessInfo");
            })}
          />
          <input
            type="submit"
            ref={saveButton}
            style={{ display: "none" }}
            onClick={handleSubmit((data) => {
              onSubmit(data, "LocationInfo");
            })}
          />
        </Grid>
        <DuplicateTaxModal />
      </form>
    </Box>
  );
}
