// import axios from "axios";

// const businessDataFetch = async (county, propertyId) => {
//   const postData = {
//     county,
//     propertyId,
//   };

//   console.log("Post Data :"+postData.county+"" + postData.propertyId);
//   try {
//     const response = await axios.post(
//       "http://192.168.0.171:3003/countyPropIdFind",
//       postData,
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     );
//     console.log("++++++++++++++++++++++++++++++++++++++++++++++++++");
//     console.log("Business Owner data", response.data.data[0]);
//     console.log("++++++++++++++++++++++++++++++++++++++++++++++++++");

//     if(response===null|| response===undefined){
//       console.log("Data Not Found");
//     }else{
//       return response.data.data[0];
//     }
//   } catch (error) {
//     console.error("An error occurred while sending data to the server:", error);
//   }
// };
// export default businessDataFetch;

import axios from "axios";

const businessDataFetch = async (county, propertyId) => {
  const postData = { county, propertyId };
  console.log("Post Data :", postData.county, postData.propertyId);

  try {
    const response = await axios.post(
      "https://stage.bptaxguru.com/core/countyPropIdFind",
      postData,
      { headers: { "Content-Type": "application/json" } }
    );
    console.log("Response:", response);
    if (Array.isArray(response.data.data) && response.data.data.length > 0) {
      console.log("Business Owner data", response.data.data[0]);
      return response.data.data[0];
    } else {
      console.log("Data Not Found");
      return null;
    }
  } catch (error) {
    console.error("An error occurred while sending data to the server:", error);
    throw error;
  }
};

export default businessDataFetch;
