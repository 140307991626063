
import { getBusinessInfo } from '../redux/actions/BusinessInfoAction';
import { getLocationInfo } from '../redux/actions/LocationInfoAction';
import { getTaxInfo } from '../redux/actions/taxInfoAction';

export  const validateMailFormat = (emailTxt) => {
    //validate email for proper format
    if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,10}){1,2}$/.test(emailTxt)) {
        return true;
    } else{
        return false;
    } 
}

export const validateZipFormat = (zipCode) => {
    return (/^\d{5}$/.test(zipCode) ? true : false);
}

export const validateAlphaNumericFormat = (fieldInput) =>{
    return (/^[\w\-\s]+$/.test(fieldInput) ? true : false);
}

export const validateTextFormat = (fieldInput) => {
    return (/^[a-zA-Z ]*$/.test(fieldInput) ? true : false);
}

export const validateMobileNumberFormat = (fieldInput) => {
    return (/^\d{10}$/.test(fieldInput) ? true : false);
}

export const validateAddressFormat = (fieldInput) => {
    return (/^[A-Za-z0-9\-\\,/.\s]+$/.test(fieldInput) ? true : false);
}

export const validateNumericFormat = (fieldInput) => {
    return (/^[0-9]*$/.test(fieldInput) ? true : false);
}

export const validateDecimalFormat = (fieldInput) => {
    return (/^\d*\.?\d*$/.test(fieldInput) ? true : false);
}

export const validateMonthFormat = (fieldInput) => {
    return (/^([1-9]|1[012])$/.test(fieldInput) ? true : false);
}

export const dispatchSavedPlanData = (dispatch,lastVisitedPage,taxFilingPlan) => {
    switch(lastVisitedPage) {
        case 'Rendition':
            dispatch();
            break;
        case 'InventoryInfo':
            dispatch();
            break;
        case 'SuppliesInfo':
            dispatch();
            break;
        case 'AdditionalBusinessInfo':
            dispatch();
            break;
        case 'LocationInfo':
            if(taxFilingPlan.locationInfoId)
            dispatch(getLocationInfo(taxFilingPlan.locationInfoId));
            break;
        case 'BusinessInfo':
            dispatch(getBusinessInfo(true,taxFilingPlan.businessInfoId));
            break;
        case 'BasicInfo':
            dispatch(getTaxInfo(taxFilingPlan.id));
            break;
        // case 'RepresentationInformation':
        //     dispatch();
        //     break;
        default:
            // dispatch({type: SET_SELECTED_PLAN});
            break;
    }
}

export const formatMobileNumberInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === "e" || e.key === "E" || e.key === "." || e.key === "+" || e.key === "-" ;
    }
    else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  }

//   export const formatNumberInput = (e) => {
//     // Prevent characters that are not numbers ("e", ".", "+" & "-")
//     let checkIfNum;
//     if (e.key !== undefined) {
//       // Check if it's a "e", ".", "+" or "-"
//       checkIfNum = e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-" ;
//     }
//     else if (e.keyCode !== undefined) {
//       // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
//       checkIfNum = e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189;
//     }
//     return checkIfNum && e.preventDefault();
//   }

export const formatNumberInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-")
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
    }
    if (checkIfNum) {
      e.preventDefault();
      return;
    }
  
    // Additional logic to allow only up to two decimal places
    const { value, selectionStart } = e.target;
  
    // Allow only one decimal point
    if (e.key === '.' && value.includes('.')) {
      e.preventDefault();
      return;
    }
  
    // Check if input is valid with up to two decimal places
    const parts = value.split('.');
    if (parts.length === 2 && parts[1].length >= 2 && selectionStart > value.indexOf('.')) {
      e.preventDefault();
      return;
    }
  }
  