import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Grid, Button, Box} from "@mui/material";
import { getTaxFillingData } from "./selectors/TaxFilingPlanSelector";
import { useSelector, useDispatch } from "react-redux";
import { getTaxFilingPlan,  resetTaxInfo,  setNewPlan,} from "../../redux/actions/taxFilingPlanAction";
import { clearBusinessInfo } from "../../redux/actions/BusinessInfoAction";
import { clearLocationInfo } from "../../redux/actions/LocationInfoAction";
import { clearAssetsForm } from "../../redux/actions/AssetsInfoActions";
import { clearSuppliesForm } from "../../redux/actions/SuppliesInfoActions";
import { resetAdditionalBusinessInfo } from "../../redux/actions/AdditionalBusinessInfoActions";
import { resetReditionForm } from "../../redux/actions/RenditionAction";
import { getClientDetails, getModalState } from "./AppClientSelectors";
import { getIsProfileComplete } from "./AppClientSelectors";
import { getDirectClientInfoId } from "../profile/userProfile/UserProfileSelectors";
import { getAgentProfile } from "../profile/agentProfile/AgentProfileSelectors";
import { getTaxFilingPlans } from "../../redux/actions/taxFilingPlanAction";
import { resetInventory } from "../../redux/actions/InventoryInfoActions";
import { DataGrid } from "@mui/x-data-grid";
import { PRIMARY_COLOR } from "../../constants/colorScheme";
import { makeStyles } from "@mui/styles";
import { fetchBusinessData, resetBusinessData } from "../../redux/actions/BusinessDataFetchAction";
// import { clearLeasedAssetsForm } from "../../redux/actions/LeasedAsestAction";
// import { getIsClientAuthenticated } from "../home/login/LoginSelectors";
// import {resetTaxInfo} from "../../redux/actions/taxInfoAction";
//<=========================================IMPORTS END=========================================\\

//<=========================================INTERNAL STYLES=========================================\\
const breakpoints={ sm:600, md:960, lg:1280,};
const useStyles = makeStyles(() => ({
  root              : {   flexGrow: 1,},
  modalContainer    : {   top: "100px",},
  icon              : {   fill: "yellowgreen",},
  chipText          : {   color: PRIMARY_COLOR,},
  stepperIconActive : {   color: "#12395b !important",},
  chipStyle         : {   border: "1px solid" + PRIMARY_COLOR,},
  homeContainer     : {   color: "#2e415c", backgroundColor: "white", padding: "30px",},
  homeContainerBlur : {   color: "#2e415c", backgroundColor: "transparent", filter: "blur(12px)", padding: "30px",},
  paneRoot          : {   position: "absolute", top: "20%", left: "50%",  transform: "translate(-50%, -50%)", width: "80%",},
  check             : {   marginBottom: "6px",  color: PRIMARY_COLOR,},
  arrowStyle        : {   top: "-5px;", left: "175px",  position: "relative",},
}));
//<=========================================INTERNAL STYLES END=========================================\\

//<========================== MAIN FUNCTION STARTS ===================================>\\
export default function HistoryScreen({ firstName }) {
  //<========================== STATES & VARIABLES DECLARATIONS===================================>\\
  const classes=useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const agentProfile = useSelector(getAgentProfile);
  const modalState = useSelector(getModalState);
  const clientDetails = useSelector(getClientDetails);
  const isProfileComplete = useSelector(getIsProfileComplete);
  const directClientInfoId = useSelector(getDirectClientInfoId);
  const taxFilingPlanData = useSelector(getTaxFillingData);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let rowDataService = [];
//<========================== STATES & VARIABLES DECLARATIONS===================================>\\

//<========================== GETTING TAXFILLING PLANS ON ONLOAD===================================>\\
  useEffect(() => {
  if (directClientInfoId) {
      dispatch(getTaxFilingPlans(directClientInfoId, null, "history"));
    } else if (agentProfile && agentProfile.id) {
      dispatch(getTaxFilingPlans(null, agentProfile.id, "history"));
    }
  }, [isProfileComplete, agentProfile, directClientInfoId,dispatch]);
  //<========================== GETTING TAXFILLING PLANS ON ONLOAD END ==============================>\\
  

  //<========================== RESETTING THE BUNISNESS PLANS STATES ==============================>\\
  useEffect(() => {
    dispatch(resetTaxInfo());                           //RESET THE TAXINFO
    dispatch(clearBusinessInfo());                     //RESET THE BUSINESS INFO
    dispatch(clearLocationInfo());                    //RESET THE LOCATION INFO
    dispatch(clearAssetsForm());                     //RESET THE ASSETS FORM
    dispatch(clearSuppliesForm());                  //RESET THE SUPPLIES FORM
    dispatch(resetAdditionalBusinessInfo());       //RESET THE ADDITIONAL BUSINES INFO
    dispatch(resetReditionForm());                //RESET THE REDITION FORM
    dispatch(resetInventory());                  //RESET THE INVENTORY
    // dispatch(clearLeasedAssetsForm)          // RESET THE LEASED ASSETS FORM
    // dispatch(resetTaxInfo());               //RESET THE TAX FILING PLAN INFO REDUCER
    dispatch(resetBusinessData());            //Business Data Fetch data reset
  }, [dispatch]);
//<========================== RESETTING THE BUNISNESS PLANS STATES END ==============================>\\

//<========================== HANDLING THE SCREENSIZE FOR AUTO RESPONSIVES ==============================>\\
useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const getColumnWidth = () => {
    if (screenWidth < breakpoints.sm) {
      return screenWidth / 3; 
    } else if (screenWidth < breakpoints.lg) {
      return screenWidth / 5; 
    } else {
      return Math.max(296.2, screenWidth / 9); 
    }
  };
  //<========================== HANDLING THE SCREENSIZE FOR AUTO RESPONSIVES END==============================>\\

  //<========================== TABLE HEADING FOR DATATABLES==============================>\\
  const columns =[
    {      field: "slno",           headerName: "SL.No",            width: 130,                 headerAlign: "center",  align: "center", disableColumnMenu: true,},
    {      field: "county",         headerName: "County",           width: 160,                 headerAlign: "center",  align: "center", disableColumnMenu: false,},
    {      field: "propertyid",     headerName: "Property ID",      width: 150,                 headerAlign: "center",  align: "center", disableColumnMenu: false,},
    {      field: "businessName",   headerName: "Business Name",    width: getColumnWidth(),    headerAlign: "center",  renderCell: (params) => (<div style={{ paddingLeft: '10px' }}>{params.value}</div>), },
    {      field: "taxYear",        headerName: "Tax Year",         width: getColumnWidth(),    headerAlign: "center",  align: "center", },
    {      field: "status",         headerName: "Status",           width: 150,                 headerAlign: "center",  align: "center", },
    {      field: "lastVisitedPage",headerName: "Last Visited Page",width: getColumnWidth(),    headerAlign: "center",  align: "center", },
    {      field: "action",         headerName: "Take Action",      width: getColumnWidth(),    headerAlign: "center",  align: "center", disableColumnMenu: true, sortable: false, searchable: true,
            renderCell: (params) => (
              <Button 
                disabled={params.row.status !== "ACTIVE"} 
                onClick={() => onSubmit(params.row.id)} 
                variant="contained" size="small">
                {params.row.status === "ACTIVE" ? "Continue" : "View"} 
              </Button>
            ),
    },
  ];
//<========================== TABLE HEADING FOR DATATABLES END ==============================>\\

//<========================== RETRIVING ROWS DATA AND CALLING ==============================>\\
  const renderData = () => {
    let taxFilingData = [];
    if (taxFilingPlanData && taxFilingPlanData.taxFilingPlans) {
        taxFilingData = taxFilingPlanData.taxFilingPlans.taxfilingPlans;
      if (taxFilingData) {
          rowDataService = [];
      }
      taxFilingData && taxFilingData.forEach((data, index) => {
          return rowDataService.push({
              id              : data.id,
              slno            : index + 1,
              county          : data.county,
              propertyid      : data.propertyId,
              businessInfoId  : data.businessInfoId,
              businessName    : data.businessName,
              lastVisitedPage : data.lastVisitedPage,
              locationInfoId  : data.locationInfoId,
              status          : data.status,
              taxYear         : data.taxYear,
          });
        });
    }
    return rowDataService;
  };

  // let rows = renderData();
  const rows = useMemo(() => renderData(taxFilingPlanData), [taxFilingPlanData]);
//<========================== RETRIVING ROWS DATA AND CALLING END ==============================>\\

//<========================== ONSUBMIT FUNCTION ==============================>\\
  const onSubmit = (taxFilingPlanId) => {
      dispatch(getTaxFilingPlan(taxFilingPlanId));
      const matchingTaxFilingPlan = taxFilingPlanData.taxFilingPlans.taxfilingPlans.find(plan => plan.id === taxFilingPlanId);
      dispatch(fetchBusinessData(matchingTaxFilingPlan.county,matchingTaxFilingPlan.propertyId));
    history.push("/appClientHome");
  };
//<========================== ONSUBMIT FUNCTION END ==============================>\\

//<========================== APPLICATION VIEW ==============================>\\
  return (
   <Fragment>
      {/* <form onSubmit={handleSubmit(onSubmit)} > */}
      <Grid container className= { modalState === false ? classes.homeContainer : classes.homeContainerBlur }>
        <Grid item container xs={12} px={4} py={2} fontSize={20}>
          Welcome Back&nbsp;<span style={{ color: "#1976D2", fontWeight: "bold" }}>{!firstName ? clientDetails.firstName : firstName} </span> &nbsp;!&nbsp;
          You were working on below tax filings earlier, click on the continue button to complete it or click Get Started button to start new tax filing.
        </Grid>
        <Grid item xs={12} px={4}>
          <DataGrid 
              sx={{
                '& .MuiDataGrid-row'                    : { fontSize:"medium", fontFamily: '"Segoe UI Emoji"',},
                '& .MuiDataGrid-columnHeaders'          : { fontWeight:'bold', borderBottom: '2px solid #1976D2', backgroundColor: '#E0F4FF', fontSize:'large',color:'#1976D2', },
                '& .MuiDataGrid-columnHeaderTitle'      : { fontWeight:'bold'},
                '& .MuiDataGrid-columnHeader'           : { fontWeight: 'bold',},
                '& .MuiTablePagination-selectIcon'      : { color:'#1976D2'},
                '& .MuiTablePagination-selectLabel, & .MuiTablePagination-select': {fontSize: '1rem', },
                  }}
              hideFooterSelectedRowCount  = {true}
              ignoreDiacritics            = {true}
              rows                        = {rows}
              columns                     = {columns}
              pageSizeOptions             = {[5, 10, 25, 50, 100]}
              initialState={{
                pagination: {paginationModel: { page: 0, pageSize: 10 },},
              }}
              // checkboxSelection
              // disableSelectionOnClick
            />
          </Grid>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 3, py: 2 }} style={{ marginRight: "0", marginLeft: "auto", display: "block" }}>
            <Box sx={{ flex: "1 1 auto" }} />
              <Button 
                variant     = "contained"
                size        = "medium"
                type        = "button"
                sx          = {{ mr: 1, width: "200px", height: "50px" }}
                style       = {{ float: "right" }}
                onClick     = {(e) => { dispatch(setNewPlan()); history.push("/appClientHome");}}> Get Started
              </Button>
          </Box>
        </Grid>
    </Fragment>
  );
}
//<========================== MAIN FUNCTION START END ===================================>\\
