import { uspsvalidator } from "../../util/uspsConfig";
import { OPERATING_STATES } from "../../constants/operatingStates";
import { getLocationInfoFromLocId, postLocationInfo, updateLocationInfoFromLocId } from "../../api/LocationInfoApi";
import { countyValuesFromCity } from "../../api/CodeValuesApi";
import { loadLocsOfBusiness } from "../../components/appClient/AppClientActions";
import { putTaxInfo } from './taxInfoAction';
import { setAppServiceInfo } from './AppServiceInfoAction';
import { NotificationManager } from "react-notifications";

const { logger } = require('../../logger');

export const SET_LOCATION_INFO = "SET_LOCATION_INFO";
export const SET_LOCATION_INFO_SUCCESS = "SET_LOCATION_INFO_SUCCESS";
export const SET_LOCATION_INFO_ERROR = "SET_LOCATION_INFO_ERROR";

export const GET_LOCATION_INFO = "GET_LOCATION_INFO";
export const GET_LOCATION_INFO_SUCCESS = "GET_LOCATION_INFO_SUCCESS";
export const GET_LOCATION_INFO_FAILURE = "GET_LOCATION_INFO_FAILURE";

export const FETCH_LOCATION_CITY_STATE = 'FETCH_LOCATION_CITY_STATE';
export const FETCH_LOCATION_CITY_STATE_SUCCESS = 'FETCH_LOCATION_CITY_STATE_SUCCESS';
export const FETCH_LOCATION_CITY_STATE_FAILURE = 'FETCH_LOCATION_CITY_STATE_FAILURE';

export const FETCH_COUNTY_INFO = 'FETCH_COUNTY_INFO';
export const FETCH_COUNTY_INFO_SUCCESS = 'FETCH_COUNTY_INFO_SUCCESS';
export const FETCH_COUNTY_INFO_FAILURE = 'FETCH_COUNTY_INFO_FAILURE';

export const LOCATION_RESET = 'LOCATION_RESET';

export const SET_UPDATE_LOCATION_INFO = 'SET_UPDATE_LOCATION_INFO';
export const SET_UPDATE_LOCATION_INFO_SUCCESS = 'SET_UPDATE_LOCATION_INFO_SUCCESS';
export const SET_UPDATE_LOCATION_INFO_FAILURE = 'SET_UPDATE_LOCATION_INFO_FAILURE';

export const SET_PARTIAL_LOCATION_INFO_SUCCESS = 'SET_PARTIAL_LOCATION_INFO_SUCCESS';
export const SET_LOCATION_ADDRESS_INFO = 'SET_LOCATION_ADDRESS_INFO';
export const SET_LOCATION_ADDRESS_MODAL_STATE = 'SET_LOCATION_ADDRESS_MODAL_STATE';

export const submitLocationInfo = (payload,isPageSubmitted,lastVisitedPage) => {
    
    let address = {
        city: payload.city,
        line1: payload.line1,
        line2: payload.line2,
        state: payload.state,
        zipcode: payload.zipcode,
    }

    let actPayload = {
        ...payload,address,county:payload.county
    }

    return (dispatch, getState) => {
        if (getState().locationInfo.addressSelected) {
            locationServiceLogic(dispatch,payload,actPayload,isPageSubmitted,lastVisitedPage);
        } else {
            // dispatch(verifyAddress(payload,isPageSubmitted,lastVisitedPage));
            locationServiceLogic(dispatch,payload,actPayload,isPageSubmitted,lastVisitedPage);
        }
    }
}

const locationServiceLogic  = (dispatch,payload,actPayload,isPageSubmitted,lastVisitedPage) => {
    if (payload.id && payload.id !== 0) {
        dispatch(updateLocationInfo(payload.id, payload,isPageSubmitted,lastVisitedPage));
        // dispatch(putTaxInfo(payload.taxInfoId,payload.businessInfoId,payload.id,"LocationInfo",true));
    } else {
        postLocationInfo({ ...actPayload }).then((response) => {
            if (response.status === 200) {
                logger.info(`successfully posted LocationInfo`);
                // dispatch(loadLocsOfBusiness(currBusiness));
                dispatch({ type: SET_LOCATION_INFO_SUCCESS, payload: {...payload,id:response.data.locationInfoId} });
                dispatch(putTaxInfo(payload.taxInfoId,payload.businessInfoId,response.data.locationInfoId,lastVisitedPage,true));
                // dispatch(setAppServiceInfo(response.status,"Location Info successfully submitted",false,isPageSubmitted));
            }
        }).catch((err) => {
            logger.error(`Failed to post LocationInfo from LocationInfoActions, error : -> ${err}`);
            NotificationManager.error('',"Failed, Please Fill all Mandatory Fields",5000);
            dispatch({ type: SET_LOCATION_INFO_ERROR, payload: {...payload,error:err} });
            dispatch(setAppServiceInfo(err.response.status,"Location Info error",true,isPageSubmitted));
        });   
    }
}

export const verifylocationInfoZipCode = (locationInfoData,zip) => {
    return (dispatch) => {
        dispatch({ type: FETCH_LOCATION_CITY_STATE, payload: zip});
        setTimeout( () => {
            // call to fetch the city and state values from zipcode
            uspsvalidator.cityStateLookup(zip).then((res) => {
                if(res.State && res.City){
                    if (OPERATING_STATES.includes(res.State)) {
                        dispatch({ type: FETCH_COUNTY_INFO });
                        countyValuesFromCity(res.City).then((response) => {
                            if (response.data.length >= 1) {
                                logger.info(`Successfully verified Location Info for the Zipcode:${zip}`);
                                let county = response.data[0].cdfMeaning;
                                dispatch({ type: FETCH_COUNTY_INFO_SUCCESS, payload: county });
                                dispatch({ type: FETCH_LOCATION_CITY_STATE_SUCCESS, payload: {...locationInfoData,city:res.City,
                                state: res.State,zipcode:res.Zip5,county:county } });
                            }
                            else {
                                logger.error(`We are not serving this area (County Failure) at this particular moment for the zipcode:${zip}`);
                                NotificationManager.error('Currently we are not serving this in area!!')
                                dispatch({ type: FETCH_COUNTY_INFO_FAILURE, payload: {...locationInfoData} });
                            }
                        })
                    }
                    else {
                        logger.error(`We are not serving this area(City, State Failure) at this particular moment for the zipcode:${zip}`);
                        NotificationManager.error('','Failed to rerieve the City, Sate, Please Enter Valid ZipCode');
                        dispatch({ type: FETCH_LOCATION_CITY_STATE_FAILURE, payload: {...locationInfoData}});
                    }
                   
                }
                else{
                    logger.error(`Failed to retrieve the City, State value for the zipcode:${zip}`);
                    dispatch({ type: FETCH_LOCATION_CITY_STATE_FAILURE, payload: res});
                }
            }).catch((err) => {
                logger.error(`Failed to retrieve the City, State value for the zipcode:${zip},error:${err}`);
                NotificationManager.error(err.message,'Failed to retrieve the City, State',5000);
                dispatch({ type: FETCH_LOCATION_CITY_STATE_FAILURE, payload: err});
            })
        }, 2000 )
    }
}

export const getSelectedLocationInfo = () => {
    return (dispatch, getState) => {
        dispatch({type: SET_UPDATE_LOCATION_INFO})
        let locationId = getState().appClientCtx.currLocation;
        getLocationInfoFromLocId(locationId).then((response) => {
            logger.info(`Successfully retrieved LocationInformation using locationKId:${locationId}`);
            let data = response.data;
            let parsedData = {
                'propertyDescription': data.propertyDescription,
                'line1': data.address.line1,
                'line2': data.address.line2,
                'totalSqft': data.totalSqft,
                'state': data.address.state,
                'city': data.address.city, 
                'county': data.county,
                'zipcode': data.address.zipcode,
                'countyPropertyId': data.countyPropertyId,
                'id': data.id
            }
            dispatch({ type: SET_UPDATE_LOCATION_INFO_SUCCESS, payload: parsedData });
        }).catch((err) => {
            logger.error(`Failed to retrieve location Information for the locationId:${locationId},error:${err}`);
            NotificationManager.error(err.message,"Failed to retrieve Location Information", 5000);
            dispatch({ type: SET_UPDATE_LOCATION_INFO_FAILURE, payload: [] });
        });
    }
    
}

export const getLocationInfo = (locationId) => {
    return (dispatch) => {
        dispatch({type: GET_LOCATION_INFO})
        if(locationId && locationId !== "" && locationId !== null && locationId !== undefined) {
        getLocationInfoFromLocId(locationId).then((response) => {
            logger.info(`Successfully retrieved LocationInformation using locationKId:${locationId}`);
            let data = response.data;
            let parsedData = {
                'propertyDescription': data.propertyDescription,
                'line1': data.address.line1,
                'line2': data.address.line2,
                'totalSqft': data.totalSqft,
                'state': data.address.state,
                'city': data.address.city, 
                'county': data.county,
                'zipcode': data.address.zipcode,
                'countyPropertyId': data.countyPropertyId,
                id: locationId
            }
            dispatch({ type: GET_LOCATION_INFO_SUCCESS, payload: parsedData });
        }).catch((err) => {
            logger.error(`Failed to retrieve location Information for the locationId:${locationId},error:${err}`);
            NotificationManager.error(err.message,"Failed to retrieve Location Information",5000);
            dispatch({ type: GET_LOCATION_INFO_FAILURE, payload: [] });
        });
    }
    }
    
}


export const updateLocationInfo = (locationId, locationInfo,isPageSubmitted,lastVisitedPage) => {
    return (dispatch, getState) => {
        let updatePayload = {
            'propertyDescription': locationInfo.propertyDescription,
            address: {
                'line1': locationInfo.line1, 
                'line2': locationInfo.line2,
                'zipcode': locationInfo.zipcode,
                'state': locationInfo.state,
                'city': locationInfo.city
            },
            'totalSqft': locationInfo.totalSqft,
            'county': locationInfo.county,
            'countyPropertyId': locationInfo.countyPropertyId
        }
        updateLocationInfoFromLocId(locationId, updatePayload).then((response) => {
            if (response.status === 200) {
                logger.info(`Successfully updated Location information using locationId:${locationId}`);
                let currBusiness = getState().appClientCtx.currBusiness;
                let parsedData = {
                    'propertyDescription': updatePayload.propertyDescription,
                    'line1': updatePayload.address.line1,
                    'line2': updatePayload.address.line2,
                    'totalSqft': updatePayload.totalSqft,
                    'state': updatePayload.address.state,
                    'city': updatePayload.address.city, 
                    'county': updatePayload.county,
                    'zipcode': updatePayload.address.zipcode,
                    'countyPropertyId': updatePayload.countyPropertyId,
                    id:locationId
                    
                }
                dispatch({ type: SET_LOCATION_INFO_SUCCESS, payload: parsedData });
                dispatch(loadLocsOfBusiness(currBusiness));
                dispatch(putTaxInfo(locationInfo.taxInfoId,locationInfo.businessInfoId,locationId,lastVisitedPage,true));
                // dispatch(setAppServiceInfo(response.status,"Location Info successfully submitted",false,isPageSubmitted));
            }
        }).catch((err) => {
            logger.error(`Failed to update location Information for the locationId:${locationId},error:${err}`);
            NotificationManager.error(err.message,"Failed to update location Information",5000);
            dispatch({ type: SET_LOCATION_INFO_ERROR, payload: {...updatePayload,error:err} });
            dispatch(setAppServiceInfo(err.response.status,"Location Info error",true,isPageSubmitted));
        });
    }
}


export const clearLocationInfo = () => {
    return (dispatch) => {
        dispatch({type: LOCATION_RESET})
    }
}

export const verifyAddress = (payload,isPageSubmitted,lastVisitedPage) => {
    const addressObj = {
        Address1: payload.line1,
        Address2: payload.line2,
        City: payload.city,
        State: payload.state,
        Zip5: payload.zipcode
    }
        return (dispatch, getState) => {
        uspsvalidator.verify(addressObj).then((response) => {
            let line1 = false;
            let line2 = false;
            if (response.Address1!==undefined) {
                if (response.Address1 === addressObj.Address1) {
                    line1 = true;
                }
            }
            if (response.Address2!==undefined) {
                if (response.Address2 === addressObj.Address2) {
                    line2 = true;
                }
            }
            if (line1 || line2) {
                let address = {
                            line1: payload.line1,
                            line2: payload.line2,
                            zipcode: payload.zipcode,
                            state: payload.state,
                            city: payload.city
                        }
                
                let actPayload = {
                    ...payload,address,county:payload.county
                }
                locationServiceLogic(dispatch,payload,actPayload,isPageSubmitted,lastVisitedPage);
            } else {
                let addressPayload = {
                    ...payload, 
                    uspsAddress: response
                }
                dispatch({ type: SET_PARTIAL_LOCATION_INFO_SUCCESS, payload: addressPayload });
                dispatch(setLocationAddressModalState(true));
            }
        }).catch((err) => {
            NotificationManager.error('',"Entered Address is Invalid!!")
            dispatch({ type: SET_LOCATION_INFO_ERROR,  payload: {...payload} });
            dispatch(setAppServiceInfo(400,"Address Info error",true,isPageSubmitted));
        })
    }
}

export const setAddressForLocation = (payload, addressType) => {
    return (dispatch) => {
        let addressPayload = '';
        if (addressType === 'userAddress') {
           addressPayload = {
                mailingAddressLine1: payload.line1,
                mailingAddressLine2: payload.line2
            }
        } else {
            addressPayload = {
                mailingAddressLine1: payload.Address1, 
                mailingAddressLine2: payload.Address2 === undefined ? '': payload.Address2
            }
            
        }
        dispatch({ type: SET_LOCATION_ADDRESS_INFO, payload: addressPayload })
        dispatch(setLocationAddressModalState(false));
       
    }
}

export const setLocationAddressModalState = (flag) => {
    return (dispatch) => {
        dispatch({ type: SET_LOCATION_ADDRESS_MODAL_STATE, payload: flag });
    }
}