const {
  BUSINESS_DATA_FETCH,
  RESET_BUSINESS_DATA_FETCH,
  FETCH_BUSINESS_DATA_FAILURE,
} = require("../actions/BusinessDataFetchAction");

const defaultState = {
  businessData: {
    County: "",
    PROP_ID: "",
    PROP_VAL_YR: "",
    STATE_CD: "",
    STATE_CD_DESC: "",
    PROP_TYPE_CD: "",
    PROPERTY_USE_CD: "",
    PROPERTY_USE_DESC: "",
    SITUS_NUM: "",
    SITUS_STREET_PREFX: "",
    SITUS_STREET: "",
    SITUS_STREET_SUFIX: "",
    SITUS_CITY: "",
    SITUS_ZIP: "",
    LEGAL_DESC: "",
    OWNER_ID: "",
    OWNER: "",
    DBA: "",
    ADDR_LINE1: "",
    ADDR_LINE2: "",
    ADDR_LINE3: "",
    ADDR_CITY: "",
    ADDR_STATE: "",
    ADDR_ZIP: "",
    AGENT_NAME: "",
    AGENT_ID: "",
    EXEMPTIONS: "",
    MARKET: "",
    ASSESSED_VAL: "",
    APPRAISED_VAL: "",
    ENTITIES: "",
  },
  error: null,
};

const businessDataInfoReducer = (state = defaultState, action) => {
  switch (action.type) {
    case BUSINESS_DATA_FETCH:
      console.log("business DAta: ", action.payload);
      return { ...state, businessData: action.payload };
    case FETCH_BUSINESS_DATA_FAILURE:
      return { ...state, error: action.payload };

    case RESET_BUSINESS_DATA_FETCH:
      return defaultState;
    default:
      return state;
  }
};

export default businessDataInfoReducer;
