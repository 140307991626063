import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFormState } from "react-hook-form";
import { Grid, Box, Divider, Typography, Autocomplete } from "@mui/material";
import {
  postTaxInformation,
  putTaxInfo,
} from "../../../redux/actions/taxInfoAction";
import { makeStyles } from "@mui/styles";
import { getClientDetails } from "../AppClientSelectors";
import { PRIMARY_COLOR } from "../../../constants/colorScheme";
import { getDirectClientInfoId } from "../../profile/userProfile/UserProfileSelectors";
import { getAgentProfile } from "../../profile/agentProfile/AgentProfileSelectors";
import { useEffect } from "react";
import { getTaxFillingData } from "../selectors/TaxFilingPlanSelector";
import { dispatchSavedPlanData } from "../../../util/utilities";
import { setAppServiceInfo } from "../../../redux/actions/AppServiceInfoAction";
import { getTaxInfo } from "../selectors/TaxInfoSelector";
// Business Data Fetch imports
import {
  getBsuinessDataAndError,
  getBusinessData,
} from "../selectors/BusinessDataFetchSelector";
import {
  fetchBusinessData,
  resetBusinessData,
} from "../../../redux/actions/BusinessDataFetchAction";
import { setModalState } from "../AppClientActions";
import DuplicateTaxModal from "./ModalContentScreen";
import { NotificationManager } from "react-notifications";

const counties = [
  { title: "Anderson" },
  { title: "Andrews" },
  { title: "Angelina" },
  { title: "Aransas" },
  { title: "Archer" },
  { title: "Armstrong" },
  { title: "Atascosa" },
  { title: "Austin" },
  { title: "Bailey" },
  { title: "Bandera" },
  { title: "Bastrop" },
  { title: "Baylor" },
  { title: "Bee" },
  { title: "Bell" },
  { title: "Bexar" },
  { title: "Blanco" },
  { title: "Borden" },
  { title: "Bosque" },
  { title: "Bowie" },
  { title: "Brazoria" },
  { title: "Brazos" },
  { title: "Brewster" },
  { title: "Briscoe" },
  { title: "Brooks" },
  { title: "Brown" },
  { title: "Burleson" },
  { title: "Burnet" },
  { title: "Caldwell" },
  { title: "Calhoun" },
  { title: "Callahan" },
  { title: "Cameron" },
  { title: "Camp" },
  { title: "Carson" },
  { title: "Cass" },
  { title: "Castro" },
  { title: "Chambers" },
  { title: "Cherokee" },
  { title: "Childress" },
  { title: "Clay" },
  { title: "Cochran" },
  { title: "Coke" },
  { title: "Coleman" },
  { title: "Collin" },
  { title: "Collingsworth" },
  { title: "Colorado" },
  { title: "Comal" },
  { title: "Comanche" },
  { title: "Concho" },
  { title: "Cooke" },
  { title: "Coryell" },
  { title: "Cottle" },
  { title: "Crane" },
  { title: "Crockett" },
  { title: "Crosby" },
  { title: "Culberson" },
  { title: "Dallam" },
  { title: "Dallas" },
  { title: "Dawson" },
  { title: "Deaf Smith" },
  { title: "Delta" },
  { title: "Denton" },
  { title: "DeWitt" },
  { title: "Dickens" },
  { title: "Dimmit" },
  { title: "Donley" },
  { title: "Duval" },
  { title: "Eastland" },
  { title: "Ector" },
  { title: "Edwards" },
  { title: "El Paso" },
  { title: "Ellis" },
  { title: "Erath" },
  { title: "Falls" },
  { title: "Fannin" },
  { title: "Fayette" },
  { title: "Fisher" },
  { title: "Floyd" },
  { title: "Foard" },
  { title: "Fort Bend" },
  { title: "Franklin" },
  { title: "Freestone" },
  { title: "Frio" },
  { title: "Gaines" },
  { title: "Galveston" },
  { title: "Garza" },
  { title: "Gillespie" },
  { title: "Glasscock" },
  { title: "Goliad" },
  { title: "Gonzales" },
  { title: "Gray" },
  { title: "Grayson" },
  { title: "Gregg" },
  { title: "Grimes" },
  { title: "Guadalupe" },
  { title: "Hale" },
  { title: "Hall" },
  { title: "Hamilton" },
  { title: "Hansford" },
  { title: "Hardeman" },
  { title: "Hardin" },
  { title: "Harris" },
  { title: "Harrison" },
  { title: "Hartley" },
  { title: "Haskell" },
  { title: "Hays" },
  { title: "Hemphill" },
  { title: "Henderson" },
  { title: "Hidalgo" },
  { title: "Hill" },
  { title: "Hockley" },
  { title: "Hood" },
  { title: "Hopkins" },
  { title: "Houston" },
  { title: "Howard" },
  { title: "Hudspeth" },
  { title: "Hunt" },
  { title: "Hutchinson" },
  { title: "Irion" },
  { title: "Jack" },
  { title: "Jackson" },
  { title: "Jasper" },
  { title: "Jeff Davis" },
  { title: "Jefferson" },
  { title: "Jim Hogg" },
  { title: "Jim Wells" },
  { title: "Johnson" },
  { title: "Jones" },
  { title: "Karnes" },
  { title: "Kaufman" },
  { title: "Kendall" },
  { title: "Kenedy" },
  { title: "Kent" },
  { title: "Kerr" },
  { title: "Kimble" },
  { title: "King" },
  { title: "Kinney" },
  { title: "Kleberg" },
  { title: "Knox" },
  { title: "La Salle" },
  { title: "Lamar" },
  { title: "Lamb" },
  { title: "Lampasas" },
  { title: "Lavaca" },
  { title: "Lee" },
  { title: "Leon" },
  { title: "Liberty" },
  { title: "Limestone" },
  { title: "Lipscomb" },
  { title: "Live Oak" },
  { title: "Llano" },
  { title: "Loving" },
  { title: "Lubbock" },
  { title: "Lynn" },
  { title: "Madison" },
  { title: "Marion" },
  { title: "Martin" },
  { title: "Mason" },
  { title: "Matagorda" },
  { title: "Maverick" },
  { title: "McCulloch" },
  { title: "McLennan" },
  { title: "McMullen" },
  { title: "Medina" },
  { title: "Menard" },
  { title: "Midland" },
  { title: "Milam" },
  { title: "Mills" },
  { title: "Mitchell" },
  { title: "Montague" },
  { title: "Montgomery" },
  { title: "Moore" },
  { title: "Morris" },
  { title: "Motley" },
  { title: "Nacogdoches" },
  { title: "Navarro" },
  { title: "Newton" },
  { title: "Nolan" },
  { title: "Nueces" },
  { title: "Ochiltree" },
  { title: "Oldham" },
  { title: "Orange" },
  { title: "Palo Pinto" },
  { title: "Panola" },
  { title: "Parker" },
  { title: "Parmer" },
  { title: "Pecos" },
  { title: "Polk" },
  { title: "Potter" },
  { title: "Presidio" },
  { title: "Rains" },
  { title: "Randall" },
  { title: "Reagan" },
  { title: "Real" },
  { title: "Red River" },
  { title: "Reeves" },
  { title: "Refugio" },
  { title: "Roberts" },
  { title: "Robertson" },
  { title: "Rockwall" },
  { title: "Runnels" },
  { title: "Rusk" },
  { title: "Sabine" },
  { title: "San Augustine" },
  { title: "San Jacinto" },
  { title: "San Patricio" },
  { title: "San Saba" },
  { title: "Schleicher" },
  { title: "Scurry" },
  { title: "Shackelford" },
  { title: "Shelby" },
  { title: "Sherman" },
  { title: "Smith" },
  { title: "Somervell" },
  { title: "Starr" },
  { title: "Stephens" },
  { title: "Sterling" },
  { title: "Stonewall" },
  { title: "Sutton" },
  { title: "Swisher" },
  { title: "Tarrant" },
  { title: "Taylor" },
  { title: "Terrell" },
  { title: "Terry" },
  { title: "Throckmorton" },
  { title: "Titus" },
  { title: "Tom Green" },
  { title: "Travis" },
  { title: "Trinity" },
  { title: "Tyler" },
  { title: "Upshur" },
  { title: "Upton" },
  { title: "Uvalde" },
  { title: "Val Verde" },
  { title: "Van Zandt" },
  { title: "Victoria" },
  { title: "Walker" },
  { title: "Waller" },
  { title: "Ward" },
  { title: "Washington" },
  { title: "Webb" },
  { title: "Wharton" },
  { title: "Wheeler" },
  { title: "Wichita" },
  { title: "Wilbarger" },
  { title: "Willacy" },
  { title: "Williamson" },
  { title: "Wilson" },
  { title: "Winkler" },
  { title: "Wise" },
  { title: "Wood" },
  { title: "Yoakum" },
  { title: "Young" },
  { title: "Zapata" },
  { title: "Zavala" },
];

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    width: "90vw",
    [theme.breakpoints.down("sm")]: {
      width: "70vw",
    },
    "& .MuiInputLabel-root": {
      // color: 'red',
      color: PRIMARY_COLOR,
      fontWeight: "bold",
      fontSize: 18,
    },
  },
}));

export default function TaxInfo({ nextButton, saveButton }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, control } = useForm({
    validations: {
      taxYear: { required: { value: true, message: "This field is required" } },
    },
  });
  const { errors } = useFormState({ control });
  const clientDetails = useSelector(getClientDetails);
  const directClientInfoId = useSelector(getDirectClientInfoId);
  const agentProfile = useSelector(getAgentProfile);
  const taxFilingPlanData = useSelector(getTaxFillingData);
  const taxInfoData = useSelector(getTaxInfo);

  const businessData = useSelector(getBusinessData);
  const businessDataError = useSelector(getBsuinessDataAndError);

  let taxFilingPlan = taxFilingPlanData.taxFilingPlan;
  if (taxFilingPlanData && !taxFilingPlanData.isPlanSelected) {
    if (taxInfoData !== null) {
      taxFilingPlan = { ...taxInfoData };
    } else {
      taxFilingPlan = {};
    }
  }
  const [taxInfo, setTaxInfo] = useState({ ...taxFilingPlan });

  const onSubmit = async (data, action) => {
    let taxFilingPlanId = null,
      businessInfoId = null,
      locationInfoId = null,
      lastVisitedPage = null;
    if (taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
      if (taxFilingPlanData.taxFilingPlan) {
        taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
        businessInfoId = taxFilingPlanData.taxFilingPlan.businessInfoId;
        locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
      }
    }
    if (taxFilingPlanId === null || taxFilingPlanId === undefined) {
      taxFilingPlanId = taxInfoData.id;
    }
    let userType = clientDetails.userType;
    if (action === "next") {
      if (userType === "CLIENT" || userType === "BUSINESS-OWNER") {
        lastVisitedPage = "BusinessInfo";
      } else {
        lastVisitedPage = "RepresentationInfo";
      }
    } else if (action === "save") {
      lastVisitedPage = "BasicInfo";
    }
    if (
      taxFilingPlanId !== null &&
      taxFilingPlanId !== undefined &&
      taxFilingPlanId !== ""
    ) {
      // dispatch(putTaxInfo(taxFilingPlanId,businessInfoId,locationInfoId,lastVisitedPage,true,county,propertyId));
      dispatch(
        putTaxInfo(
          taxFilingPlanId,
          businessInfoId,
          locationInfoId,
          lastVisitedPage,
          true,
          data.county,
          data.propertyId
        )
      );
    } else {
      dispatch(
        postTaxInformation(
          data,
          directClientInfoId,
          agentProfile ? agentProfile.id : null,
          userType,
          true,
          lastVisitedPage,
          data.county,
          data.propertyId
        )
      );
    }
  };

  const fetchBusinessName = (county, propertyId) => {
    dispatch(resetBusinessData());
    try {
      // if(taxFilingPlanData.taxFilingPlans){
      //   console.log("<-----------Plan----------->");
      //   console.log(taxFilingPlanData.taxFilingPlans.taxfilingPlans);
      //   const plan=taxFilingPlanData.taxFilingPlans.taxfilingPlans.includes((plan)=>plan.county ===county && plan.propertyId===propertyId);
      //   console.log(taxFilingPlanData.taxFilingPlans.taxfilingPlans.includes(taxFilingPlanData.taxFilingPlans.propertyId===propertyId));
      //   console.log("-------Plan", plan);
      // }
      if (taxFilingPlanData.taxFilingPlans) {
        console.log("<-----------Plan----------->");
        console.log(taxFilingPlanData.taxFilingPlans.taxfilingPlans);
        const isPlanAvailable = taxFilingPlanData.taxFilingPlans.taxfilingPlans.some(plan => 
            plan.county === county && plan.propertyId === propertyId
        );
        const foundPlan = taxFilingPlanData.taxFilingPlans.taxfilingPlans.find(plan => 
            plan.county === county && plan.propertyId === propertyId
        );
        console.log("Plan available:", isPlanAvailable); 
        console.log("Found plan:", foundPlan); 
        if(isPlanAvailable){
          NotificationManager.error(`Given ${county} county with ${propertyId} ID is already having a Business Plan. Please Check your Inputs!`);
          // setTaxInfo((prev) => ({ ...prev, county:"" }));
          setTaxInfo((prev) => ({ ...prev, propertyId:"" }));
        }
        else
        {
          dispatch(fetchBusinessData(county, propertyId));
        }
    }
    } catch (error) {
      console.error(
        "An error occurred while sending data to the server:",
        error
      );
    }
  };

  useEffect(() => {
    if (taxFilingPlanData.isNewPlan && !taxFilingPlanData.isPlanSelected) {
      if (businessData !== null || businessData.OWNER !== null) {
        setValue("businessName", businessData.OWNER);
        setTaxInfo((prevState) => ({
          ...prevState,
          businessName: businessData.OWNER.trim(),
        }));
      } else if (
        !taxFilingPlanData.isNewPlan &&
        taxFilingPlanData.isPlanSelected
      ) {
        setValue("businessName", taxInfo.businessName.trim());
      }
    }
  }, [businessData, setValue]);

  useEffect(() => {
    if (taxFilingPlanData.isPlanSelected && !taxFilingPlanData.isNewPlan) {
      dispatchSavedPlanData(
        dispatch,
        "BasicInfo",
        taxFilingPlanData.taxFilingPlan
      );
    }
    dispatch(setAppServiceInfo(0, null, false, false));
  }, [dispatch]);
  var currentDate = new Date();

  const handleCountyChange = (event, newValue) => {
    setValue("county", newValue ? newValue.title : "");
    setTaxInfo((prev) => ({ ...prev, county: newValue ? newValue.title : "" }));
    console.log("Selected County:", newValue ? newValue.title : "");
  };

  const handlePropertyIdChange = (event) => {
    setValue("propertyId", event.target.value);
    setTaxInfo((prev) => ({ ...prev, propertyId: event.target.value }));
    console.log("Property ID:", event.target.value);
  };

  return (
    <Box
      style={{
        position: "relative",
        left: "2%",
        bottom: "15%",
        textAlign: "justify",
        minHeight: "360px",
      }}
    >
      <form>
        {/* Main Grid */}
        <Grid container direction="column">
          {/* Step Title */}
          <Grid item xs={12} sx={{ m: 1 }}>
            <h1 style={{ textAlign: "center" }}>
              Tax Year
              <Typography
                className={classes.loginTitle}
                style={{ float: "right" }}
              >
                {" "}
                <span style={{ color: "red" }}>*</span> Required fields{" "}
              </Typography>
              <Divider />
            </h1>
          </Grid>

          {/* taxYear Filed  */}
          <Grid item xs={12}>
            <FormControl sx={{ m: 1 }} className={classes.fieldContainer}>
              <InputLabel htmlFor="taxYear">Tax Year</InputLabel>
              <Select
                labelId="taxYear"
                id="taxYear"
                required
                label="taxYear"
                {...register("taxYear", {
                  required: "Please enter your first name.",
                  onChange: (e) => {
                    setTaxInfo({ ...taxInfo, taxYear: e.target.value });
                  },
                })}
                // value={taxInfo.taxYear}
                defaultValue={currentDate.getFullYear() - 1}
              >
                <MenuItem value={currentDate.getFullYear() - 1}>
                  {currentDate.getFullYear() - 1}
                </MenuItem>
              </Select>
            </FormControl>
            {errors.taxYear && <p>Please select Tax Year</p>}
          </Grid>

          {/* county Field*/}
          <Grid item xs={12} sx={{ m: 1, width: "70vw" }}>
            <Autocomplete
              className={classes.fieldContainer}
              disablePortal
              id="county"
              options={counties}
              disabled={
                !taxFilingPlanData.isNewPlan && taxFilingPlanData.isPlanSelected
              }
              sx={{ width: 300 }}
              getOptionLabel={(option) => option.title ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Enter your County"
                  required
                  InputLabelProps={{
                    shrink: true,
                    sx: {
                      "&.Mui-required .MuiInputLabel-asterisk": {
                        color: "red",
                      },
                    },
                  }}
                />
              )}
              onChange={handleCountyChange}
              value={counties.find((c) => c.title === taxInfo.county) || null}
            />
          </Grid>

          {/*propertyId Field  */}
          <Grid item xs={12} sx={{ m: 1, width: "70vw" }}>
            <TextField
              className={classes.fieldContainer}
              label="Enter Your Property ID"
              id="propertyId"
              type="number"
              variant="outlined"
              required
              InputLabelProps={{
                shrink: true,
                sx: {
                  "&.Mui-required .MuiInputLabel-asterisk": { color: "red" },
                },
              }}
              value={taxInfo.propertyId}
              disabled={
                !taxFilingPlanData.isNewPlan && taxFilingPlanData.isPlanSelected
              }
              onChange={handlePropertyIdChange}
              onBlur={() => {
                if (taxInfo.county && taxInfo.propertyId) {
                  fetchBusinessName(taxInfo.county, taxInfo.propertyId);
                } else {
                  {
                    errors.propertyId && <p>Please Enter PropertyId</p>;
                  }
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sx={{ m: 1, width: "70vw" }}>
            <TextField
              id="businessName"
              label="Business Name"
              variant="outlined"
              disabled={
                !taxFilingPlanData.isNewPlan && taxFilingPlanData.isPlanSelected
              }
              required
              {...register("businessName", {
                required: "Please enter Business name",
                maxLength: {
                  value: 50,
                  message: "Business name cannot be longer than 50 characters",
                },
                onChange: (e) => {
                  setTaxInfo({
                    ...taxInfo,
                    businessName: e.target.value.trim(),
                  });
                },
              })}
              className={classes.fieldContainer}
              value={taxInfo.businessName?taxInfo.businessName.trim():""}
              InputLabelProps={{
                shrink: true,
                sx: {
                  "&.Mui-required .MuiInputLabel-asterisk": { color: "red" },
                },
              }}
            />
            {errors.businessName && <p>{errors.businessName.message}</p>}
            {businessDataError && (
              <p style={{ color: "red" }}>{businessDataError}</p>
            )}
            {!taxFilingPlanData.isNewPlan &&
              taxFilingPlanData.isPlanSelected && (
                <input
                  type="hidden"
                  {...register("businessName")}
                  value={taxInfo.businessName.trim()}
                />
              )}
          </Grid>

          {/* nextButton */}
          <input
            type="submit"
            ref={nextButton}
            style={{ display: "none" }}
            onClick={handleSubmit((data) => {
              onSubmit(data, "next");
            })}
          />

          {/* saveButton */}
          <input
            type="submit"
            ref={saveButton}
            style={{ display: "none" }}
            onClick={handleSubmit((data) => {
              onSubmit(data, "save");
            })}
          />
        </Grid>
      </form>
    </Box>
  );
}
