import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import rootReducer from './redux/rootReducer';
import { loadState, saveState } from './sessionStorage';

const history = createBrowserHistory();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let middleWareList = [];

const persistedState = loadState();


if(process.env.NODE_ENV === 'development'){
  middleWareList = [...middleWareList, routerMiddleware(history), thunk,createLogger()];
} else{
  middleWareList = [...middleWareList, routerMiddleware(history), thunk];
}

const middleware = composeEnhancer(applyMiddleware(...middleWareList));


const store = createStore(rootReducer,persistedState, middleware);
  
  store.subscribe(() => {
    saveState(store.getState());
  });

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
        <Route path ="/" name="Home" component={App} />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

export { store };
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
