import React, { Fragment, useRef, useState, useEffect } from "react";
import {
  Grid,
  Fade,
  TextField,
  Button,
  Typography,
  Divider,
  LinearProgress,
  Box,
  Modal,
  Backdrop,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddressSelectedFlag,
  submitProfileDetails,
  submitProfileDetailsForUpdate,
  updateProfileInfo,
  verifyProfileZipCode,
} from "./UserProfileActions";
import {
  validateMailFormat,
  validateNumericFormat,
} from "../../../util/utilities";
import {
  getIsLoadingStateCity,
  getLoadedCity,
  getLoadedState,
  getProfilePayload,
  getAddressModalState,
} from "./UserProfileSelectors";
import {
  getClientDetails,
  getStateValue,
  getCityValue,
  getIsProfileComplete,
} from "../../appClient/AppClientSelectors";
import useStyles, { theme } from "../../../styles/mainPagesCSS";
import { useHistory } from "react-router";
import AddressSuggestionModal from "../../addressSuggestion/AddressSuggestionModal";
import { ThemeProvider } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { formatNumberInput } from "../../../util/utilities";

const UserProfileView = () => {
  const errObjInitialState = {
    nameErr: false,
    phErr: false,
    mailErr: false,
    validationErr: false,
    addressErr: false,
    zipErr: false,
    validZipErr: false,
  };

  const cityValues = useSelector(getCityValue);
  const stateValues = useSelector(getStateValue);

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef(null);
  const clientDetails = useSelector(getClientDetails);
  const [errs, setErrs] = useState(errObjInitialState);
  const profileInitPayload = useSelector(getProfilePayload);
  const [profile, setProfile] = useState({ ...profileInitPayload });
  const loadedCity = useSelector(getLoadedCity);
  const loadedState = useSelector(getLoadedState);
  const isLoadingStateCity = useSelector(getIsLoadingStateCity);
  const isProfileComplete = useSelector(getIsProfileComplete);
  const addressModalState = useSelector(getAddressModalState);
  const [fetchedCity, setFetchedCity] = useState("");
  const [fetchedState, setFetchedState] = useState("");

  const getIdOfValue = (valueType, value) => {
    switch (valueType) {
      case "state":
        const stateId = stateValues
          .filter((item) => item.codeValueKey === value)
          .map((item) => item.id);
        return stateId[0];
      case "city":
        const cityId = cityValues
          .filter((item) => item.codeValueKey === value)
          .map((item) => item.id);
        return cityId[0];
      default:
        return "";
    }
  };

  const clearValues = () => {
    setProfile({
      name: "",
      email: "",
      phone: 0,
      addressLine1: "",
      addressLine2: "",
      zip: 0,
      state: "",
      city: "",
    });
    setErrs({
      nameErr: false,
      phErr: false,
      mailErr: false,
      validationErr: false,
      addressErr: false,
      zipErr: false,
      validZipErr: false,
    });
  };

  const validateZipAndFetchStateCityValues = (val) => {
    if (validateNumericFormat(val)) {
      setProfile({ ...profile, zip: val });
      if (val.length !== 5) {
        setFetchedCity("");
        setFetchedState("");
        setErrs({ ...errs, validZipErr: true, zipErr: false });
      } else {
        setProfile({ ...profile, zip: val });
        setErrs({ ...errs, validZipErr: false, zipErr: false });
        dispatch(verifyProfileZipCode(val));
      }
    } else {
      setProfile({ ...profile, zip: "" });
      setErrs({ ...errs, validZipErr: true, zipErr: false });
    }
  };

  const handleTxt = (evt, txtType) => {
    switch (txtType) {
      case "name":
        setProfile({ ...profile, name: evt.target.value });
        setErrs({ ...errs, nameErr: false });
        break;
      case "phone":
        let mobileNumber = evt.target.value.replace(/[^0-9]/g, "");
        setProfile({ ...profile, phone: evt.target.value });
        if (handleMobileNumberValidation(mobileNumber)) {
          let parsedNumber = mobileNumber;
          if (mobileNumber.length === 10) {
            parsedNumber = mobileNumber.replace(
              /(\d{3})(\d{3})(\d{4})/,
              "($1) $2-$3"
            );
          }
          setProfile({ ...profile, phone: parsedNumber });
          setErrs({ ...errs, phErr: false });
        } else {
          setErrs({ ...errs, phErr: true });
        }
        break;
      case "email":
        setProfile((prevState) => ({ ...prevState, email: evt.target.value }));
        if (validateMailFormat(evt.target.value)) {
          setErrs((prevState) => ({
            ...prevState,
            mailErr: false,
            validationErr: false,
          }));
        } else {
          setErrs((prevState) => ({
            ...prevState,
            validationErr: true,
            mailErr: true,
          }));
        }
        break;
      case "addressL1":
        setProfile({ ...profile, addressLine1: evt.target.value });
        dispatch(setAddressSelectedFlag(false));
        setErrs({ ...errs, addressErr: false });
        break;
      case "addressL2":
        setProfile({ ...profile, addressLine2: evt.target.value });
        break;
      case "zip":
        validateZipAndFetchStateCityValues(evt.target.value);
        break;
      default:
    }
  };

  const handleEmailValidation = (email) => {
    if (validateMailFormat(email)) {
      setErrs((prevState) => ({
        ...prevState,
        mailErr: false,
        validationErr: false,
      }));
    } else {
      setProfile((prevState) => ({ ...prevState, email: "" }));
      setErrs((prevState) => ({
        ...prevState,
        validationErr: true,
        mailErr: true,
      }));
    }
  };
  const handleMobileNumberValidation = (mobileNumber) => {
    return mobileNumber && mobileNumber.length === 10;
  };

  const execScroll = () =>
    formRef.current.scrollIntoView({ behavior: "smooth" });

  const handleSaveNxt = (event) => {
    let error = false;
    handleEmailValidation(profile.email);
    !profile.name || profile.name === ""
      ? setErrs((prevState) => ({ ...prevState, nameErr: true }))
      : setErrs((prevState) => ({ ...prevState, nameErr: false }));
    !profile.email === ""
      ? setErrs((prevState) => ({ ...prevState, mailErr: true }))
      : setErrs((prevState) => ({ ...prevState, mailErr: false }));

    handleMobileNumberValidation(profile.phone)
      ? setErrs((prevState) => ({ ...prevState, phErr: true }))
      : setErrs((prevState) => ({ ...prevState, phErr: false }));
    !profile.addressLine1
      ? setErrs((prevState) => ({ ...prevState, addressErr: true }))
      : setErrs((prevState) => ({ ...prevState, addressErr: false }));
    !profile.zip || profile.zip === 0
      ? setErrs((prevState) => ({ ...prevState, zipErr: true }))
      : setErrs((prevState) => ({ ...prevState, zipErr: false }));
    fetchedCity === "" || fetchedState === ""
      ? setErrs((prevState) => ({ ...prevState, validZipErr: true }))
      : setErrs((prevState) => ({ ...prevState, validZipErr: false }));
    if (
      !profile.email ||
      !profile.phone ||
      !profile.addressLine1 ||
      !profile.zip ||
      !profile.name
    ) {
      error = true;
    }
    if (error) {
      event.stopPropagation();
    }

    if (!error && Object.values(errs).every((item) => item === false)) {
      let userId = clientDetails.userId;
      let state = Number.isInteger(fetchedState)
        ? fetchedState
        : getIdOfValue("state", fetchedState);
      let city = Number.isInteger(fetchedCity)
        ? fetchedCity
        : getIdOfValue("city", fetchedCity);
      isProfileComplete
        ? dispatch(
            submitProfileDetailsForUpdate({ ...profile, userId, city, state })
          )
        : dispatch(submitProfileDetails({ ...profile, userId, city, state }));
      // clearValues();
    } else {
      execScroll();
    }
  };

  const handleCancelClick = () => {
    history.push("/appClientHome");
  };
  useEffect(() => {
    setFetchedCity(loadedCity);
    setFetchedState(loadedState);
  }, [loadedCity, loadedState]);

  // useEffect(() => {
  //     if (!isNaN(loadedCity) && cityValues && loadedCity != "" && loadedCity!=undefined){
  //         if(cityValues && cityValues.size > 0) {
  //             cityValues.forEach( city => {
  //                 if(city.id === loadedCity)
  //                 setFetchedCity(city.codeValueKey);
  //             });
  //         }
  //     } else{
  //         setFetchedCity(loadedCity);
  //     }

  //     if(!isNaN(loadedState) && loadedState != "" && loadedState!=undefined){
  //         if(stateValues && stateValues.size > 0) {
  //             stateValues.forEach( state => {
  //                 if(state.id === loadedState)
  //                 setFetchedState(state.codeValueKey);
  //             });
  //       }
  //     } else {
  //         setFetchedState(loadedState);
  //     }

  // }, [loadedCity, loadedState, cityValues, stateValues]);
  const getValueOfId = (valueType, value) => {
    switch (valueType) {
      case "state":
        const stateCodeValue = stateValues
          .filter((item) => item.id === value)
          .map((item) => item.codeValueKey);
        return stateCodeValue[0];
      case "city":
        const cityCodeValue = cityValues
          .filter((item) => item.id === value)
          .map((item) => item.codeValueKey);
        return cityCodeValue[0];
      default:
        return "";
    }
  };

  return (
    <Fragment>
      <Box>
        <Grid container>
          <Modal
            open={addressModalState}
            hideBackdrop
            disableEscapeKeyDown={true}
            aria-labelledby="app-modal"
            aria-describedby="app-modal-client"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            disableScrollLock={true}
            className={classes.modalRoot}
          >
            <Fade in={addressModalState}>
              <Box>
                <AddressSuggestionModal />
              </Box>
            </Fade>
          </Modal>
        </Grid>
      </Box>
      <Grid
        container
        className={
          addressModalState === true ? classes.paneRootBlur : classes.paneRoot
        }
      >
        <Grid item xs={12}>
          <div className={classes.paneBox}>
            <Grid
              container
              justifyContent={"space-between"}
              style={{ margin: "5px 0" }}
            >
              <Grid item xs={12} className={classes.formInputs}>
                <Typography variant="h4" className={classes.loginTitle}>
                  {" "}
                  My Profile{" "}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Fade in={true} timeout={1500}>
              {/* <div className={classes.scrollableContent}> */}
              <Grid container ref={formRef}>
                <Grid item xs={12} className={classes.formInputs}>
                  {clientDetails && (
                    <TextField
                      required
                      id="name"
                      label="Client Name"
                      variant="standard"
                      className={classes.fields}
                      onChange={(e) => handleTxt(e, "name")}
                      error={errs.nameErr}
                      helperText={errs.nameErr && `Enter Name`}
                      value={profile.name}
                    />
                  )}
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    className={classes.fields}
                    required
                    id="phoneNum"
                    label="Phone Number"
                    variant="standard"
                    error={errs.phErr}
                    type="text" //Changed type number to text
                    inputProps={{ maxLength: 10 }} // added max length
                    // InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleTxt(e, "phone")}
                    onKeyDown={formatNumberInput}
                    helperText={errs.phErr && `Enter phone number`}
                    value={profile.phone === 0 ? "" : profile.phone}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    className={classes.fields}
                    required
                    id="email"
                    label="Email"
                    value={profile.email}
                    variant="standard"
                    type="email"
                    onChange={(e) => handleTxt(e, "email")}
                    error={errs.validationErr || errs.mailErr}
                    helperText={
                      errs.validationErr
                        ? `Enter valid email address`
                        : errs.mailErr
                        ? `Enter registered email address`
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    required
                    id="addressL1"
                    label="Mailing Address Line 1"
                    variant="standard"
                    className={classes.fields}
                    onChange={(e) => handleTxt(e, "addressL1")}
                    error={errs.addressErr}
                    helperText={errs.addressErr && `Enter Address`}
                    value={profile.addressLine1}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formInputs}>
                  <TextField
                    id="addressL2"
                    label="Address Line 2"
                    variant="standard"
                    className={classes.fields}
                    onChange={(e) => handleTxt(e, "addressL2")}
                    value={profile.addressLine2}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} className={classes.formInputs}>
                    <TextField
                      required
                      className={classes.fields}
                      id="zip"
                      label="Zip"
                      variant="standard"
                      type="text"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => handleTxt(e, "zip")}
                      error={errs.zipErr || errs.validZipErr}
                      helperText={
                        errs.zipErr
                          ? `Enter Zip Code`
                          : errs.validZipErr
                          ? `Enter valid Zip code`
                          : null
                      }
                      value={profile.zip}
                    />
                    {isLoadingStateCity && (
                      <LinearProgress style={{ marginTop: "2px" }} />
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.formInputs}>
                    <TextField
                      required
                      id="city"
                      label="City"
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      disabled
                      className={classes.disabledFields}
                      value={
                        Number.isInteger(fetchedCity)
                          ? getValueOfId("city", fetchedCity)
                          : fetchedCity
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.formInputs}>
                    <TextField
                      required
                      id="state"
                      label="State"
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      disabled
                      className={classes.disabledFields}
                      value={
                        Number.isInteger(fetchedState)
                          ? getValueOfId("state", fetchedState)
                          : fetchedState
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.buttonContainer}>
                  <ThemeProvider theme={theme}>
                    <Stack
                      spacing={2}
                      style={{ width: "100%" }}
                      direction="row"
                      justifyContent="right"
                    >
                      <Button
                        color="secondary"
                        variant="contained"
                        size="medium"
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        onClick={handleSaveNxt}
                      >
                        {isProfileComplete ? `Update` : `Submit`}
                      </Button>
                    </Stack>
                  </ThemeProvider>
                  {/* <Grid item xs={12} md={8} lg={4} className={classes.formInputs}>
                                            <Button 
                                                className={classes.cancelBtn} size="medium" color="inherit" onClick={handleCancelClick} >
                                                Cancel 
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md={8} lg={4} className={classes.formInputs}>
                                            <Button 
                                                className={classes.saveBtn} size="medium" color="inherit" onClick={handleSaveNxt} >
                                                {isProfileComplete? `Update` : `Submit`}
                                            </Button>
                                        </Grid> */}
                </Grid>
              </Grid>
              {/* </div> */}
            </Fade>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UserProfileView;
