import { loadBusinesses } from "../../components/appClient/AppClientActions";
import { businessType, ownershipType, sicCodes } from '../../api/AuthApi';
import { uspsvalidator } from "../../util/uspsConfig";
import { OPERATING_STATES } from "../../constants/operatingStates";
import { svcRequest } from "../../util/requestHelper";
import config from "../../config/context-root-configs";
import { getBusinessInfoFromBusinessId } from "../../api/BusinessInfoApi";
import { putTaxInfo } from './taxInfoAction';
import { NotificationManager } from "react-notifications";
import { setAppServiceInfo } from './AppServiceInfoAction';

const { logger } = require('../../logger');

export const SET_BUSINESS_INFO = "SET_BUSINESS_INFO";
export const SET_BUSINESS_INFO_SUCCESS = "SET_BUSINESS_INFO_SUCCESS";
export const SET_BUSINESS_INFO_ERROR = "SET_BUSINESS_INFO_ERROR";

export const FETCH_OWNERSHIP_TYPES = "FETCH_OWNERSHIP_TYPES";
export const FETCH_OWNERSHIP_TYPES_SUCCESS = "FETCH_OWNERSHIP_TYPES_SUCCESS";
export const FETCH_OWNERSHIP_TYPES_FAILURE = "FETCH_OWNERSHIP_TYPES_FAILURE";


export const FETCH_BUSINESS_TYPES = "FETCH_BUSINESS_TYPES";
export const FETCH_BUSINESS_TYPES_SUCCESS = "FETCH_BUSINESS_TYPES_SUCCESS";
export const FETCH_BUSINESS_TYPES_FAILURE = "FETCH_BUSINESS_TYPES_FAILURE";

export const FETCH_SIC_CODES = "FETCH_SIC_CODES"
export const FETCH_SIC_CODES_SUCCESS = "FETCH_SIC_CODES_SUCCESS"
export const FETCH_SIC_CODES_FAILURE = "FETCH_SIC_CODES_FAILURE"

export const FETCH_BUSINESS_CITY_STATE = 'FETCH_BUSINESS_CITY_STATE';
export const FETCH_BUSINESS_CITY_STATE_SUCCESS = 'FETCH_BUSINESS_CITY_STATE_SUCCESS';
export const FETCH_BUSINESS_CITY_STATE_FAILURE = 'FETCH_BUSINESS_CITY_STATE_FAILURE';

export const SET_UPDATE_BUSINESS_INFO = 'SET_UPDATE_BUSINESS_INFO';
export const SET_UPDATE_BUSINESS_INFO_SUCCESS = 'SET_UPDATE_BUSINESS_INFO_SUCCESS';
export const SET_UPDATE_BUSINESS_INFO_FAILURE = 'SET_UPDATE_BUSINESS_INFO_FAILURE';

export const SET_BUSINESS_ADDRESS_INFO = 'SET_BUSINESS_ADDRESS_INFO';
export const SET_PARTIAL_BUSINESS_INFO_SUCCESS = 'SET_PARTIAL_BUSINESS_INFO_SUCCESS';
export const BUSINESS_RESET = 'BUSINESS_RESET';
export const SET_BUSINESS_ADDRESS_MODAL_STATE = 'SET_BUSINESS_ADDRESS_MODAL_STATE';

export const GET_BUSINESS_INFO = "GET_BUSINESS_INFO";
export const GET_BUSINESS_INFO_SUCCESS = "GET_BUSINESS_INFO_SUCCESS";
export const GET_BUSINESS_INFO_FAILURE = "GET_BUSINESS_INFO_FAILURE";
// export const postBusinessInformation = data => {
//     return dispatch => {
//         submitBusinessInfo(dispatch,data);
//         dispatch({ type: GET_BUSINESS_INFO,payload: data });
//     }
// }

export const postBusinessInformation = (payload, isPageSubmitted, lastVisitedPage) => {

    return (dispatch, getState) => {
        if (!getState().businessInfo.addressSelected) {
            // dispatch(verifyBusinessAddress(payload, isPageSubmitted, lastVisitedPage));
            businessServiceLogic(dispatch, payload, getState, isPageSubmitted, lastVisitedPage);
        } else {
            businessServiceLogic(dispatch, payload, getState, isPageSubmitted, lastVisitedPage);
        }

    }
}

export const businessServiceLogic = (dispatch, payload, getState, isPageSubmitted, lastVisitedPage) => {
    let address = {
        city: payload.city,
        line1: payload.mailingAddressLine1,
        line2: payload.mailingAddressLine2,
        state: payload.state,
        zipcode: payload.zip
    };

    if (payload.userType === 'CLIENT' || payload.userType === 'BUSINESS-OWNER') {
        let postPayload = {
            ...payload,
            userType: 'BUSINESS-OWNER',
            directClientInfoId: payload.directClientInfoId,
            ptxContactName: payload.propertyTaxContactPerson,
            address,
            ownershipType: payload.ownershipType,
            // TODO: Remove these as changed page doesnt contain these
            sicCode: 22,
            businessType: 33,
        }
        if (payload.id && payload.id !== 0) {
            patchClientBusinessInfo(postPayload, payload.id).then((response) => {
                logger.info(`Successfully updated Client Business Information`);
                NotificationManager.success('',"Update Successful!!")
                dispatch({ type: SET_BUSINESS_INFO_SUCCESS, payload: postPayload });
                dispatch(loadBusinesses(true, postPayload.directClientInfoId, getState().appClientCtx.taxYr));
                dispatch(putTaxInfo(payload.taxInfoId, payload.id, null, lastVisitedPage, true));
                // dispatch(setAppServiceInfo(response.status,"Business Info successfully submitted",false,isPageSubmitted));
            }).catch((err) => {
                logger.error(`Failed to Update Client business Info for the id: ${payload.id}, error : -> ${err}`);
                NotificationManager.error(err.messae,'Failed to Update Client business Info',5000);
                dispatch({ type: SET_BUSINESS_INFO_ERROR, payload: { ...postPayload, error: err } });
                dispatch(setAppServiceInfo(err.response.status, "Business Info error", true, isPageSubmitted));
            });
        } else {
            postClientBusinessInfo(postPayload).then((response) => {
                if (response) {
                    logger.info(`Successfully posted Client BusinessInfo`);
                    dispatch({ type: SET_BUSINESS_INFO_SUCCESS, payload: { ...postPayload, id: response.data.businessInfoId } });
                    dispatch(putTaxInfo(payload.taxInfoId, response.data.businessInfoId, null, lastVisitedPage, true));
                    // dispatch(setAppServiceInfo(response.status,"Business Info successfully submitted",false,isPageSubmitted));
                }
            }).catch((err) => {
                logger.error(`Failed to Post Client business Info, error : -> ${err}`);
                // Error Capturing
                const errors = err.response?.data?.errors;
                if (errors && Array.isArray(errors)) {
                    const uniqueErrors = {};
                    errors.forEach(error => {
                        if (!uniqueErrors[error.param]) {
                            uniqueErrors[error.param] = error.msg;
                        }
                    });
                    const errorMessage = Object.entries(uniqueErrors).map(([param, msg]) => `- ${msg}`); 
                    NotificationManager.error('Please Complete the OwnerShip Step',"", 5000);
                } else {
                    NotificationManager.error(err.message,'An unexpected error occurred. Please try again.', 5000);
                }
                dispatch({ type: SET_BUSINESS_INFO_ERROR, payload: { ...postPayload, error: err } });
                dispatch(setAppServiceInfo(err.response.status, "Business Info error", true, isPageSubmitted));
            });
        }
    } else {
        let agentPostPayload = {
            ...payload,
            agentInfoId: payload.agenProfileId,
            ptxContactName: payload.propertyTaxContactPerson,
            address,
            ownershipType: payload.ownershipType,
            // TODO: Remove these as changed page doesnt contain these
            sicCode: 22,
            businessType: 33,
        }

        if (payload.id && payload.id !== 0) {
            patchAgentBusinessInfo(agentPostPayload, payload.id).then((response) => {
                logger.info(`Successfully updated Agent business information`);
                dispatch({ type: SET_BUSINESS_INFO_SUCCESS, payload: agentPostPayload });
                dispatch(loadBusinesses(false, agentPostPayload.agentInfoId, getState().appClientCtx.taxYr));
                dispatch(putTaxInfo(payload.taxInfoId, response.data.businessInfoId, null, lastVisitedPage, true));
                // dispatch(setAppServiceInfo(response.status,"Business Info successfully submitted",false,isPageSubmitted));
            }).catch((err) => {
                logger.error(`Failed to update Agent business Info for the id:${payload.id}, error : -> ${err}`);
                 // Error Capturing
                 const errors = err.response?.data?.errors;
                 if (errors && Array.isArray(errors)) {
                     const uniqueErrors = {};
                     errors.forEach(error => {
                         if (!uniqueErrors[error.param]) {
                             uniqueErrors[error.param] = error.msg;
                         }
                     });
                     const errorMessage = Object.entries(uniqueErrors).map(([param, msg]) => `- ${msg}`); 
                     NotificationManager.error('Please Complete the OwnerShip Step',"", 10000);
                 } else {
                     NotificationManager.error(err.messae,'An unexpected error occurred. Please try again.', '', 10000);
                 }
                dispatch({ type: SET_BUSINESS_INFO_ERROR, payload: { ...agentPostPayload, error: err } });
                dispatch(setAppServiceInfo(err.response.status, "Business Info error", true, isPageSubmitted));
            });
        } else {
            postAgentBusinessInfo(agentPostPayload).then((response) => {
                logger.info(`Successfully posted Agent business Information`);
                dispatch({ type: SET_BUSINESS_INFO_SUCCESS, payload: { ...agentPostPayload, id: response.data.businessInfoId } });
                dispatch(loadBusinesses(false, agentPostPayload.agentInfoId, getState().appClientCtx.taxYr));
                dispatch(putTaxInfo(payload.taxInfoId, response.data.businessInfoId, null, lastVisitedPage, true));
                // dispatch(setAppServiceInfo(response.status,"Business Info successfully submitted",false,isPageSubmitted));
            }).catch((err) => {
                logger.error(`Failed to Post Agent business Info, error : -> ${err}`);
                NotificationManager.error(err.messae,"Failed to Post Agent Business Info",5000);
                dispatch({ type: SET_BUSINESS_INFO_ERROR, payload: { ...agentPostPayload, error: err } });
                dispatch(setAppServiceInfo(err.response.status, "Business Info error", true, isPageSubmitted));
            });
        }
    }

}

export const fetchOwnerShipTypes = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_OWNERSHIP_TYPES })
        let types = []
        setTimeout(() => {
            ownershipType().then((response) => {
                let data = response.data
                if (data && response.status === 200) {
                    logger.info(`Successfully fetched Ownershiptypes from BusinessInfoActions`);
                    dispatch({ type: FETCH_OWNERSHIP_TYPES_SUCCESS, payload: data });
                }
                else {
                    logger.error(`Failed to fetch Ownership types from BusinessInfoActions`);
                    dispatch({ type: FETCH_BUSINESS_TYPES_FAILURE, payload: types })
                }
            }).catch((err) => {
                logger.error(`Failed to fetch Ownership types from BusinessInfoActions, error : -> ${err}`);
                dispatch({ type: FETCH_OWNERSHIP_TYPES_FAILURE, payload: types })
            })
        }, 2000)

    }
}

export const fetchBusinessTypes = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_BUSINESS_TYPES })
        setTimeout(() => {
            businessType().then((response) => {
                if (response && response.status === 200) {
                    logger.info(`Successfully fetched business types from BusinessInfoActions`);
                    dispatch({ type: FETCH_BUSINESS_TYPES_SUCCESS, payload: response.data });
                }
                else {
                    logger.error(`Failed to fetch Business types from BusinessInfoActions`);
                    dispatch({ type: FETCH_BUSINESS_TYPES_FAILURE, payload: response.data })
                }
            }).catch((err) => {
                logger.error(`Failed to fetch Business types from BusinessInfoActions, error : -> ${err}`);
                dispatch({ type: FETCH_BUSINESS_TYPES_FAILURE, payload: err })
            })
        }, 2000);

    }
}

export const fetchSicCodes = (payload) => {
    //cdfMeaning from payload(display)
    return (dispatch) => {
        dispatch({ type: FETCH_SIC_CODES })
        let sicCodeslist = []
        sicCodes(payload).then((response) => {
            if (response.data && response.status === 200) {
                logger.info(`Successfully fetched Sic Codes`);
                dispatch({ type: FETCH_SIC_CODES_SUCCESS, payload: response.data })
            }
            else {
                logger.error(`Failed to fetch Sic codes from BusinessInfoActions`);
                dispatch({ type: FETCH_SIC_CODES_FAILURE, payload: sicCodeslist })
            }

        }).catch((err) => {
            logger.error(`Failed to fetch Sic codes from BusinessInfoActions, error : -> ${err}`);
            dispatch({ type: FETCH_SIC_CODES_FAILURE, payload: sicCodeslist })
        })
    }
}

export const verifyBusinessInfoZipCode = (businessInfo, zip) => {
    console.log("zipcodevalidator---------",businessInfo);
    return (dispatch) => {
        dispatch({ type: FETCH_BUSINESS_CITY_STATE, payload: zip });
        setTimeout(() => {
            // call to fetch the city and state values from zipcode
            uspsvalidator.cityStateLookup(zip).then((res) => {
                if (res.State && res.City) {
                    logger.info(`Successfully retrieved City: ${res.City}, State: ${res.State}`);
                    if (OPERATING_STATES.includes(res.State)) {
                        dispatch({
                            type: FETCH_BUSINESS_CITY_STATE_SUCCESS, payload: {
                                ...businessInfo, city: res.City, state: res.State,
                                zip: res.Zip5
                            }
                        });
                    }
                    else {
                        dispatch({ type: FETCH_BUSINESS_CITY_STATE_FAILURE, payload: { ...businessInfo } });
                        NotificationManager.error('Currently we are not serving this in area!!')
                    }
                }
                else {
                    dispatch({ type: FETCH_BUSINESS_CITY_STATE_FAILURE, payload: { ...businessInfo } });
                }
            }).catch((err) => {
                logger.error(`Failed to fetch City,State from BusinessInfoActions, error : -> ${err}`);
                NotificationManager.error(err.messae,"Failed to Fetch City, State. Please Try again!",5000)
                dispatch({ type: FETCH_BUSINESS_CITY_STATE_FAILURE, payload: err });
            })
        }, 2000)
    }
}

export const getSelectedBusinessInfo = (isClient) => {
    return (dispatch, getState) => {
        dispatch({ type: SET_UPDATE_BUSINESS_INFO })
        let businessId = getState().appClientCtx.currBusiness;
        getBusinessInfoFromBusinessId(isClient, businessId).then((response) => {
            if (response.status === 200) {
                logger.info(`Succesfully retrieved Selected BusinessInfo`);
                let data = response.data;
                let parsedData = {
                    'businessName': data.businessName,
                    'emailId': data.emailId,
                    'city': data.address.city,
                    'state': data.address.state,
                    'corporationName': data.corporationName,
                    'zip': data.address.zipcode,
                    'mailingAddressLine1': data.address.line1,
                    'mailingAddressLine2': data.address.line2,
                    'mobilePhoneNumber': data.mobilePhoneNumber,
                    'officePhoneNumber': data.officePhoneNumber,
                    'noOfLocations': data.businessLocations,
                    'propertyTaxContactPerson': data.ptxContactName,
                    'jobTitle': data.jobTitle,
                    'ownerName': data.ownerName,
                    'businessType': data.businessType,
                    'sicCode': data.sicCode,
                    'ownershipType': data.ownershipType,
                    'id': data.id
                }
                dispatch({ type: SET_UPDATE_BUSINESS_INFO_SUCCESS, payload: parsedData });
            }
        }).catch((err) => {
            logger.error(`Failed to get selected Business Info from BusinessInfoActions, error : -> ${err}`);
            dispatch({ type: SET_UPDATE_BUSINESS_INFO_FAILURE, payload: [] });
        })
    }
}

export const clearBusinessInfo = () => {
    return (dispatch) => {
        dispatch({ type: BUSINESS_RESET })
    }
}

export const verifyBusinessAddress = (payload, isPageSubmitted, lastVisitedPage) => {
    const addressObj = {
        Address1: payload.mailingAddressLine1,
        Address2: payload.mailingAddressLine2,
        City: payload.city,
        State: payload.state,
        Zip5: payload.zip
    }

    return (dispatch, getState) => {
        uspsvalidator.verify(addressObj).then((response) => {
            let line1 = false;
            let line2 = false;
            if (response.Address1 !== undefined) {
                if (response.Address1 === addressObj.Address1) {
                    line1 = true;
                }
            }
            if (response.Address2 !== undefined) {
                if (response.Address2 === addressObj.Address2) {
                    line2 = true;
                }
            }
            if (line1 || line2) {
                businessServiceLogic(dispatch, payload, getState, true, lastVisitedPage);
            }
            else {
                let addressPayload = {
                    ...payload,
                    uspsAddress: response
                }
                dispatch({ type: SET_PARTIAL_BUSINESS_INFO_SUCCESS, payload: addressPayload });
                dispatch(setBusinessAddressModalState(true));
            }
        }).catch((err) => {
            dispatch({ type: SET_BUSINESS_INFO_ERROR, payload: { ...payload, error: err } });
            NotificationManager.error('The Entered address is not valid!!')
            dispatch(setAppServiceInfo(400, "Address Info error", true, true));
        })
    }
}

export const setAddressForBusiness = (payload, addressType) => {
    return (dispatch) => {
        let addressPayload = '';
        if (addressType === 'userAddress') {
            addressPayload = {
                mailingAddressLine1: payload.mailingAddressLine1,
                mailingAddressLine2: payload.mailingAddressLine2
            }
        } else {
            addressPayload = {
                mailingAddressLine1: payload.Address1,
                mailingAddressLine2: payload.Address2 === undefined ? '' : payload.Address2
            }

        }
        dispatch({ type: SET_BUSINESS_ADDRESS_INFO, payload: addressPayload })
        dispatch(setBusinessAddressModalState(false));
    }
}

export const getBusinessInfo = (isClient, businessInfoId) => {
    return (dispatch) => {
        // dispatch({ type: GET_BUSINESS_INFO, payload: {} });
        if (businessInfoId) {
            getBusinessInfoFromBusinessId(isClient, businessInfoId).then((response) => {
                if (response.status === 200) {
                    logger.info(`Succesfully retrieved Selected BusinessInfo`);
                    let data = response.data;
                    let parsedData = {
                        'businessName': data.businessName,
                        'emailId': data.emailId,
                        'city': data.address.city,
                        'state': data.address.state,
                        'corporationName': data.corporationName,
                        'zip': data.address.zipcode,
                        'mailingAddressLine1': data.address.line1,
                        'mailingAddressLine2': data.address.line2,
                        'mobilePhoneNumber': data.mobilePhoneNumber,
                        'officePhoneNumber': data.officePhoneNumber,
                        'noOfLocations': data.businessLocations,
                        'propertyTaxContactPerson': data.ptxContactName,
                        'jobTitle': data.jobTitle,
                        'ownerName': data.ownerName,
                        'businessType': data.businessType,
                        'sicCode': data.sicCode,
                        'ownershipType': data.ownershipType,
                        'id': data.id
                    }
                    dispatch({ type: GET_BUSINESS_INFO_SUCCESS, payload: parsedData });
                }
            }).catch((err) => {
                logger.error(`Failed to get selected Business Info from BusinessInfoActions, error : -> ${err}`);
                NotificationManager.error(err.message,'Failed to get selected Business Info',5000);
                dispatch({ type: GET_BUSINESS_INFO_FAILURE, payload: [] });
            })
        }
    }
}


export const setBusinessAddressModalState = (flag) => {
    return (dispatch) => {
        dispatch({ type: SET_BUSINESS_ADDRESS_MODAL_STATE, payload: flag });
    }
}

//Post Client BusinessInfo
export const postClientBusinessInfo = (payload) => {
    return svcRequest.post(config.clientBusinessInfo, payload, {
        timeout: 30000
    }
    );
}

//Post Agent BusinessInfo
export const postAgentBusinessInfo = (payload) => {
    return svcRequest.post(config.agentBusinessInfo, payload, {
        timeout: 30000
    }
    );
}

//Post Client BusinessInfo
export const patchClientBusinessInfo = (payload, id) => {
    console.log("-------Payload-----",payload);
    return svcRequest.patch(`${config.clientBusinessInfo}${id}`, payload, {
        timeout: 30000
    }
    );
}

//Post Agent BusinessInfo
export const patchAgentBusinessInfo = (payload, id) => {
    return svcRequest.patch(`${config.agentBusinessInfo}${id}`, payload, {
        timeout: 30000
    }
    );
}
