import {
    SET_INVENTORY_INFO, SET_INVENTORY_INFO_SUCCESS, SET_INVENTORY_INFO_ERROR,
    FETCH_INVENTORY_INFO, FETCH_INVENTORY_INFO_SUCCESS, FETCH_INVENTORY_INFO_ERROR, UPDATE_SECOND_FORM_SECTION_STATUS,
    UPDATE_THIRD_FORM_SECTION_STATUS, UPDATE_INV_RESALE_FLAG, RESET_INVENTORY
} from "../actions/InventoryInfoActions";

const defaultState = {
    inventoryInfo: {
        invForResale: false,
        descOfInv: "",
        invValueOnDec: 0, 
        sellOutOfState: false,
        percentOfGoodsOutState:0,
        totSaleOutOfState:"",
        invTransOutStateThisYear: false,
        recordTypes:[],
        portionOfInvTransOutStateLastYear: false,
        noOfMonthInvOutOfState: "",
        percGoodsSigDiffWhy: "",
        percGoodsSigDiff: false,
        totInvJan:0,
        totInvFeb:0,
        totInvMar:0,
        totInvApr:0,
        totInvMay:0,
        totInvJune:0,
        totInvJuly:0,
        totInvAug:0,
        totInvSep:0,
        totInvOct:0,
        totInvNov:0,
        totInvDec:0, 
        totCostShippedOutState: 0

        
    },
    isSubmitting: false,
    isUpdating: false,
    secondFormSection: false,
    thirdFormSection: false, invResaleFlag: false
}

const InventoryInfoReducer = (state = defaultState, action) => {
    switch(action.type){
        case SET_INVENTORY_INFO:
            return {...state, isSubmitting: true }
        case SET_INVENTORY_INFO_SUCCESS:
            return {...state, inventoryInfo: action.payload, isSubmitting: false }
        case SET_INVENTORY_INFO_ERROR: 
        case FETCH_INVENTORY_INFO_ERROR:
            return {...state, inventoryInfo: defaultState.inventoryInfo, isUpdating: false}
        case FETCH_INVENTORY_INFO:
            return {...state}
        case FETCH_INVENTORY_INFO_SUCCESS:
            return {...state, inventoryInfo: action.payload, isUpdating: true, invResaleFlag: state.invResaleFlag, secondFormSection: false, thirdFormSection: false}
        case UPDATE_SECOND_FORM_SECTION_STATUS:
            console.log({...state})
            return { ...state, secondFormSection: action.payload, invResaleFlag: state.invResaleFlag}
        case UPDATE_THIRD_FORM_SECTION_STATUS:
            console.log({...state})
            return { ...state, thirdFormSection: action.payload, invResaleFlag: state.invResaleFlag, secondFormSection: state.secondFormSection}
        case UPDATE_INV_RESALE_FLAG:
            return {...state, invResaleFlag: action.payload, thirdFormSection: state.thirdFormSection, secondFormSection: state.secondFormSection}
        case RESET_INVENTORY:
            return defaultState;
        default: 
            return state;
    }
}
export default InventoryInfoReducer;