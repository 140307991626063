import React, { Fragment, useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Typography, AppBar, Grid, Toolbar, Fade, Stack, useMediaQuery, Grow, Paper,
   MenuItem, Avatar, Divider, IconButton, Tooltip, Popper, ClickAwayListener, MenuList, ListItemIcon } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getClientDetails } from '../components/appClient/AppClientSelectors';
import { getIsAuthorized } from '../components/home/login/LoginSelectors';
import { logout } from '../components/home/login/LoginAction';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import QuizIcon from '@mui/icons-material/Quiz';
import EventIcon from '@mui/icons-material/Event';
import LogoutIcon from '@mui/icons-material/Logout';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { Link } from 'react-router-dom';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../constants/colorScheme';

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    grid:{
      justifyContent: 'space-evenly',
    },
    head:{
      background: PRIMARY_COLOR,
    },
    agentHead: {
      background: '#db7a8bf5',
    },
    title: {
      flexGrow: 1,
      textAlign: 'left',
      cursor: 'pointer'
    },
    agentTitle: {
      color: '#2d3f58',
      flexGrow: 1,
      textAlign: 'left',
      cursor: 'pointer'
    },
    menuItem: {
      background:  'linear-gradient(to right, PRIMARY_COLOR, PRIMARY_COLOR, PRIMARY_COLOR), linear-gradient(to right, white, white)',
      backgroundSize: '100% 0.2em, 0 0.2em',
      backgroundPosition: '100% 100%, 0 100%',
      backgroundRepeat: 'no-repeat',
      transition: 'background-size 400ms',
      '&:hover': {
        color: PRIMARY_COLOR,
        backgroundSize: '0 0.2em, 100% 0.2em'
      }
    },
    agentMenuItem: {
      background:  'linear-gradient(to right, #d77c8df5, #414d69, #d77c8df5), linear-gradient(to right, white, white)',
      backgroundSize: '100% 0.2em, 0 0.2em',
      backgroundPosition: '100% 100%, 0 100%',
      backgroundRepeat: 'no-repeat',
      transition: 'background-size 400ms',
      '&:hover': {
        color: PRIMARY_COLOR,
        backgroundSize: '0 0.2em, 100% 0.2em'
      }
    },
    menuLinks:{
      textDecoration: 'none',
      color: SECONDARY_COLOR,
      cursor: 'pointer',
      padding: '0 3px',
      '&:hover': {
        color: 'white',
      }
    },
    agentMenuLinks:{
      textDecoration: 'none',
      color: '#2d3f58',
      cursor: 'pointer',
      padding: '0 3px',
      '&:hover': {
        color: 'white',
      }
    },
    activeMenuLinks:{
      textDecoration: 'none',
      color: 'white',
      cursor: 'pointer',
      padding: '0 3px',
      textShadow: '-1px -1px 6px #ffffff',
      '&:hover': {
        color: 'white',
      }
    },
    avatar: {
      cursor: 'pointer',
      margin: '0 10px',
      width: '30px',
      height: '30px',
      borderRadius: '40%',
      fontSize: '1rem',
      fontWeight: '600',
    },
    avatarBg: {
      backgroundColor: 'grey',
    },
    agentAvatarBg: {
      backgroundColor: '#2d3f58',
    },
    menuBtn:{
      backgroundColor: 'unset',
      padding: '6px',
      fontSize: '0.775rem',
      boxShadow: 'none',
     
    },
    hamMenu:{
      zIndex: '999',
      '& .MuiPaper-root':{
        color: 'white',
        background: PRIMARY_COLOR,
        width: '200px',
      }
    },
    hamMenuItem: {
      height: '70px',
    },
    hamMenuIcon: {
      paddingRight: '10px',
    },
    show: {
      display: 'inline-block'
    },
    hide: {
      display: 'none'
    },
    divider: {
        borderColor: SECONDARY_COLOR
    }
  }));
  
  const HeaderMenu = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const isAuth = useSelector(getIsAuthorized);
    const clientDetails = useSelector(getClientDetails);
    const menuRef = useRef(null);
    const deviceMenuRef = useRef(null);
    const deviceNoAuthMenuRef = useRef(null);
    const { firstName, lastName } = clientDetails;
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const iconProp = {      className: isSmallScreen ? clsx(classes.menuLinks) : classes.hide    };
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [deviceAnchorEl, setDeviceAnchorEl] = useState(null);
    const [deviceNoAuthAnchorEl, setDeviceNoAuthAnchorEl] = useState(null);
    const menuOpen = Boolean(menuAnchorEl);
    const deviceOpen = Boolean(deviceAnchorEl);
    const deviceNoAuthOpen = Boolean(deviceNoAuthAnchorEl);

    const handleMenuClick = (event) => {
      setMenuAnchorEl(event.currentTarget);
    };

    const deviceMenuClick = (e) => {
      setDeviceAnchorEl(e.currentTarget);
    }

    const deviceNoAuthMenuClick = (e) => {
      setDeviceNoAuthAnchorEl(e.currentTarget);
    }
    
    const getActiveCss = (refType) => {
      switch(refType) {
        case 'accRef'       : return isSmallScreen ? classes.hide : (history.location.pathname === '/appClientHome' ? classes.activeMenuLinks : classes.menuLinks);
        case 'homeRef'      : return isSmallScreen ? classes.hide : (history.location.pathname === '/' ? classes.activeMenuLinks : classes.menuLinks);
        case 'contactRef'   : return isSmallScreen ? classes.hide : (history.location.pathname === '/contactUs' ? classes.activeMenuLinks : classes.menuLinks);
        case 'deadlineRef'  : return isSmallScreen ? classes.hide : (history.location.pathname === '/deadlines' ? classes.activeMenuLinks : classes.menuLinks);
        case 'faqRef'       : return isSmallScreen ? classes.hide : (history.location.pathname === '/faq' ? classes.activeMenuLinks : classes.menuLinks);
        default             : 
      }
    }

    const handleClose = (event) => {
      if (menuRef.current && menuRef.current.contains(event.target)) {
          return
      }
        setMenuAnchorEl(null);
      };
    
    const handleDeviceClose = (event) => {
      if (deviceMenuRef.current && deviceMenuRef.current.contains(event.target)) {
        return
    }
      setDeviceAnchorEl(null);
    };

    const handleDeviceNoAuthMenuClose = (event) => {
      if (deviceNoAuthMenuRef.current && deviceNoAuthMenuRef.current.contains(event.target)) {
        return
    }
      setDeviceNoAuthAnchorEl(null);
    };

    const handleListKeyDown = (event) => {
      if (event.key === 'Tab') {
          event.preventDefault();
          setDeviceAnchorEl(null);
          setDeviceNoAuthAnchorEl(null);
          setMenuAnchorEl(null);
      }
  }
    const handleRedirect = (redirectRoute) => {
      history.push(redirectRoute);
      setDeviceAnchorEl(null);
      setDeviceNoAuthAnchorEl(null);
    }

    const handleLogout = () => {
      dispatch(logout());
    }
    
    const handleProfileClick = () => {
      history.push('/profile');
    }

    return (
      <div className={classes.root}>
        <AppBar position="static"  className={classes.head}>
          <Toolbar>
            <Grid container alignItems="center">
              <Grid item xs={6} lg={6}>
                <Grid container alignItems="center">
                  <Grid item >
                    <Typography variant="h4" className={classes.title} onClick={() => isAuth ? 
                      handleRedirect('/historyScreen'): handleRedirect("")}>
                        Business Property Tax
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} lg={6} >
                <Grid container alignItems="center" className={clsx(classes.grid)} >
                {isAuth ? 

                  <Fragment>
                      <Grid item className={classes.menuItem}>
                        <Fade in={true} timeout={1000}>
                          <Typography variant="h5">
                            <Link to="/historyScreen" className={(() => getActiveCss('accRef'))()}> Account </Link>
                          </Typography>
                        </Fade>
                      </Grid> 
                      <Grid item className={classes.menuItem}>
                        <Fade in={true} timeout={2000}>
                          <Typography variant="h5">
                            <Link to="/contactUs" className={(() => getActiveCss('contactRef'))()} > Contact Us </Link>
                          </Typography>
                          </Fade>
                      </Grid>
                      <Grid item className={classes.menuItem}>
                        <Fade in={true} timeout={2000}>
                          <Typography variant="h5">
                            <Link to="/deadlines" className={(() => getActiveCss('deadlineRef'))()} > Deadlines </Link>
                          </Typography>
                          </Fade>
                      </Grid>
                      <Grid item className={classes.menuItem}>
                        <Fade in={true} timeout={2000}>
                          <Typography variant="h5">
                            <Link to="/faq" className={(() => getActiveCss('faqRef'))()} > FAQ </Link>
                          </Typography>
                          </Fade>
                      </Grid>

                      {/* hamburger menu seen only on devices and hidden on large screens */}
                      <Tooltip title="Menu" aria-label="device-menu" arrow>
                        <IconButton color="inherit" onClick={deviceMenuClick} 
                          ref={deviceMenuRef} size="small" {...iconProp}>
                          <MenuIcon />
                        </IconButton>
                      </Tooltip>
                      <Popper
                          anchorEl={deviceMenuRef.current}
                          open={deviceOpen} transition disablePortal
                          className={classes.hamMenu}
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleDeviceClose}>
                                    <MenuList autoFocusItem={deviceOpen} id="device-menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem className={classes.hamMenuItem} onClick={() => handleRedirect('/appClientHome')}>
                                            <ListItemIcon className={classes.menuLinks}>
                                                <HomeIcon fontSize="small"  className={classes.hamMenuIcon}/>
                                                Account
                                            </ListItemIcon>
                                        </MenuItem>
                                        <Divider className={classes.divider}/>
                                        <MenuItem className={classes.hamMenuItem} onClick={() => handleRedirect('/contactUs')}>
                                            <ListItemIcon className={classes.menuLinks}>
                                                <ContactPhoneIcon fontSize="small"  className={classes.hamMenuIcon}/>
                                                Contact Us
                                            </ListItemIcon>
                                        </MenuItem>
                                        <Divider className={classes.divider}/>
                                        <MenuItem className={classes.hamMenuItem} onClick={() => handleRedirect('/deadlines')}>
                                            <ListItemIcon className={classes.menuLinks}>
                                              <EventIcon fontSize="small"  className={classes.hamMenuIcon}/>
                                                Deadlines
                                            </ListItemIcon>
                                        </MenuItem>
                                        <Divider className={classes.divider}/>
                                        <MenuItem className={classes.hamMenuItem} onClick={() => handleRedirect('/faq')}>
                                            <ListItemIcon className={classes.menuLinks}>
                                              <QuizIcon fontSize="small"  className={classes.hamMenuIcon}/>
                                                Faq
                                            </ListItemIcon>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                            </Grow>
                          )}
                      </Popper>
                      <Grid item >
                          <Fade in={true} timeout={3000}> 
                            <Stack direction="row" style={{padding: '5px 0'}} > 
                              <div ref={menuRef} style={{ display: 'flex'}} aria-label="profile-menu">
                                <Avatar className={clsx(classes.avatar, classes.avatarBg)} onClick={handleMenuClick} >
                                    {`${firstName && firstName.split('')[0].toUpperCase()}${lastName ? lastName.split('')[0].toUpperCase() : ''}`}
                                </Avatar> 
                                <Typography variant="h5" className={isSmallScreen ? classes.hide : classes.menuLinks}
                                    onClick={handleMenuClick} > 
                                    {`${firstName.toUpperCase()} `} 
                                </Typography>
                              </div>
                              <Popper
                                  anchorEl={menuRef.current}
                                  open={menuOpen} transition disablePortal
                                  className={classes.hamMenu}
                                >
                                  {({ TransitionProps, placement }) => (
                                    <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList autoFocusItem={menuOpen} id="menu-list" onKeyDown={handleListKeyDown}>
                                                <MenuItem className={classes.hamMenuItem} onClick={handleProfileClick}>
                                                    <ListItemIcon className={classes.menuLinks}>
                                                        <PermContactCalendarIcon fontSize="small"  className={classes.hamMenuIcon}/>
                                                          Profile
                                                    </ListItemIcon>
                                                </MenuItem>
                                                <Divider className={classes.divider}/>
                                                <MenuItem className={classes.hamMenuItem} onClick={handleLogout}>
                                                    <ListItemIcon className={classes.menuLinks}>
                                                      <LogoutIcon fontSize="small"  className={classes.hamMenuIcon}/>
                                                        Logout
                                                    </ListItemIcon>
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                    </Grow>
                                  )}
                              </Popper>
                            </Stack>
                          </Fade>
                        </Grid>
                  </Fragment>
                   : 

                  <Fragment>
                    <Grid item className={classes.menuItem}>
                      <Fade in={true} timeout={1000}>
                        <Typography variant="h5">
                          <Link to="/" className={(() => getActiveCss('homeRef'))()} > Home </Link>
                        </Typography>
                      </Fade>
                    </Grid> 
                    <Grid item className={classes.menuItem}>
                      <Fade in={true} timeout={2000}>
                        <Typography variant="h5">
                          <Link to="/contactUs" className={(() => getActiveCss('contactRef'))()} > Contact Us </Link>
                        </Typography>
                      </Fade>
                    </Grid>
                    <Grid item className={classes.menuItem}>
                      <Fade in={true} timeout={2000}>
                        <Typography variant="h5">
                          <Link to="/deadlines" className={(() => getActiveCss('deadlineRef'))()} > Deadlines </Link>
                        </Typography>
                      </Fade>
                    </Grid>
                    <Grid item className={classes.menuItem}>
                      <Fade in={true} timeout={2000}>
                        <Typography variant="h5">
                          <Link to="/faq" className={(() => getActiveCss('faqRef'))()} > FAQ </Link>
                        </Typography>
                      </Fade>
                    </Grid>
                    <Grid item className={classes.menuItem}>
                      <Fade in={true} timeout={2000}>
                        <Typography variant="h5">
                          <Link to="/" className={(() => getActiveCss('homeRef'))()} > Login </Link>
                        </Typography>
                      </Fade>
                    </Grid>

                    {/* hamburger menu seen only on devices and hidden on large screens */}
                    <Tooltip title="Menu" aria-label="menu" arrow>
                      <IconButton color="inherit" onClick={deviceNoAuthMenuClick} ref={deviceNoAuthMenuRef} 
                      size="small" {...iconProp}>
                        <MenuIcon />
                      </IconButton>
                    </Tooltip>
                    <Popper
                          anchorEl={deviceNoAuthMenuRef.current}
                          open={deviceNoAuthOpen} transition disablePortal
                          className={classes.hamMenu}
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleDeviceNoAuthMenuClose}>
                                    <MenuList autoFocusItem={deviceNoAuthOpen} id="device-menu-list" onKeyDown={handleListKeyDown}>
                                        <MenuItem className={classes.hamMenuItem} onClick={() => handleRedirect('/')}>
                                            <ListItemIcon className={classes.menuLinks}>
                                                <HomeIcon fontSize="small"  className={classes.hamMenuIcon}/>
                                                Home
                                            </ListItemIcon>
                                        </MenuItem>
                                        <Divider className={classes.divider}/>
                                        <MenuItem className={classes.hamMenuItem} onClick={() => handleRedirect('/contactUs')}>
                                            <ListItemIcon className={classes.menuLinks}>
                                                <ContactPhoneIcon fontSize="small"  className={classes.hamMenuIcon}/>
                                                Contact Us
                                            </ListItemIcon>
                                        </MenuItem>
                                        <Divider className={classes.divider}/>
                                        <MenuItem className={classes.hamMenuItem} onClick={() => handleRedirect('/deadlines')}>
                                            <ListItemIcon className={classes.menuLinks}>
                                              <EventIcon fontSize="small"  className={classes.hamMenuIcon}/>
                                                Deadlines
                                            </ListItemIcon>
                                        </MenuItem>
                                        <Divider className={classes.divider}/>
                                        <MenuItem className={classes.hamMenuItem} onClick={() => handleRedirect('/faq')}>
                                            <ListItemIcon className={classes.menuLinks}>
                                              <QuizIcon fontSize="small"  className={classes.hamMenuIcon}/>
                                                Faq
                                            </ListItemIcon>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                            </Grow>
                          )}
                      </Popper>
                  </Fragment>}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
    );
  }

  export default HeaderMenu;
