import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { Grid , Box, Button,Typography,Divider,FormControl,Select,InputLabel,MenuItem,FormHelperText} from '@mui/material';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme,ThemeProvider} from '@mui/material/styles';
import { standardButtons } from '../../../styles/mainPagesCSS';
import { getAssetTypes,getCurrentAsset } from "../selectors/AssetsInfoSelector";
import { useSelector } from "react-redux";
import { submitAssetsInfo, updateAssetsInfo } from '../../../redux/actions/AssetsInfoActions';
import { getLocationInfo } from '../selectors/LocationInfoSelector';
import { getTaxFillingData } from '../selectors/TaxFilingPlanSelector';
import { formatNumberInput } from '../../../util/utilities';
import { getTaxInfo } from '../selectors/TaxInfoSelector'; //added for retriveing the Tex Plan ID

const useStyles = makeStyles((theme) => ({
    fieldContainer: {
      width:'100%',
      margin:1,
      [theme.breakpoints.down("sm")]: {
        width: '45vw',
        left: '20%'
      },
    },
    buttonStyle: {
        [theme.breakpoints.down("md")]: {
          position:'relative',
          left: '50%',
        }
      }
  }));


export default function NewAssetForm({closeAction,updateAsset}) {
    const classes = useStyles();
    const currentAsset = useSelector(getCurrentAsset);
    
    const dispatch = useDispatch();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const buttonDirection = matches? "column":"row";
    const assetTypes = useSelector(getAssetTypes);
    const locationInfo = useSelector(getLocationInfo);
    const [assetType,setAssetType] = useState('');
    const taxFilingPlanData = useSelector(getTaxFillingData);
    //    let asset =  assetTypes.filter( assetType =>{
//        return  assetType.id == currentAsset.assetType
//     });
const taxInfoData = useSelector(getTaxInfo);//added for retriveing the Tex Plan ID from getTaxInfo Selector

let defaultValues = (currentAsset && updateAsset) ? {...currentAsset,assetDescription:currentAsset.assetDesc,assetNumber:currentAsset.assetNum,assetType:currentAsset.assetType,
    originalPurchasePrice:currentAsset.orgPurchasePrice, } :{assetDescription:'',assetNumber:'',originalPurchasePrice:'',
    quantity:'',costCenter:''};
    
    const { register, handleSubmit, formState: { errors },setValue } = useForm({
        defaultValues: defaultValues,
        shouldUnregister: false
    });
    useEffect(() => {
        if(currentAsset && updateAsset) {
            setValue('assetDescription',currentAsset.assetDesc);
            setValue('assetNumber',currentAsset.assetNum);
            setValue('originalPurchasePrice',currentAsset.orgPurchasePrice);
            setValue('dateAcquired',currentAsset.dateAcquired);
            setValue('quantity',currentAsset.quantity);
            setValue('costCenter',currentAsset.costCenter);
            setValue('assetType',currentAsset.assetType);
            setAssetType(currentAsset.assetType);
        }
    },[currentAsset]);

    
    let updateFlag = updateAsset;
    const onSubmit = data => {
        
        let date = new Date(data.dateAcquired);
        let locationInfoId = null,taxFilingPlanId = null;
        if(taxFilingPlanData && taxFilingPlanData.isPlanSelected) {
            if(taxFilingPlanData.taxFilingPlan){
                locationInfoId = taxFilingPlanData.taxFilingPlan.locationInfoId;
                taxFilingPlanId = taxFilingPlanData.taxFilingPlan.id;
            }
        }
        if(locationInfoId == null || locationInfoId === undefined){
            locationInfoId = locationInfo != null ? locationInfo.id:'';
        }
        if(taxFilingPlanId == null || taxFilingPlanId === undefined){
            // taxFilingPlanId = locationInfo != null ? locationInfo.taxInfoId:'';
            taxFilingPlanId = taxFilingPlanId = taxInfoData.id; //Tax Plan id is retriving from taxInfoData.id from getTaxInfo selector from TaxInfo Selector
        }
        
        // alert("New Asset taxfilingplanid:"+ taxFilingPlanId);
        let payload = {...data,locationInfoId:locationInfoId,taxFilingPlanId:taxFilingPlanId,dateAcquired: new Date( date.getTime() + Math.abs(date.getTimezoneOffset()*60000) ).getTime()};
        if(updateAsset) {
            payload = {...payload,id:currentAsset.id};
        }
        updateFlag ? dispatch(updateAssetsInfo(payload)) : dispatch(submitAssetsInfo(payload));
        // dispatch(submitAssetsInfo({...data,locationInfoId:null,taxFilingPlanId:null,assetFormats:[]}));
        closeAction();
    }
    return ( 
        <Box style={{position:'relative',left:'0%',bottom:'0%',textAlign:'justify',minHeight:'350px',width:'100%'}}>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container direction="column" sx={{width:'100%'}}>
                    <Grid item xs={6} sx={{ m: 1 }}>
                        <h1 style={{textAlign:'center'}}>{updateFlag?'Edit Asset': 'Add Asset'}
                            <Typography className={classes.loginTitle} style={{ float: 'right' }}>
                                <span style={{color: 'red'}}>*</span>  Required fields 
                            </Typography><Divider />
                        </h1> 
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1 }}>
                        <TextField id="assetDescription" label="Asset Description" 
                        InputLabelProps={{ shrink:true, required: true, sx: {'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}} }}
                        variant="outlined" {...register('assetDescription', { required: 'Please enter Asset description', maxLength: 30 })}
                        className={classes.fieldContainer}
                        helperText={errors.assetDescription ? errors.assetDescription.message : ""}
                        error={errors.assetDescription} 
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1 }}>
                        <TextField type="number" id="assetNumber" 
                        InputLabelProps={{ shrink:true, }} label="Asset Number" variant="outlined"
                        //  {...register('assetNumber', { required: 'Please enter Asset number', maxLength: 30 })}
                         {...register('assetNumber', {maxLength: 30 })}
                         onKeyDown={ formatNumberInput }
                        className={classes.fieldContainer}
                        helperText={errors.assetNumber ? errors.assetNumber.message : ""}
                        // error={errors.assetNumber} 
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1}}>
                        <FormControl  className={classes.fieldContainer}>
                            <InputLabel htmlFor="assetType">Asset Type</InputLabel>
                            <Select
                                labelId="assetType"
                                id="assetType"
                                label="assetType"
                                {...register('assetType',{ required: 'Please select Asset type' })}
                                error={!assetType && errors.assetType} 
                                onChange={e => {
                                    setAssetType(e.target.value);
                                }}
                                value={assetType}
                                >
                            {assetTypes.map((assetType) => (
                                <MenuItem  key={assetType.id}  value={assetType.id}>
                                 {assetType.display}
                                </MenuItem>
                          ))}
                         
                            </Select>
                            <FormHelperText error>{!assetType && errors.assetType && errors.assetType.message}</FormHelperText>
                            </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1}}>
                        <TextField
                            id="dateAcquired"
                            type='date'
                            label='Year Acquired'
                            variant="outlined"
                            className={classes.fieldContainer}
                            InputLabelProps={{ shrink: true, required: true,sx: {'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}}}}
                            {...register('dateAcquired',
                             { required: 'Please enter asset acquired date', maxLength: 30,
                             validate: value => { 

                                const today = new Date();
                                const yyyy = today.getFullYear();
                                let mm = today.getMonth() + 1; // Months start at 0!
                                let dd = today.getDate();
                                
                                if (dd < 10) dd = '0' + dd;
                                if (mm < 10) mm = '0' + mm;
                                
                                const formattedToday = yyyy + '-' + mm + '-' + dd;

                                if(value <= formattedToday) {
                                    return true 
                                } else {
                                    return 'Please select valid acquired date'
                                }
                            }, })}
                            helperText={errors.dateAcquired ? errors.dateAcquired.message : ""}
                            error={errors.dateAcquired} 
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1 }}>
                        <TextField id="originalPurchasePrice" 
                        type='float'
                        InputLabelProps={{shrink:true, required: true, sx: {'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}} }}
                         label="Original Purchase Price" variant="outlined"
                          {...register('originalPurchasePrice', { required: 'Please enter Original Purchase Price', maxLength: 30 })}
                          onKeyDown={ formatNumberInput }
                          helperText={errors.originalPurchasePrice ? errors.originalPurchasePrice.message : ""}
                            error={errors.originalPurchasePrice} 
                        className={classes.fieldContainer}/>
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1 }}>
                        <TextField type="number" id="quantity" label="Quantity" InputLabelProps={{shrink:true, required: true, sx: {'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}} }}
                         variant="outlined" {...register('quantity', { required: 'Please enter quantity', maxLength: 30 })}
                         onKeyDown={ formatNumberInput }
                        className={classes.fieldContainer}
                        helperText={errors.quantity ? errors.quantity.message : ""}
                        error={errors.quantity} />
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1 }}>
                        <TextField id="costCenter" label="Cost Center" InputLabelProps={{shrink:true}}
                        //  variant="outlined" {...register('costCenter', { required: 'Please enter Cost center', maxLength: 30 })}
                         variant="outlined" {...register('costCenter', {maxLength: 30 })}
                        className={classes.fieldContainer}
                        helperText={errors.costCenter ? errors.costCenter.message : ""}
                        // error={errors.costCenter}
                        />
                    </Grid>
                    
                </Grid>
                <ThemeProvider theme={standardButtons}>
                    <Stack spacing={2} style={{ width:'100%',marginTop: '50px' }} direction={buttonDirection} justifyContent="center" className={classes.buttonStyle}>
                        <Button variant="contained" size="medium" onClick={()=> closeAction()} sx={{ mr: 1 , width: '200px',height:'50px' }}>
                        Back
                        </Button>
                        <Button  variant="contained" size="medium" sx={{ mr: 1 , width: '200px',height:'50px' }} type="submit">
                            Save Asset
                        </Button>
                    </Stack>
                </ThemeProvider>
            </form>
        </Box>
    );
}