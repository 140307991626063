import { uspsvalidator } from "../../util/uspsConfig";
import { OPERATING_STATES } from "../../constants/operatingStates";
import config from "../../config/context-root-configs";
import { svcRequest } from "../../util/requestHelper";
import { defineCurrAdditionalBusiness } from "../../components/appClient/AppClientActions";
import { putTaxInfo } from './taxInfoAction';
import { setAppServiceInfo } from './AppServiceInfoAction';
import { NotificationManager } from "react-notifications";

const { logger } = require('../../logger');

export const FETCH_ADDBIZ_CITY_STATE = 'FETCH_ADDBIZ_CITY_STATE';
export const FETCH_ADDBIZ_CITY_STATE_SUCCESS = 'FETCH_ADDBIZ_CITY_STATE_SUCCESS';
export const FETCH_ADDBIZ_CITY_STATE_FAILURE = 'FETCH_ADDBIZ_CITY_STATE_FAILURE';

export const ADDITIONAL_BUSINESS_INFO_SUCCESS = 'ADDITIONAL_BUSINESS_INFO_SUCCESS';
export const ADDITIONAL_BUSINESS_INFO_FAILURE = 'ADDITIONAL_BUSINESS_INFO_FAILURE';

export const SET_ADDITIONAL_BUSINESS_SUCCESS = 'SET_ADDITIONAL_BUSINESS_SUCCESS';

export const RESET_ADDITIONALBI='RESET_ADDITIONALBI';

export const verifyAdditionalBusinessInfoZipCode = (additionalBusinessInfo,zip) => {
    return (dispatch) => {
        dispatch({ type: FETCH_ADDBIZ_CITY_STATE, payload: zip});
        setTimeout( () => {
            // call to fetch the city and state values from zipcode
            uspsvalidator.cityStateLookup(zip).then((res) => {
                if(res.State && res.City){
                    logger.info(`Successfully retrieved City: ${res.City}, State: ${res.State}`);
                    if (OPERATING_STATES.includes(res.State)) {
                        dispatch({ type: FETCH_ADDBIZ_CITY_STATE_SUCCESS, payload: {...additionalBusinessInfo,city:res.City,
                        state:res.State,zip:res.Zip5}});
                    }
                    else {
                        dispatch({ type: FETCH_ADDBIZ_CITY_STATE_FAILURE, payload: {...additionalBusinessInfo} });
                    }
                }
                else{
                    dispatch({ type: FETCH_ADDBIZ_CITY_STATE_FAILURE, payload: {...additionalBusinessInfo}});
                }
            }).catch((err) => {
                logger.error(`Failed to fetch City,State from BusinessInfoActions, error : -> ${err}`);
                NotificationManager.error(err.message,'Failed to Fetch City, State Information',5000);
                dispatch({ type: FETCH_ADDBIZ_CITY_STATE_FAILURE, payload: err});
            })
        }, 2000 )
    }
}

export const submitAdditionalBusinessInfo = (payload,isPageSubmitted,lastVisitedPage) => {
    return (dispatch,getState) => {
        let taxFilingPlanId = getState().taxInfoReducer.taxInfo.id;
        let businessInfoId = getState().businessInfo.businessInfo.id;
        if(businessInfoId === 0) {
            businessInfoId = getState().taxFilingPlanInfoReducer.taxFilingPlan.businessInfoId;
        }
        
        let locationInfoId = getState().locationInfo.locationInfo.id;
        if(locationInfoId === undefined) {
            locationInfoId = getState().taxFilingPlanInfoReducer.taxFilingPlan.locationInfoId;
        }
            let postPayload = {
                ...payload,
                taxFilingPlanId: getState().taxInfoReducer.taxInfo.id,
                businessType: payload.businessType,
                sicCode: payload.sicCode,
                businessDescription: payload.businessDescription,
                occupiedArea: payload.squareFootOccupied,
                businessSoldDate: payload.businessSoldDate,
                businessStartDate: payload.businessStartDateAtLoc,
                businessMovedDate: payload.businessMovedDate,
                businessClosedDate: payload.businessClosedDate,
                newOwnerName: payload.newOwner,
                salesTaxPermitNr: payload.salesTaxPermitNumber,
                newAddress: {
                    line1: payload.newAddressLine1,
                    line2: payload.newAddressLine2, 
                    state: payload.state,
                    city: payload.city,
                    zipcode: payload.zip
                    
                },
                assetsConfirmationInd: payload.assetsConfirmationInd,
             

            }
            if(payload.id && payload.id !== 0){
                patchAdditionalBusinessInfo(postPayload,payload.id).then(() => {
                    logger.info(`Successfully updated Client Business Information`);
                    dispatch({ type: ADDITIONAL_BUSINESS_INFO_SUCCESS, payload: postPayload });
                    dispatch(putTaxInfo(taxFilingPlanId,businessInfoId,locationInfoId,lastVisitedPage,true));
                    // dispatch(setAppServiceInfo(200,"AdditionalBusiness Info successfully submitted",false,isPageSubmitted));
                }).catch((err) => {
                    logger.error(`Failed to Update Additional business Info for the id: ${payload.id}, error : -> ${err}`);
                    NotificationManager.error(err.message,'Failed to Update Additional Business Information',5000);
                    dispatch(setAppServiceInfo(err.response.status,"Business Info error",true,isPageSubmitted));
                });
            } else {
                postAdditionalBusinessInfo(postPayload).then((response) => {
                    if(response){
                        logger.info(`Successfully posted Client BusinessInfo`);
                        dispatch({ type: ADDITIONAL_BUSINESS_INFO_SUCCESS, payload: {...postPayload,id:response.data.additionalBusinessInfoId}});
                        dispatch(defineCurrAdditionalBusiness(response.data.additionalBusinessInfoId));
                        dispatch(putTaxInfo(taxFilingPlanId,businessInfoId,locationInfoId,lastVisitedPage,true));
                        // dispatch(setAppServiceInfo(response.status,"AdditionalBusiness Info successfully submitted",false,isPageSubmitted));
                    }
                }).catch((err) => {
                    logger.error(`Failed to Post Additional business Info, error : -> ${err}`);
                    NotificationManager.error(err.message,"Failed to Post Additional Business Information",5000);
                    dispatch(setAppServiceInfo(err.response.status,"Business Info error",true,isPageSubmitted));
                });
                }
        }

}

export const getCurrentAdditionalBusinessInfoData = () => {
    return (dispatch, getState) => {
        let currentAdditionalBusinessInfoId = getState().appClientCtx.currAdditionalBusiness;
        getAdditionalBusinessInfo(currentAdditionalBusinessInfoId).then((response) => {
            let payload = response.data;
            let getParsedPayload = {
                businessType: payload.businessType,
                sicCode: payload.sicCode,
                businessDescription: payload.businessDescription,
                squareFootOccupied: payload.occupiedArea,
                businessSoldDate: payload.businessSoldDate,
                businessStartDateAtLoc: payload.businessStartDate,
                businessMovedDate: payload.businessMovedDate,
                businessClosedDate: payload.businessClosedDate,
                newOwner: payload.newOwnerName,
                salesTaxPermitNumber: payload.salesTaxPermitNr,
                newAddressLine1: payload.newAddress.line1,
                newAddressLine2: payload.newAddress.line2, 
                state: payload.newAddress.state, 
                city: payload.newAddress.city,
                zip: payload.newAddress.zipcode,
                assetsConfirmationInd: payload.assetsConfirmationInd,  
                id: payload.id
            }
            dispatch({ type: SET_ADDITIONAL_BUSINESS_SUCCESS, payload: getParsedPayload });
        })
    }
}

export const getAdditionalBusinessInfoData = () => {

   
    return (dispatch,getState) => {
        let taxFilingPlanId = getState().taxFilingPlanInfoReducer.taxFilingPlan.id;
        getAdditionalBusinessInfo(taxFilingPlanId).then((response) => {
            let payload = response.data;
            if(payload) {
            let getParsedPayload = {
                businessType: payload.businessType,
                sicCode: payload.sicCode,
                businessDescription: payload.businessDescription,
                squareFootOccupied: payload.occupiedArea,
                businessSoldDate: payload.businessSoldDate,
                businessStartDateAtLoc: payload.businessStartDate,
                businessMovedDate: payload.businessMovedDate,
                businessClosedDate: payload.businessClosedDate,
                newOwner: payload.newOwnerName,
                salesTaxPermitNumber: payload.salesTaxPermitNr,
                newAddressLine1: payload.newAddress? payload.newAddress.line1: '',
                newAddressLine2: payload.newAddress? payload.newAddress.line2:'', 
                state: payload.newAddress?payload.newAddress.state:'', 
                city: payload.newAddress?payload.newAddress.city:'',
                zip: payload.newAddress?payload.newAddress.zipcode:'',
                assetsConfirmationInd: payload.assetsConfirmationInd,  
                id: payload.id
            }
            dispatch({ type: SET_ADDITIONAL_BUSINESS_SUCCESS, payload: getParsedPayload });
        } else{
            dispatch({ type: SET_ADDITIONAL_BUSINESS_SUCCESS, payload: {} });
        }
        })
    }
}

//Post Agent BusinessInfo
export const patchAgentBusinessInfo = (payload,id) => {
    return svcRequest.patch(`${config.agentBusinessInfo}${id}`, payload, {
        timeout: 30000
      }
    );
}

//Post Additional BusinessInfo
export const postAdditionalBusinessInfo = (payload) => {
    return svcRequest.post(config.additionalBusinessInfo, payload, {
        timeout: 30000
      }
    );
}

//Patch Additional BusinessInfo
export const patchAdditionalBusinessInfo = (payload,id) => {
    return svcRequest.patch(`${config.additionalBusinessInfo}/${id}`, payload, {
        timeout: 30000
      }
    );
}

//Get Additional BusinessInfo
export const getAdditionalBusinessInfo = (id) => {
    return svcRequest.get(`${config.additionalBusinessInfo}?taxFilingPlanId=${id}`, {
        timeout: 30000
    })
}
  
//ADDED TO RESET ADDITIONAL BUSINESS INFO
export const resetAdditionalBusinessInfo=()=>{
    return dispatch => {
        dispatch({ type: RESET_ADDITIONALBI });
    }
}