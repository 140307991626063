import { SET_SUPPLIES_INFO, SET_SUPPLIES_INFO_SUCCESS, SET_SUPPLIES_INFO_ERROR, GET_SUPPLIES_INFO, GET_SUPPLIES_INFO_ERROR, GET_SUPPLIES_INFO_SUCCESS, UPDATE_SUPPLIES_INFO_SUCCESS,RESET_SUPPLIES } from "../actions/SuppliesInfoActions";

const defaultState = {
    suppliesInfo: {
        id:'',
        totalExpensesYearly: '',
        reOrderTimes: '',
        inventoryCheck: "No",
    },
    isSubmitting: false,
    updateFlag: false
}

const SupppliesInfoReducers = (state = defaultState, action) => {
    switch(action.type){
        case SET_SUPPLIES_INFO:
            return {...state, isSubmitting: true }
        case SET_SUPPLIES_INFO_SUCCESS:
            return {...state, suppliesInfo: action.payload, isSubmitting: false }
        case SET_SUPPLIES_INFO_ERROR: 
            return {...state, suppliesInfo: defaultState.suppliesInfo}
        case GET_SUPPLIES_INFO:
            return {...state, isSubmitting: true, updateFlag: false }
        case GET_SUPPLIES_INFO_SUCCESS:
            let updateFlag = false;
            if(action.payload.id){
                updateFlag = true;
            }
            return {...state, suppliesInfo: action.payload, isSubmitting: false, updateFlag: updateFlag}
        case UPDATE_SUPPLIES_INFO_SUCCESS:
            return {...state, suppliesInfo: action.payload, isSubmitting: false, updateFlag: false}
        case GET_SUPPLIES_INFO_ERROR: 
            return { suppliesInfo: defaultState.suppliesInfo }
            // ADDED TO RESET THE SUPPLIES
        case RESET_SUPPLIES: 
            return defaultState;
        default : 
            return state;
    }
}
export default SupppliesInfoReducers;
