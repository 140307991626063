import React, { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Fade, TextField, Button, Typography, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { login } from './LoginAction';
import { ThemeProvider } from '@mui/material/styles';
import { validateMailFormat } from '../../../util/utilities';
import { useHistory } from 'react-router';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../constants/colorScheme';
import { theme } from '../../../styles/mainPagesCSS';


const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '15px 0 '
    },
    formContainer: {
        color: '#1A355D',
        border: '1px solid PRIMARY_COLOR',
        boxShadow: '0px 0px 9px 4px rgb(183 163 167 / 55%)',
        padding: '10px'
    },
    loginTitle: {
        fontWeight: 'bold'
      },
    formInputs:{
        padding: '10px 0',
    },
    fields: {
        minWidth: 'calc(100% - 40px)'
    },
    loginBtn: {
        color: SECONDARY_COLOR,
        width: 'calc(100% - 40px)',
        fontWeight: 'bold',
        cursor: 'pointer',
        backgroundColor: PRIMARY_COLOR,
        '&:hover': {
            backgroundColor: PRIMARY_COLOR,
            color: 'white',
        }
    },
    actionBtn: {
        width: 'calc(100% - 100px)',
        fontWeight: 'bold',
        margin: '10px 0',
        '&:hover': {
          backgroundColor: PRIMARY_COLOR,
          color: SECONDARY_COLOR,
          animation: 0
      }
      }
  }));

const LoginForm = () => {
    const errObjInitialState = {
        mailErr: null, validationErr: null, pwdErr: null
    }

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const formRef = useRef(null);
    let mailInput = useRef(null);
    const [errs, setErrs ] = useState(errObjInitialState);
    const [loginEmail, setLoginEmail] = useState('');
    const [loginPwd, setLoginPwd] = useState('');
    const [userType, setUserType] = useState('');

    const clearValues = () => {
        setLoginEmail('');
        setLoginPwd('');
        
        setErrs({
            mailErr: null, validationErr: null, pwdErr: null
        });
        mailInput.current.value = "";
    }

    const handleTxt = (evt, txtType) => {
        switch(txtType){
            case 'pwd':
                setLoginPwd(evt.target.value);
                setErrs((prevState) => ({...prevState, pwdErr: false}));
                break;
            case 'email':
                if(validateMailFormat(evt.target.value)){
                    setLoginEmail(evt.target.value);
                    setErrs((prevState) => ({...prevState, mailErr: false, validationErr: false}));
                }
                else{
                    setLoginEmail('');
                    setErrs((prevState) => ({...prevState, mailErr: false, validationErr: true}));
                }
                break;  
            default: 
        }
    }

    const execScroll = () => formRef.current.scrollIntoView({ behavior: 'smooth' });

    const handleRedirect = (route) => {
        history.push(route);
    }

    const handleLogin = () => {
        !loginEmail ? setErrs((prevState) => ({...prevState, mailErr: true})) 
        : setErrs((prevState) => ({...prevState, mailErr: false}));
        !loginPwd ? setErrs((prevState) => ({...prevState, pwdErr: true})) 
        : setErrs((prevState) => ({...prevState, pwdErr: false}));
        
        if(Object.values(errs).every(item => item === false)){
            dispatch(login(loginEmail, loginPwd, userType));
            clearValues();
        }else{
            execScroll();
        }
    }

    const handleAgentButtonClick = () => {
        setUserType('AGENT');
    }

    const handleUserButtonClick = () => {
        setUserType('BUSINESS-OWNER');
    }

    return (
        <div className={classes.root}>
            <Fade in={true} timeout={1500}>
                <Grid container className={classes.formContainer}  ref={formRef}>
                   { userType === '' &&
                    <>
                        <Grid item xs={12}  className={classes.formInputs}>
                            <Typography variant="h6" className={classes.loginTitle} style={{paddingTop: '25%', paddingBottom: '4%', textAlign: 'center'}}> Are you a Business Owner or an Agent? </Typography>
                        </Grid>
                        <ThemeProvider theme={theme}>
                            <Stack spacing={2} style={{ width:'100%', paddingBottom: '25%'}} direction="row" justifyContent="center">
                                <Button color="secondary" variant="contained" size="medium" onClick={handleUserButtonClick} >
                                    BusinessOwner
                                </Button>
                                <Button color="secondary" variant="contained" size="medium" onClick={handleAgentButtonClick}> 
                                    Agent 
                                </Button>
                            </Stack>
                        </ThemeProvider>
                    </>
                    }
                    {userType!=='' && <>
                    <Grid item xs={12}  className={classes.formInputs}>
                        <Typography variant="h4"  className={classes.loginTitle} > {(userType==='BUSINESS-OWNER') ? 'BusinessOwner' : userType} Login </Typography>
                    </Grid>
                    <Grid item xs={12}  className={classes.formInputs}>
                        <TextField className={classes.fields} required id="email" label="Email" InputLabelProps={{ sx: {'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}} }}
                            variant="standard" type='email' onChange={(e) => handleTxt(e, 'email')} error={errs.validationErr || errs.mailErr} onKeyPress={(e) => e.key === 'Enter' && handleLogin()}
                        helperText={errs.validationErr ? `Enter valid email address`: (errs.mailErr ? `Enter registered email address` : null)}
                        inputRef={mailInput}/>
                    </Grid>
                    <Grid item xs={12} className={classes.formInputs}>
                        <TextField required id="pwd" label="Password" variant="standard" type="password" value={loginPwd} InputLabelProps={{ sx: {'&.Mui-required .MuiInputLabel-asterisk': { color: 'red'}} }}
                            className={classes.fields} onChange={(e) => handleTxt(e, 'pwd')}  error={errs.pwdErr} onKeyPress={(e) => e.key === 'Enter' && handleLogin()}
                            helperText={errs.pwdErr && `Enter Password`}/>
                    </Grid>
                    <Grid item xs={12}  className={classes.formInputs}>
                        <Button className={classes.loginBtn} size="medium" color="inherit" 
                            onClick={handleLogin}>
                            Login
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.formInputs}>
                        <Button className={classes.actionBtn} size="medium" color="inherit" 
                            onClick={() => handleRedirect('/forgotPwd')}>
                            Forgot Password
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.formInputs}>
                        <Button className={classes.actionBtn} size="medium" color="inherit" 
                            onClick={() => handleRedirect('/register')}>
                            Register
                        </Button>
                    </Grid>
                    </>}
                </Grid>
            </Fade>

        </div>
    )
}

export default LoginForm;
